import env from "@lib/utils/env";
import { FinalMileManager } from "@sourceful/shared-utils/engine-utils";

import { FinalMileMeta } from "@sourceful/shared-types";

const SOURCEFUL_ROOT_DOMAIN = env("ROOT_HOSTNAME");

export const FINAL_MILE_KEY = "SFE_FINAL_MILE";
export const DEFAULT_POSTCODE = "E4 6AN";

const finalMileManager = new FinalMileManager(SOURCEFUL_ROOT_DOMAIN);

export const saveFinalMileMeta = (meta: FinalMileMeta) => {
  finalMileManager.saveFinalMileMeta(meta);
};

export const getFinalMileMeta = (): FinalMileMeta => {
  const meta = finalMileManager.getFinalMileMeta();
  return meta;
};
