import React from "react";
import { Icon, styled } from "@sourceful/shared-components";
import toRem from "@styles/postprocess/conversion/to-rem";

const Container = styled("div", {
  "@lg": {
    display: "none",
  },
});

const BurgerButton = styled("button", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "40px",
  height: "40px",
  lineHeight: "26px",
  borderRadius: "100%",
  color: "#404044",
  backgroundColor: "transparent",
  border: "none",
  padding: "0",
  marginInlineStart: toRem(-10),
  marginInlineEnd: toRem(10),
  cursor: "pointer",

  "&:hover, &:focus": {
    backgroundColor: "#f6f6f6",
  },

  "&:focus-within": {
    boxShadow: "0 0 0 4px #ccdef9",
    outline: "none",
  },

  "@md": {
    width: "50px",
    height: "50px",
  },
});

export interface IPrimaryNavigationBurgerProps {
  clickEvent: () => void;
}

export const PrimaryNavigationBurger = React.forwardRef<
  HTMLButtonElement,
  IPrimaryNavigationBurgerProps
>((props, ref) => {
  const { clickEvent } = props;

  return (
    <Container>
      <BurgerButton ref={ref} type="button" onClick={clickEvent} aria-label="Menu">
        <Icon name="navigation-menu" />
      </BurgerButton>
    </Container>
  );
});

export default PrimaryNavigationBurger;
