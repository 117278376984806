import { GraphQLClient } from "graphql-request";

import env from "@lib/utils/env";
import { hasKey } from "@lib/hasKey";
import { BasicValue, ProductAttributeValue } from "@sourceful/shared-types";

export * from "./getPreviewType";

export const getPrintColourValue = (valueMeta: ProductAttributeValue) => {
  if (typeof valueMeta.value === "string" || typeof valueMeta.value === "number") {
    return valueMeta.value;
  }

  if (!hasKey(valueMeta.value, "value")) {
    return null;
  }

  return (valueMeta.value as BasicValue).value;
};

export const createURL = (route: string, query: any, step: string) => {
  const qs = Object.keys(query).reduce((acc: string, k: string) => {
    if (k === "productMain" || k === "step") return acc;

    return `${acc}&${k}=${query[k]}`;
  }, "");

  return `${route}?${qs.substring(1)}${step !== "" ? `&step=${step}` : ""}`;
};

export const provideGqlClient = (token?: string) => {
  const hasuraEndpoint = env("HASURA_ENDPOINT") + "/v1/graphql";
  const client = new GraphQLClient(hasuraEndpoint);
  if (token) {
    client.setHeader("authorization", `Bearer ${token}`);
  }

  return client;
};

export const handleBeforeUnloadConfigurator = (event: any) => {
  event.preventDefault();
  event.returnValue = "";
};
