export interface UserJWT {
  uuid: string;
  created_at: string;
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  groups: string[];
  identities: {
    connection: string;
    isSocial: boolean;
    provider: string;
    user_id: string;
  }[];
  identity_api: string;
  last_ip: string;
  last_login: string;
  logins_count: number;
  name: string;
  nickname: string;
  oid: string;
  org: {
    uuid: string;
    display_name: string;
    id: string;
    name: string;
  };
  organisations: {
    uuid: string;
    display_name: string;
    id: string;
    name: string;
  }[];
  phone: string[];
  picture: string;
  tenantid: string;
  updated_at: string;
  upn: string;
  user_id: string;
  user_metadata: {
    phone_number?: string;
    [index: string]: any;
  };
}

export interface UserClaims {
  ["x-hasura-allowed-roles"]: string[];
  ["x-hasura-default-role"]: string;
  ["x-hasura-user-id"]: string;
  user: UserJWT;
}

export const CLAIMS_DOMAIN = "https://hasura.io/jwt/claims";
