import GlobalFooter from "@components/globalFooter/GlobalFooter";
import SiteHeader from "@components/siteHeader";
import CheckOnboardingWrapper from "@providers/CheckOnboardingWrapper/CheckOnboardingWrapper";
import { GridOverlay, IntercomInitialisationButton } from "@sourceful/shared-components";
import Script from "next/script";
import { FC } from "react";

interface DefaultLayoutProps {
  disableNav?: boolean;
  children?: React.ReactNode;
  initialiseIntercom: VoidFunction;
}

const DefaultLayout: FC<DefaultLayoutProps> = ({ children, disableNav, initialiseIntercom }) => {
  return (
    <CheckOnboardingWrapper>
      <Script src="https://cmp.osano.com/Azz7sdT3Wh0qm5dY/d51c14ff-6a1b-4466-b221-70e74ffb3851/osano.js"></Script>
      <GridOverlay />
      <SiteHeader appearance={disableNav ? "simplified" : "default"} />
      {children}
      <GlobalFooter appearance={disableNav ? "simplified" : "default"} />
      <IntercomInitialisationButton initialiseIntercom={initialiseIntercom} />
    </CheckOnboardingWrapper>
  );
};

export default DefaultLayout;
