import { styled } from "@sourceful/shared-components";
import toRem from "@styles/postprocess/conversion/to-rem";
import { useRef } from "react";

const PrimaryNavInteractionPanel = styled("div", {
  //transition: "opacity 1s, visibility 1s",
  display: "none",
  backgroundColor: "white",
  variants: {
    interaction: {
      yes: {
        visibility: "hidden",
        opacity: "0",
      },
      no: {
        display: "block",
      },
    },
    visible: {
      no: {
        display: "none",
      },
      yes: {
        display: "block",
        visibility: "visible",
        opacity: "1",
      },
    },
    layout: {
      container: {
        width: "auto",
        height: "auto",
        position: "static",
      },
      full: {
        width: "100%",
        height: "100%",
        overflowX: "auto",
        position: "fixed",
        top: "0",
        left: "0",
        zIndex: 9999,
      },
      fixed: {
        minWidth: toRem(350),
        width: "auto",
        height: "auto",
        position: "absolute",
        left: "auto",
        top: "calc(100% + 10px)",
        transform: "translateX(calc(-50% + 60px))",
        bshad: "$subtle",
        rad: "$medium",
        borderRadius: "16px",
      },
    },
  },
  compoundVariants: [
    {
      interaction: "yes",
      layout: "fixed",
      css: {
        padding: "30px",
        overflowX: "visible",
        zIndex: "1",

        "&:before": {
          content: "",
          display: "block",
          width: "100%",
          height: "30px",
          position: "absolute",
          top: "-30px",
          left: "0",
          //border: "1px solid red", // debug helper
        },
      },
    },
    {
      interaction: "yes",
      layout: "full",
      css: {
        padding: "clamp(30px, 6vw, 60px)",
      },
    },
  ],
});

export interface PrimaryNavigationInteractionPanelProps {
  id: string;
  labelledby: string;
  children?: React.ReactNode;
  interaction: "yes" | "no" | any;
  layout?: string | any;
  visible: "yes" | "no" | any;
  className?: string;
}

export const PrimaryNavigationInteractionPanel = ({
  id,
  labelledby,
  children,
  interaction = "no",
  layout = "full",
  visible = "no",
  className,
}: PrimaryNavigationInteractionPanelProps) => {
  const ref = useRef<HTMLDivElement>(null);

  //console.log("PrimaryNavigationInteractionPanel", visible);

  return (
    <PrimaryNavInteractionPanel
      ref={ref}
      id={id}
      aria-labelledby={labelledby}
      interaction={interaction}
      layout={layout}
      visible={visible}
      data-panel
      className={className}
    >
      {children}
    </PrimaryNavInteractionPanel>
  );
};

export default PrimaryNavigationInteractionPanel;
