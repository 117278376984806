import { styled, css, globalCss } from "@sourceful/shared-components";
import toRem from "@styles/postprocess/conversion/to-rem";

export const responseIoCss = globalCss({
  ".search-open iframe[src*='webchat']": {
    display: "none",
  },
});

export const Container = styled("div", {
  width: "100%",
});

export const SearchButton = styled("button", {
  alignItems: "center",
  justifyContent: "center",
  width: "50px",
  height: "50px",
  lineHeight: "26px",
  borderRadius: "$radii-small",
  color: "#404044",
  backgroundColor: "transparent",
  border: "none",
  padding: "0",
  marginInlineStart: "auto",
  cursor: "pointer",
  transition: "all 0.2s",

  "&:hover": {
    opacity: 0.7,
  },
});

export const modalCss = css({
  position: "fixed",
  zIndex: "9999",
  top: "0",
  left: "0",
  width: "100vw",
  maxWidth: "100%",
  // See: https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/ + https://github.com/modulz/stitches/issues/758
  // Using height: in this instance though, applied to html/body as above in _document.tsx
  height: "100vh; height: -webkit-fill-available;",
  overflowX: "auto",
  backgroundColor: "white",
  display: "flex",
  justifyContent: "center",
});

export const screenReaderCss = css({
  position: "absolute",
  width: "1px",
  height: "1px",
  padding: "0",
  margin: "-1px",
  overflow: "hidden",
  clip: "rect(0,0,0,0)",
  whiteSpace: "nowrap",
  borderWidth: 0,
});

export const containerCss = css({
  width: "100%",
  maxWidth: toRem(1200),
  paddingBlock: "clamp(20px, 3vw, 60px)",
  paddingInline: "clamp(20px, 3vw, 60px)",
});

export const headCss = css({
  display: "flex",
  alignItems: "center",
  mbe: "$block_m3",
  position: "relative",
});

export const headCloseIconCss = css({
  position: "absolute",
  right: "0",
  //mie: "$inline_s2",
});

export const labelCss = css({
  position: "relative",
  display: "flex",
  alignItems: "center",
  mbe: "$block_m2",
});

export const searchIconCss = css({
  position: "absolute",
  left: "0",
  mis: "$inline_s2",
});

export const searchCloseIconCss = css({
  position: "absolute",
  right: "0",
  mie: "$inline_s2",
});

export const inputCss = css({
  width: "100%",
  border: "1px solid #DCDCDD",
  borderRadius: "6px",
  outline: "none",
  p: "$inline_xs2",
  fs: "$p2",
  /* smartphones, touchscreens */
  "@media (hover: none) and (pointer: coarse)": {
    fontSize: toRem(16),
  },

  "&:focus": {
    borderColor: "$palette1_colour1",
    boxShadow: " 0 0 0 4px #99BDF3",
  },
});

export const linkButtonCss = css({
  border: "none",
  padding: "0",
  px: "$inline_xs1",
  fs: "$p2",
  color: "$palette1_colour1",
  textDecoration: "underline",
  cursor: "pointer",
  outlineOffset: "2px",

  "&:hover, &:focus": {
    textDecoration: "none",
  },
});

export const sectionCss = css({
  pbe: "$inline_m1",
  mbe: "$block_m1",
  borderBottom: "1px solid #DCDCDD",

  "&:last-child": {
    borderBottom: "none",
  },

  "@lg": {
    pbe: "$inline_l1",
    mbe: "$block_l1",
  },
});

export const sectionHeadCss = css({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  mbe: "$block_s2",

  "@lg": {
    mbe: "$block_m2",
  },
});

export const sectionUlCss = css({
  margin: "0",
});

export const sectionLiCss = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",

  "&::before": {
    display: "none",
  },
});

export const iconButtonCss = css({
  display: "flex",
  alignItems: "center",
  border: "none",
  padding: "0",
  py: "$inline_xs1",
  pie: "$inline_xs1",
  fs: "$p3",
  cursor: "pointer",
  outlineOffset: "2px",

  "&:hover, &:focus": {
    textDecoration: "underline",
  },

  "@lg": {
    fs: "$p2",
  },
});

export const clearAllButtonCss = css({
  color: "&palette3_colour1_shade20",
  textDecoration: "underline",
  border: "0",
  padding: "0",
  cursor: "pointer",

  "&:hover, &:focus": {
    textDecoration: "none",
    color: "$palette1_colour1",
  },
});

export const clearButtonCss = css({
  mis: "$inline_xs1",
  padding: "0",
  border: "0",
  cursor: "pointer",
  color: "$palette3_colour1_tint20",

  "&:hover, &:focus": {
    color: "$palette1_colour1",
  },
});

export const searchResultsContainerCss = css({
  paddingBlockEnd: "clamp(20px, 3vw, 60px)",
});

export const searchLoadingCss = css({
  my: "$block_xl1",
  display: "flex",
  width: "100%",
  justifyContent: "center",
});

export const productsCss = css({
  mbe: "$block_m2",
  pbe: "$inline_m2",
  borderBottom: "1px solid #DCDCDD",
});

export const searchNoResultsCss = css({
  my: "$block_xl1",
  display: "flex",
  width: "100%",
  justifyContent: "center",
});

export const searchResultsSummaryCss = css({
  my: "$block_m2",
  pbe: "$block_s1",
  borderBottom: "1px solid #DCDCDD",
});

export const searchResultsListCss = css({
  margin: "0",
});

export const searchResultLiCss = css({
  mbe: "$block_m1",

  "&::before": {
    display: "none",
  },

  "&:last-child": {
    mbe: "0",
  },
});

export const searchResultLinkCss = css({
  display: "flex",
  alignItems: "start",
  outline: "none",

  "&:hover, &:focus": {
    "& .heading": {
      textDecoration: "underline",
    },
  },
});

export const searchResultImgCss = css({
  rad: "$medium",
});

export const searchResultImageContainerCss = css({
  minWidth: "82px",
  minHeight: "82px",
  mie: "$inline_s2",
  backgroundColor: "$palette3_colour1_tint60",
  rad: "$medium",
});

export const searchResultContentCss = css({});

export const searchResultDescCss = css({
  margin: "0",
});
