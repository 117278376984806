import { css, breakpoints } from "@sourceful/shared-components";
import toRem from "@styles/postprocess/conversion/to-rem";
import { IMenu, IMenuItem, INavigationItem } from "./PrimaryNavigation";
import { DisplayType, MenuItem } from "./MenuItem";

const subHeadingCss = css({
  fontSize: toRem(20),
  fontWeight: "400",
  color: "#747477",
  margin: "0",
  marginBottom: "12px",
});

const liCss = css({
  borderBottom: "1px solid #DCDCDD",

  "&::before": {
    display: "none",
  },

  "&:last-child": {
    border: "none",
  },
});

const containerCss = css({
  "&:not(:first-child)": {
    marginBlockStart: "48px",
  },
});

export interface IMenuSectionProps {
  heading?: string;
  items?: IMenuItem[];
  onMenuItemButtonClick: (menu?: IMenu, updatedNavItems?: INavigationItem[]) => void;
  width?: number;
}

export const MenuSection = ({
  heading,
  items = [],
  onMenuItemButtonClick,
  width,
}: // onMenuItemClick,
IMenuSectionProps) => {
  return (
    <div className={containerCss()}>
      {heading && (
        <div>
          <h3 className={subHeadingCss()}>{heading}</h3>
        </div>
      )}
      {items.length > 0 && (
        <div>
          <ul>
            {items?.map((item, index) => {
              return (
                <li className={liCss()} key={`li-item-${index}`}>
                  <MenuItem
                    {...item}
                    key={`menu-item-${index}`}
                    onButtonClick={() => {
                      onMenuItemButtonClick(item.menu);
                    }}
                    displayType={
                      width && width >= breakpoints.lg
                        ? DisplayType.PrimaryNavigation
                        : DisplayType.Default
                    }
                    isActive={false}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MenuSection;
