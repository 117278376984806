import { PortableTextTypeComponentProps } from "@portabletext/react";
import { PrimaryButton } from "@sourceful/shared-components";
import StandardLink from "@components/siteHeader/StandardLink";
import { CtaLink, ExternalLink } from "@sourceful/shared-types";

const BlockContentCtaLink = (props: PortableTextTypeComponentProps<CtaLink>) => {
  const link = props.value.link;

  const appearance = props.value.appearance;

  if (link.externalLink?.link) {
    let externalLink: ExternalLink = {
      link: link.externalLink.link,
      newWindow: link.externalLink.newWindow,
    };

    if (appearance === "link") {
      return (
        <StandardLink externalLink={externalLink} css={{ textDecoration: "none !important" }}>
          {props.value.title}
        </StandardLink>
      );
    } else {
      return (
        <StandardLink externalLink={externalLink} css={{ textDecoration: "none !important" }}>
          <PrimaryButton as="span">{props.value.title}</PrimaryButton>
        </StandardLink>
      );
    }
  }

  if (appearance === "link") {
    return (
      <StandardLink internalLink={link.internalLink} css={{ textDecoration: "none !important" }}>
        {props.value.title}
      </StandardLink>
    );
  } else {
    return (
      <StandardLink internalLink={link.internalLink} css={{ textDecoration: "none !important" }}>
        <PrimaryButton as="span">{props.value.title}</PrimaryButton>
      </StandardLink>
    );
  }
};
export default BlockContentCtaLink;
