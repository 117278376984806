import { SanitySecondaryNavigation } from "@groq/siteHeaderConfig";
import { BlockContent } from "@lib/sanity-components";
import { styled, css, Label, LabelSize } from "@sourceful/design-system";

const Nav = styled("nav", {
  width: "100%",
  justifyContent: "center",
  position: "relative",
  backgroundColor: "$colour-brand-blue-hue-1-shade-3",
  lineHeight: "20px",
  paddingLeft: "$spacing-fluid-inline-x-small",
  paddingRight: "$spacing-fluid-inline-x-small",
  paddingTop: "$spacing-fluid-block-x-small",
  paddingBottom: "$spacing-fluid-block-x-small",

  "@lg": {
    display: "flex",
  },
});

const blockContentWrapperCss = css({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  maxWidth: "1200px",
  alignItems: "center",
  color: "$colour-lightest",

  "> div": {
    color: "inherit",

    "> *": {
      margin: "0",
      marginBlockEnd: "$spacing-fluid-inline-small",

      "&:last-child": {
        marginBlockEnd: "0",
      },

      a: {
        fontSize: "inherit",
        color: "inherit",

        "&:hover, &:focus": {
          color: "inherit",
          textDecoration: "none",
        },
      },
    },
  },
});

interface ISecondaryNavigationProps {
  data: SanitySecondaryNavigation;
}

export const SecondaryNavigation = ({ data }: ISecondaryNavigationProps) => {
  const { isEnabled, content } = data;

  return isEnabled && content ? (
    <Nav role="navigation" aria-label="Secondary navigation" data-cy="site-header-secondary-nav">
      <Label size={LabelSize.Medium} className={blockContentWrapperCss()} as="div">
        <BlockContent value={content} />
      </Label>
    </Nav>
  ) : (
    <></>
  );
};
