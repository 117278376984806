import { IconTextSize, IconTextNew } from "@components/iconTextNew/IconTextNew";
import { PortableTextTypeComponentProps } from "@portabletext/react";
import { IconName } from "@sourceful/shared-types";
import { css } from "@sourceful/shared-components";

const iconTextContainerCss = css({
  display: "flex",
  gap: "$spacing-fluid-inline-m",
});

const BlockContentIconTextContainer = (
  props: PortableTextTypeComponentProps<{
    items: {
      _key: string;
      iconName: IconName;
      text: string;
      size: IconTextSize;
    }[];
  }>
) => {
  return (
    <div className={iconTextContainerCss()}>
      {props.value.items.map((item: any) => {
        return (
          <IconTextNew
            key={item._key}
            icon={item.iconName}
            text={item.text}
            size={IconTextSize.Large}
          />
        );
      })}
    </div>
  );
};

export default BlockContentIconTextContainer;
