import { Icon, NotificationBadge, styled } from "@sourceful/shared-components";
import { DropdownLink } from "@sourceful/shared-types";
import Link from "next/link";

const ListItem = styled("li", {
  margin: "0px",
  padding: "0px",
  listStyle: "none",
  display: "flex",
  borderBottom: "1px solid #DCDCDD",
  "&:last-of-type": {
    borderBottom: "1px solid transparent",
  },
  "&:first-of-type": {
    // force single items to have a border bottom
    borderBottom: "1px solid #DCDCDD",
  },
});

const StyledA = styled("a", {
  flex: "1",
  minHeight: "50px",
  padding: "12.5px",
  backgroundColor: "#FFFFFF",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingRight: "15px",
  transition: "transform 0.4s",
  border: "none",
  position: "relative",
  cursor: "pointer",

  "@media (hover: hover) and (pointer: fine)": {
    "&:hover": {
      opacity: 0.7,
      transform: "translate(10px, 0px)",
    },
  },
});

const LinkTitle = styled("span", {
  display: "block",
  fontSize: "16px",
  lineHeight: "26px",
  margin: "0px",
  padding: "0px",
  letterSpacing: "normal",
  textAlign: "left",
});

const LinkSubtitle = styled("span", {
  fontFamily: "Space Grotesk",
  fontSize: "12px",
  lineHeight: 1.6,
  fontWeight: 400,
  color: "$palette3_colour1",
  textAlign: "left",
});

const InnerWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  ".link-icon": {
    marginRight: "27px",
    color: "$primary_80",
  },
});

const LeftWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
});

interface GlobalHeaderLinkPros {
  item: DropdownLink;
  count?: number;
  onClick?: () => void;
  isButton?: boolean;
  id?: string;
}

const GlobalHeaderLink = ({
  item: { url: link, title, subtitle, iconName },
  onClick,
  isButton,
  count,
  id,
}: GlobalHeaderLinkPros) => {
  const InnerContent = (
    <>
      <InnerWrapper>
        {iconName && <Icon name={iconName} height={24} width={24} className="link-icon" />}

        <div>
          <LinkTitle>{title}</LinkTitle>
          {subtitle && <LinkSubtitle>{subtitle}</LinkSubtitle>}
        </div>
      </InnerWrapper>

      <LeftWrapper>
        {count ? <NotificationBadge position="static" text={count.toString()} /> : null}

        <Icon name={"arrow-direction-right"} />
      </LeftWrapper>
    </>
  );

  if (isButton) {
    return (
      <ListItem>
        <StyledA id={id} as="button" onClick={onClick}>
          {InnerContent}
        </StyledA>
      </ListItem>
    );
  }
  return (
    <ListItem>
      <Link href={link ?? ""} passHref legacyBehavior>
        <StyledA>{InnerContent}</StyledA>
      </Link>
    </ListItem>
  );
};

export default GlobalHeaderLink;
