import { styled } from "@sourceful/design-system";

export const Link = styled("a", {
  fontFamily: "inherit",
  fontSize: "inherit",
  background: "none",
  border: "none",
  color: "$colour-brand-neutral-hue-1-tint-1",
  cursor: "pointer",
  transition: "all 0.3s ease 0s",
  display: "inline-flex",
  alignItems: "center",
  textDecoration: "underline",

  "&:hover": {
    color: "$colour-brand-blue-hue-1-base",
  },
  "&:focus": {
    outline: "none",
  },
  "&:focus-visible": {
    outline: "revert",
  },

  "& + [data-link]": {
    marginInlineStart: "$spacing-fluid-inline-small",
  },

  variants: {
    color: {
      blue: {
        color: "$colour-brand-blue-hue-1-base",
        "&:hover": {
          color: "$colour-brand-blue-hue-1-shade-3",
        },
      },
      neutral: {
        color: "$colour-brand-neutral-hue-1-shade-3",

        "&:hover": {
          color: "$colour-brand-neutral-hue-1-shade-3",
        },
      },
    },
  },
});

export default Link;
