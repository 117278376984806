import React, { FC } from "react";
import { IconName } from "@sourceful/shared-types";
import LinkNew, { LinkNewProps } from "../linkNew/LinkNew";
import { css, Icon, TextNew, TextNewAs, CSS } from "@sourceful/shared-components";

const tagCss = css({
  display: "inline-flex",
  alignItems: "center",
  //whiteSpace: "normal",
  //maxWidth: "$optimalLine",
  variants: {
    type: {
      Inline: {},
      Stacked: {
        flexDirection: "column",
        textAlign: "center",
      },
    },
    position: {
      Leading: {},
      Trailing: {},
    },
    iconAlignment: {
      Middle: {},
      Start: {},
      End: {},
    },
  },
  compoundVariants: [
    {
      type: "Stacked",
      iconAlignment: "Start",
      css: {
        textAlign: "left",
      },
    },
    {
      type: "Stacked",
      iconAlignment: "End",
      css: {
        textAlign: "right",
      },
    },
  ],
});

const linkCss = css({
  "& .text": {
    textDecoration: "underline",
  },

  "&:hover, &:focus": {
    "& .text": {
      textDecoration: "none",
    },
  },
});

const iconCss = css({
  display: "inline-flex",
  variants: {
    type: {
      Inline: {},
      Stacked: {},
    },
    size: {
      Small: {
        $$spacing: "$space$spacing-fluid-block-xxs",
      },
      Medium: {
        $$spacing: "$space$spacing-fluid-block-xs",
      },
      Large: {
        $$spacing: "$space$spacing-fluid-block-s",
      },
      // TODO: Make sure inline/block is used
    },
    position: {
      Leading: {},
      Trailing: {},
    },
    iconAlignment: {
      Middle: { alignSelf: "center" },
      Start: { alignSelf: "start" },
      End: { alignSelf: "end" },
    },
  },
  compoundVariants: [
    {
      type: "Inline",
      position: "Leading",
      css: {
        marginInlineEnd: "$$spacing",
      },
    },
    {
      type: "Inline",
      position: "Trailing",
      css: {
        marginInlineStart: "$$spacing",
      },
    },
    {
      type: "Stacked",
      position: "Leading",
      css: {
        marginBlockEnd: "$$spacing",
      },
    },
    {
      type: "Stacked",
      position: "Trailing",
      css: {
        marginBlockStart: "$$spacing",
      },
    },
  ],
});

function getIconSize(size: IconTextSize): "XXS" | "XS" | "S" {
  switch (size) {
    case "Large":
      return "S";
    case "Medium":
      return "XS";
    default:
      return "XXS";
  }
}

export enum IconTextType {
  "Inline" = "Inline",
  "Stacked" = "Stacked",
}

export enum IconTextPosition {
  "Leading" = "Leading",
  "Trailing" = "Trailing",
}

export enum IconTextSize {
  "Small" = "Small",
  "Medium" = "Medium",
  "Large" = "Large",
} // Determines Icon, Label size and Spacing

export enum IconTextAs {
  "div" = "div",
  "h2" = "h2",
  "h3" = "h3",
  "h4" = "h4",
} // Maybe others down the line

export enum IconTextIconAlignment {
  "Middle" = "Middle",
  "Start" = "Start",
  "End" = "End",
}

export interface IconTextNewProps {
  icon: IconName;
  text: string;
  type?: IconTextType;
  position?: IconTextPosition;
  size?: IconTextSize;
  className?: string;
  css?: CSS;
  as?: IconTextAs;
  iconAlignment?: IconTextIconAlignment;
  link?: LinkNewProps;
  textAs?: TextNewAs;
}

export const IconTextNew: FC<IconTextNewProps> = ({
  icon,
  text,
  type = IconTextType.Inline,
  position = IconTextPosition.Leading,
  size = IconTextSize.Medium,
  className,
  css,
  as = IconTextAs.div,
  iconAlignment = IconTextIconAlignment.Middle,
  link,
  textAs = "p",
}) => {
  const Tag = as;

  const tag = (
    <Tag
      data-icon-text=""
      className={
        tagCss({
          position,
          type,
          iconAlignment,
          css: {
            ...css,
          },
        }) + (className ? ` ${className}` : ``)
      }
    >
      {position === "Leading" && (
        <div
          className={iconCss({
            type,
            size,
            position,
            iconAlignment,
          })}
        >
          <Icon name={icon} size={getIconSize(size)} />
        </div>
      )}
      <TextNew as={textAs} variant="Label" lineHeight="Loose" size={size} className="text">
        {text}
      </TextNew>
      {position === "Trailing" && (
        <div
          className={iconCss({
            type,
            size,
            position,
            iconAlignment,
          })}
        >
          <Icon name={icon} size={getIconSize(size)} />
        </div>
      )}
    </Tag>
  );

  if (!link) {
    return tag;
  }

  return (
    <LinkNew {...link} className={linkCss()}>
      {tag}
    </LinkNew>
  );
};

export default IconTextNew;
