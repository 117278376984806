import { useIdentifyDatadogUser } from "@lib/hooks/useIdentifyDatadogUser/useIdentifyDatadogUser";
import env from "@lib/utils/env";
import { useRouter } from "next/router";
import { ReactNode } from "react";
import { useAuthentication } from "../AuthenticationProvider";
import { useCheckOnboardingStatus } from "./useCheckOnboardingStatus";

const DISABLE_DATADOG = env("DISABLE_DATADOG");
interface Props {
  children: ReactNode;
}

const CheckOnboardingWrapper = ({ children }: Props) => {
  const {
    user,
    isAuthenticated,
    isLoading: isAuthLoading,
    loginCount,
    metadata,
    getAccessTokenSilently,
  } = useAuthentication();
  const router = useRouter();

  useIdentifyDatadogUser({
    isAuthLoading,
    user: user || null,
    isDatadogDisabled: DISABLE_DATADOG === "true", // this env var is a string so we need it to evaluate explicitly to true to "true"
  });

  useCheckOnboardingStatus({
    router: router,
    revalidate: false,
    authProps: {
      isLoading: isAuthLoading,
      user: user,
      isAuthenticated,
      getAccessTokenSilently,
      loginCount,
      metadata,
    },
  });

  return <>{children}</>;
};

export default CheckOnboardingWrapper;
