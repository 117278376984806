import { useEffect, useState } from "react";
import { getDefaultEngineVersion, getEngineVersion } from "@lib/getEngineVersion";
import { Chip, styled, Link as SourcefulLink } from "@sourceful/shared-components";
import Link from "next/link";

const Wrapper = styled("div", {
  position: "fixed",
  bottom: "20px",
  left: "50%",
  transform: "translate(-50%, 0)",
  boxShadow: "$fly",
  borderRadius: "$pill",
  zIndex: 1000,
});

const EngineVersionWarning = () => {
  const defaultEngineVersion = getDefaultEngineVersion();
  const [currentEngineVersion, setCurrentEngineVersion] = useState(defaultEngineVersion);
  const [show, setShow] = useState(true);

  useEffect(() => {
    const version = getEngineVersion();
    setCurrentEngineVersion(version);
  }, []);

  if (!show || currentEngineVersion === defaultEngineVersion) return null;

  return (
    <Wrapper data-cy="engine-warning">
      <Chip
        iconName="alert-exclamation-fill"
        handleDismiss={() => setShow(false)}
        text={`You are using engine ${currentEngineVersion} instead of the default ${defaultEngineVersion}`}
      >
        <Link href={"/admin/engine-settings"} legacyBehavior>
          <SourcefulLink css={{ marginLeft: "$inline_s1" }}>Edit</SourcefulLink>
        </Link>
      </Chip>
    </Wrapper>
  );
};

export default EngineVersionWarning;
