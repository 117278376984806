import { Heading, IconText } from "@sourceful/shared-components";
import { MouseEventHandler } from "react";
import {
  iconButtonCss,
  sectionCss,
  sectionHeadCss,
  sectionLiCss,
  sectionUlCss,
} from "./PrimaryNavigationSearchStyles";

interface IPrimaryNavigationSearchSuggestedProps {
  buttonClick: MouseEventHandler<HTMLButtonElement>;
}

export const PrimaryNavigationSearchSuggested = ({
  buttonClick,
}: IPrimaryNavigationSearchSuggestedProps) => {
  return (
    <div className={sectionCss()}>
      <div className={sectionHeadCss()}>
        <Heading level={3} fontSize={6} prominence="primary">
          Suggested
        </Heading>
      </div>
      <ul className={sectionUlCss()}>
        {["Mailer box", "Sourceful climate", "FSC", "Recycled mailer"].map((x, i) => {
          return (
            <li key={`suggested-${i}`} className={sectionLiCss()}>
              <button
                onClick={buttonClick}
                className={iconButtonCss()}
                aria-label={`Search for ${x}`}
              >
                <IconText primaryIcon="navigation-search" text={x} css={{}} />
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
