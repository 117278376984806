import { DocumentNode, GraphQLError } from "graphql";
import env from "../utils/env";
import { useAuthentication } from "@providers/AuthenticationProvider";
import { getAuthHeaders } from "@lib/auth/getAuthHeaders";
import { PRODUCT_CLOUD_DEFAULT_ROLE, ProductCloudRole } from "@sourceful/shared-utils/rbac";
import {
  GetNextPageParamFunction,
  GetPreviousPageParamFunction,
  keepPreviousData,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { print as gqlToString } from "graphql/language/printer";
import { useCallback, useEffect } from "react";
import { useRedirectToLogin } from "./useRedirectToLogin";

const hasuraEndpoint = env("HASURA_ENDPOINT") + "/v1/graphql";
const DEFAULT_CACHE_TIME_IN_MS = 6e4; // 60 seconds

export function useGraphQLPrep(query: DocumentNode) {
  const rawQuery = gqlToString(query);
  const operationName = getOperationNameFromRawQuery(rawQuery);
  const { getAccessTokenSilently, hasuraRole: role } = useAuthentication();
  const redirectToLogin = useRedirectToLogin();

  // we can handle different auth errors individually in this callback
  const handleAuthErrors = useCallback((error: ErrorWithGraphQLErrors) => {
    const shouldRedirect = error.graphQLErrors?.find(err =>
      err.message.toLowerCase().includes("not authorized")
    );

    if (shouldRedirect) {
      return redirectToLogin();
    }
  }, []);

  return {
    rawQuery,
    operationName,
    getAccessTokenSilently,
    role,
    handleAuthErrors,
  };
}

type MutationErrorCallback<Variables extends object = {}> = (
  error: unknown,
  variables: Variables,
  context: unknown
) => unknown;

/**
 * Wraps graphQLRequest in a react-query `useMutation`, manages request authorisation and exposes key `useMutation` properties.
 * @param query a `DocumentNode`, created by the `gql` tag
 * @param opts various options
 * @returns a tuple of the shape: [mutation function, object containing key properties from `useMutation`]
 * @example
  const [invalidateArtworkResponse] = useGraphQLMutation<
    DeleteArtworkFileResponseMutationVariables,
    DeleteArtworkFileResponseMutationResult
  >(INVALIDATE_ARTWORK_FILE_RESPONSE);
 */
export function useGraphQLMutation<Variables extends object = {}, Response = unknown>(
  query: DocumentNode,
  opts?: {
    onError?: MutationErrorCallback<Variables>;
  }
) {
  opts ??= {};
  const { onError } = opts;
  const { getAccessTokenSilently, operationName, rawQuery, role, handleAuthErrors } =
    useGraphQLPrep(query);

  const {
    mutateAsync,
    mutate,
    data,
    error,
    context,
    isPending,
    isError,
    isSuccess,
    status,
    reset,
  } = useMutation({
    mutationKey: [operationName],
    mutationFn: async (variables: Variables) =>
      graphQLRequest<Variables, Response>(rawQuery, {
        variables,
        token: await getAccessTokenSilently(),
        operationName,
        role,
      }),
    onError(error, ...rest) {
      handleAuthErrors(error);
      onError?.(error, ...rest);
    },
  });

  const fields = {
    /**
     * Trigger mutation asynchronously.
     */
    mutateAsync,
    /**
     * Trigger mutation synchronously.
     */
    mutateSync: mutate,
    /**
     * Provides helper methods for tracking/managing the mutation.
     */
    mutation: data,
    error,
    context,
    isLoading: isPending,
    isError,
    isSuccess,
    status,
    resetContext: reset,
    /**
     * The GraphQL operation name, as parsed from raw query string.
     */
    operationName,
  };

  return [mutateAsync, fields] as const;
}

/**
 * Wraps graphQLRequest in a react-query `useQuery`, manages request authorisation and exposes key `useQuery` properties.
 * @param query a `DocumentNode`, created by the `gql` tag
 * @param opts various options
 * @returns key properties from `useQuery`
 */
export function useGraphQLQuery<Variables extends object = {}, Response = unknown>(
  query: DocumentNode,
  opts?: {
    variables?: Variables;
    enabled?: boolean;
    /**
     * Time (in milliseconds) before data is considered stale.
     *
     * While data is fresh (i.e not-stale), subsequent queries with the same query key will simply return the data associated with the query key and the `queryFn` will not be invoked.
     *
     * Use this to control how long to cache query results.
     *
     * Defaults to `0`.
     */
    staleTime?: number;
    /**
     * Time (in milliseconds) before data is removed from the cache.
     *
     * Note that this is different from `staleTime` (and this difference is a common pitfall when learning to use react-query): while `staleTime` determines how long data is considered 'fresh', it doesn't determine how long data is cached -- in other words, data can be fresh (not-stale) but not cached.
     *
     * Use this to control how long to cache query results.
     *
     * Defaults to `0` if `staleTime` is not set, else defaults to `staleTime` + 1 minute.
     */
    cacheTime?: number;
    onError?: (error: Error) => void;
  }
) {
  opts ??= { enabled: true };

  const { enabled, variables, staleTime, cacheTime, onError } = opts;
  const { getAccessTokenSilently, operationName, rawQuery, role, handleAuthErrors } =
    useGraphQLPrep(query);
  // query will be re-run whenever queryKey changes
  const queryKey = [operationName, variables] as const;

  // be wary of using `isLoading`. react-query v4 introduces a breaking change (see https://sajadtorkamani.com/react-query-isinitialloading).
  // in most cases, you'll probably want to use isInitialLoading or isFetching
  const {
    data,
    error,
    isError,
    isSuccess,
    status,
    refetch,
    isRefetching,
    isLoading,
    isFetched,
    isFetching,
  } = useQuery({
    queryKey,
    queryFn: async ({ queryKey }) => {
      const [operationName, variables] = queryKey;
      return graphQLRequest<Variables, Response>(rawQuery, {
        token: await getAccessTokenSilently(),
        operationName,
        role,
        variables,
      });
    },
    enabled,
    placeholderData: keepPreviousData,
    staleTime,
    gcTime: cacheTime || staleTime === undefined ? 0 : staleTime + DEFAULT_CACHE_TIME_IN_MS,
  });

  useEffect(() => {
    if (!error || !onError) return;

    handleAuthErrors(error);
    onError(error as Error);
  }, [error]);

  return {
    query: data,
    error,
    /**
     * `true` if the query is running (i.e either fetching for the first time or re-fetching), else `false`.
     */
    isFetching,
    isError,
    isSuccess,
    status,
    /**
     * The GraphQL operation name, as parsed from raw query string.
     */
    operationName,
    /**
     * Re-run this query. `refetch` also allows you to alter the query key (e.g to change the variables passed to the GraphQL server, do `refetch({queryKey: [operationName, newVariables]})`).
     */
    refetch,
    isRefetching,
    /**
     * `true` if the query is running for the first time, else `false`.
     */
    isInitialLoading: isLoading,
    hasFetchedAtLeastOnce: isFetched,
    queryKey,
  };
}

type PageParams = {
  limit: number;
  offset: number;
};

/**
 * Wraps graphQLRequest in a react-query `useInfiniteQuery`, manages request authorisation and exposes key `useGraphQLInfiniteQuery` properties.
 * @param query a `DocumentNode`, created by the `gql` tag
 * @param opts various options
 * @returns key properties from `useInfiniteQuery`
 */
export function useGraphQLInfiniteQuery<Variables extends object = {}, Response = unknown>(
  query: DocumentNode,
  opts: {
    enabled?: boolean;
    getNextPageParam: GetNextPageParamFunction<PageParams, Response>;
    getPreviousPageParam?: GetPreviousPageParamFunction<PageParams, Response>;
    /**
     * Pagination parameters (i.e limit and/or offset) should not be included in `variables` or they will re-trigger the query whenever they change,
     * effectively running the query twice when fetchNextPage/fetchPreviousPage is called
     */
    variables?: Omit<Variables, "limit" | "offset">;
    /**
     * Pagination parameters to be used for the initial query (e.g limit: 10, offset: 0).
     */
    defaultParams: PageParams;
    /**
     * Time (in milliseconds) before data is considered stale.
     *
     * While data is fresh (i.e not-stale), subsequent queries with the same query key will simply return the data associated with the query key and the `queryFn` will not be invoked.
     *
     * Use this to control how long to cache query results.
     *
     * Defaults to `0`.
     */
    staleTime?: number;
    /**
     * Time (in milliseconds) before data is removed from the cache.
     *
     * Note that this is different from `staleTime` (and this difference is a common pitfall when learning to use react-query): while `staleTime` determines how long data is considered 'fresh', it doesn't determine how long data is cached -- in other words, data can be fresh (not-stale) but not cached.
     *
     * Use this to control how long to cache query results.
     *
     * Defaults to `0` if `staleTime` is not set, else defaults to `staleTime` + 1 minute.
     */
    cacheTime?: number;
    onError?: (error: Error) => void;
  }
) {
  const {
    enabled,
    getNextPageParam,
    getPreviousPageParam,
    variables = {},
    defaultParams,
    staleTime,
    cacheTime,
    onError,
  } = opts;
  const { getAccessTokenSilently, operationName, rawQuery, role, handleAuthErrors } =
    useGraphQLPrep(query);
  // query will be re-run whenever queryKey changes
  const queryKey = [operationName, variables] as const;

  // be wary of using isLoading. react-query v4 introduces a breaking change (see https://sajadtorkamani.com/react-query-isinitialloading).
  // in most cases, you'll probably want to use isInitialLoading or isFetching
  const {
    data,
    error,
    isError,
    isSuccess,
    status,
    refetch,
    isRefetching,
    isLoading,
    isFetched,
    isFetching,
    hasNextPage = true,
    hasPreviousPage = false,
    fetchNextPage,
    fetchPreviousPage,
    isFetchingNextPage,
    isFetchingPreviousPage,
  } = useInfiniteQuery({
    queryKey,
    queryFn: async ({ queryKey, pageParam = defaultParams }) => {
      const [operationName, variables] = queryKey;
      return graphQLRequest<Variables | PageParams, Response>(rawQuery, {
        token: await getAccessTokenSilently(),
        operationName,
        role,
        variables: {
          ...variables,
          ...pageParam,
        },
      });
    },
    enabled,
    initialPageParam: defaultParams,
    placeholderData: keepPreviousData,
    getNextPageParam,
    getPreviousPageParam,
    staleTime,
    gcTime: cacheTime || staleTime === undefined ? 0 : staleTime + DEFAULT_CACHE_TIME_IN_MS,
  });

  useEffect(() => {
    if (!error || !onError) return;

    handleAuthErrors(error);
    onError(error as Error);
  }, [error]);

  return {
    query: data,
    error,
    /**
     * `true` if the query is running (i.e either fetching for the first time or re-fetching), else `false`.
     */
    isFetching,
    isError,
    isSuccess,
    status,
    /**
     * The GraphQL operation name, as parsed from raw query string.
     */
    operationName,
    /**
     * Re-run this query. `refetch` also allows you to alter the query key (e.g to change the variables passed to the GraphQL server).
     */
    refetch,
    isRefetching,
    /**
     * `true` if the query is running for the first time, else `false`.
     */
    isInitialLoading: isLoading,
    hasFetchedAtLeastOnce: isFetched,
    hasNextPage,
    hasPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    isFetchingNextPage,
    isFetchingPreviousPage,
  };
}

/**
 * Parses a raw GraphQL query, extracts the operation's name and returns it. Throws an error if a malformed query is provided.
 * @param rawQuery string
 * @returns string
 */
export function getOperationNameFromRawQuery(rawQuery: string): string {
  const re = /^(query|mutation)\s(\w)+/;

  const [matched] = re.exec(rawQuery) ?? [];
  if (!matched) {
    const msg = "Operation name was not found in query";
    console.error(msg, { rawQuery, re });
    throw new Error(msg);
  }

  const [, operationName] = matched.split(" ");
  return operationName;
}

interface GraphQLRequestArgs<Variables extends object = {}> {
  variables?: Variables;
  token?: string;
  role?: ProductCloudRole;
  operationName: string;
  fetchFn?: typeof fetch;
  /**
   * Whether to append auth headers to request. Hooks like `useReadOnlyConfigurator` need this set to `true`.
   * @default false
   */
  incognito?: boolean;
}

export interface ErrorWithGraphQLErrors extends Error {
  graphQLErrors?: Array<GraphQLError>;
}

/**
 * Initiates a GraphQL request to the GraphQL server with the appropriate authorisation headers for the given access token and role. Also handles any GraphQL errors that occurs.
 * @param query a raq GraphQL query
 * @returns `Response`
 */
export async function graphQLRequest<Variables extends object = {}, Response = unknown>(
  query: string,
  {
    variables,
    token,
    operationName,
    role = PRODUCT_CLOUD_DEFAULT_ROLE,
    fetchFn = fetch,
    incognito = false,
  }: GraphQLRequestArgs<Variables>
): Promise<Response> {
  const authHeaders = incognito ? {} : getAuthHeaders({ token, role });

  try {
    const res = await fetchFn(hasuraEndpoint, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        ...authHeaders,
        "Access-Control-Expose-Headers": [
          "X-Hasura-Query-Cache-Key",
          "X-Hasura-Query-Family-Cache-Key",
          "Warning",
        ].join(","),
      },
      body: JSON.stringify({
        query,
        variables,
        operationName,
      }),
    });

    const json = await res.json();

    const hasGraphQLErrors = !!json.errors?.length;

    if (hasGraphQLErrors) {
      const err: ErrorWithGraphQLErrors = new Error(
        "GraphQL error:\n" + json.errors.map((err: any) => err.message).join("\n")
      );
      err.graphQLErrors = json.errors.map(
        (err: any) =>
          new GraphQLError(err.message, {
            nodes: undefined,
            source: undefined,
            positions: undefined,
            path: err.extensions?.path,
            originalError: new Error(err.message),
            extensions: err.extensions,
          })
      );

      throw err;
    }

    return json as Response;
  } catch (err) {
    // will catch both network errors and GraphQL errors, but error handlers will only be invoked for GraphQL errors
    handleGraphqlError(err as Error, [
      ({ message, locations, path }: GraphQLError) =>
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          { err }
        ),
    ]);

    throw err;
  }
}

export function handleGraphqlError(
  err: ErrorWithGraphQLErrors,
  errorHandlers?: Array<CallableFunction>
) {
  errorHandlers ??= [];

  if (!err.graphQLErrors) {
    console.error(`[Network Error]: ${err}`);
  } else {
    errorHandlers.forEach(errorHandler => {
      err.graphQLErrors?.forEach(gqlErr => errorHandler(gqlErr));
    });
  }

  // bubble error up to react-query's `onError` callback so that both `isError` and `error` are set
  throw err;
}
