import { LinkProps } from "next/link";
import { getUrlFromTypeAndSlug } from "@lib/utils/urlHelper";
import { ExternalLink, InternalLink } from "@sourceful/shared-types";
import { CSS } from "@sourceful/design-system";
import Link from "../Link";
import { FC } from "react";

export interface IStandardLinkProps extends Omit<LinkProps, "href"> {
  internalLink?: InternalLink;
  externalLink?: ExternalLink;
  css?: CSS;
  children?: React.ReactNode;
}

export const StandardLink: FC<IStandardLinkProps> = ({
  internalLink,
  externalLink,
  css,
  children,
}) => {
  if (externalLink && externalLink.link) {
    if (externalLink.link.startsWith("/")) {
      return (
        <Link
          css={{ ...css }}
          data-link=""
          color="blue"
          target="_blank"
          rel="noopener noreferrer"
          href={externalLink.link}
        >
          {children}
        </Link>
      );
    } else {
      return (
        <Link
          css={{ ...css }}
          data-link=""
          color="blue"
          target={externalLink.newWindow ? "_blank" : undefined}
          rel="noopener noreferrer"
          href={externalLink.link}
        >
          {children}
        </Link>
      );
    }
  }

  if (internalLink) {
    return (
      <Link
        css={{ ...css }}
        data-link=""
        color="blue"
        target="_blank"
        rel="noopener noreferrer"
        href={getUrlFromTypeAndSlug(internalLink._type, internalLink.slug)}
      >
        {children}
      </Link>
    );
  }

  return <a href="#">{children}</a>;
};

export default StandardLink;
