import { useAuthentication } from "@providers/AuthenticationProvider";
import { useRouter } from "next/router";
import { useCallback } from "react";

export function useRedirectToLogin() {
  const { loginWithRedirect } = useAuthentication();
  const router = useRouter();

  return useCallback(() => {
    const REDIRECTION_PATH = "/handleRedirect";

    const redirectUriBase = window?.location?.origin ?? "https://shop.sourceful.com";
    const redirectUri = redirectUriBase.concat(REDIRECTION_PATH);
    const returnTo = router.asPath;

    return loginWithRedirect({
      authorizationParams: {
        redirect_uri: redirectUri,
      },
      appState: {
        returnTo,
      },
    });
  }, [loginWithRedirect, router?.asPath]);
}
