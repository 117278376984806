import {
  Chip,
  Heading,
  styled,
  Text,
  InputField,
  PrimaryButton,
} from "@sourceful/shared-components";
import toRem from "@styles/postprocess/conversion/to-rem";
import { useState } from "react";

const Wrapper = styled("div", {
  minWidth: toRem(250),
  display: "grid",
  gridGap: toRem(20),
  maxWidth: toRem(500),
  padding: toRem(20),
});

const Form = styled("form", {
  display: "grid",
  gridGap: toRem(20),
});

interface PostcodeSelectorProps {
  initialPostcode?: string;
  handleSubmit: (postcode: string, countryCode: string) => void;
}

const PostcodeSelector = ({ initialPostcode = "", handleSubmit }: PostcodeSelectorProps) => {
  const [postcode, setPostcode] = useState(initialPostcode);
  const [countryCode] = useState("GB");
  const [error, setError] = useState("");

  const validatePostcode = () => {
    const postCodeRegExp = /^([A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/gim; // see https://stackoverflow.com/questions/164979/regex-for-matching-uk-postcodes
    const isValidPattern = postCodeRegExp.test(postcode);

    if (!isValidPattern) {
      setError("Please enter a valid postcode");
      return isValidPattern;
    }
    return isValidPattern;
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    const isValid = validatePostcode();
    isValid && handleSubmit(postcode, countryCode);
  };

  return (
    <Wrapper data-cy="postcode-selector">
      <Heading level={4}>Enter Postcode</Heading>
      <Text level={2}>
        Enter your delivery postcode to get better estimates on pricing and carbon footprint
      </Text>

      <Chip
        iconName="alert-information-fill"
        text="Delivery is currently only available to the UK"
      />

      <Form onSubmit={onSubmit}>
        <InputField
          isRequired
          name="postcode"
          value={postcode}
          size="medium"
          placeholder="Enter postcode"
          label="Post Code"
          data-cy="postcode-input"
          type="text"
          handleChange={e => setPostcode(e.currentTarget.value)}
          handleBlur={() => {
            validatePostcode();
          }}
          errors={error ? [error] : undefined}
        />

        <InputField
          isRequired
          isDisabled
          name="countryCode"
          value={countryCode}
          data-cy="countrycode-input"
          size="medium"
          placeholder="Enter Country Code"
          label="Country Code"
          type="text"
          handleChange={() => {}}
        />

        <PrimaryButton data-cy="submit-postcode-button">Submit</PrimaryButton>
      </Form>
    </Wrapper>
  );
};

export default PostcodeSelector;
