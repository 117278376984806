import { SanityMenuItem, SanityPrimaryNavigation } from "@groq/siteHeaderConfig";
import { getSanityImgUrl } from "@lib/sanity";
import { getUrlFromTypeAndSlug } from "@lib/utils/urlHelper";
import React, { MouseEvent, useEffect, useRef, useState } from "react";
import SiteLogo from "../SiteLogo";
import { breakpoints, Icon } from "@sourceful/shared-components";
import PrimaryNavigationBurger from "../PrimaryNavigationBurger";
import PrimaryNavigationSearch from "../PrimaryNavigationSearch";
import GlobalHeaderAccount from "@components/siteHeader/headerAccount";
import FocusTrapCatcher from "@components/focusTrapCatcher";
import { useOnRouteChange } from "@lib/hooks/useOnRouteChange";
import { MenuItem } from "./MenuItem";
import {
  ActionsContainer,
  burgerLogoNavCss,
  closeButtonCss,
  headingContainerCss,
  innerCss,
  menuCss,
  menuHeadingCss,
  menuInfoCss,
  primaryNavCss,
  primaryNavListCss,
} from "./styles";
import { SanityStandardLink } from "@sourceful/shared-types";
import { apiConfig } from "apiConfig";

export interface INestedMenuItem {
  _key: string;
  title: string;
  href?: string;
  description?: string;
  icon?: string;
  image?: string;
  menu?: IMenu;
}

export interface IMenuSection {
  _key: string;
  heading?: string;
  items: INestedMenuItem[];
}

export interface IMenu {
  id: string;
  heading: string;
  sections: IMenuSection[];
}

export interface IMenuItem {
  _key: string;
  id: string;
  panelId?: string;
  title: string;
  href?: string;
  menu?: IMenu;
  isActive: boolean;
  isBurgerOnly?: boolean;
}

function mapMenuItem(item: SanityMenuItem, index: number, level: number): IMenuItem {
  let navItem: IMenuItem = {
    _key: item._key,
    id: `p-${level}-${index}`,
    title: item.linkText,
    href: getHrefFromStandardLink(item.standardLink),
    isActive: false,
    panelId: `panel-${level}-${index}`,
    isBurgerOnly: item.burgerMenuOnly,
  };

  if (item.menuItems && item.menuItems.length > 0) {
    const menu: IMenu = {
      id: `menu-${level}-${index}`,
      heading: item.linkText,
      sections: [{ _key: `menu-section-${level}-${index}`, items: [] }],
    };

    menu.sections[0].items = item.menuItems.map((x, i) => {
      return mapNestedMenuItem(x, i, level + 1, x._key);
    });

    navItem.menu = menu;
  }

  return navItem;
}

function getHrefFromStandardLink(standardLink: SanityStandardLink): string {
  if (standardLink.internalLink) {
    return getUrlFromTypeAndSlug(standardLink.internalLink._type, standardLink.internalLink.slug);
  }

  if (standardLink.externalLink) {
    return standardLink.externalLink.link;
  }

  return "#";
}

function mapNestedMenuItem(
  item: SanityMenuItem,
  index: number,
  level: number,
  key: string
): INestedMenuItem {
  let navItem: INestedMenuItem = {
    _key: key,
    title: item.linkText,
    href: getHrefFromStandardLink(item.standardLink),
    description: item.linkDescription,
  };

  if (item.linkIcon) {
    navItem.icon = item.linkIcon;
  }

  if (item.linkImage) {
    navItem.image = getSanityImgUrl(item.linkImage.asset._ref, base => base.width(80).height(80));
  }

  if (item.menuItems && item.menuItems.length > 0) {
    const menu: IMenu = {
      id: `menu-${level}-${index}`,
      heading: item.linkText,
      sections: [{ _key: `menu-section-${level}-${index}`, items: [] }],
    };

    menu.sections[0].items = item.menuItems.map((x, i) => {
      return mapNestedMenuItem(x, i, level + 1, x._key);
    });

    navItem.menu = menu;
  }

  return navItem;
}

function transformDataToNavItems(data: SanityPrimaryNavigation): IMenuItem[] {
  if (!data) return [];

  return data.menuItems.map((x, i) => {
    return mapMenuItem(x, i, 1);
  });
}

interface PrimaryNavigationProps {
  data: SanityPrimaryNavigation;
}

export function PrimaryNavigation({ data }: PrimaryNavigationProps) {
  const burgerButtonRef = useRef<HTMLButtonElement>(null);

  const [navItems, setNavItems] = useState<IMenuItem[]>(transformDataToNavItems(data));
  const [menuVisible, setMenuVisible] = useState(false);

  useEffect(() => {
    setNavItems(transformDataToNavItems(data));
  }, [data]);

  useEffect(() => {
    // Move this out of this components, tried adding to _document.tsx but was struggling with Class syntax
    document.body.className = "js";
  }, []);

  const isTouchDevice = !!global.window?.document?.documentElement?.ontouchstart;

  const isBurgerMenu = () => {
    return (
      typeof window !== "undefined" &&
      !window.matchMedia(`(min-width: ${breakpoints.lg}px)`).matches
    );
  };

  const menuOpenClick = () => {
    setMenuVisible(true);
  };

  const hasActiveMenuItem = () => {
    return navItems.some(x => x.isActive);
  };

  const menuCloseClick = () => {
    if (!hasActiveMenuItem()) {
      setMenuVisible(false);
      return;
    }

    const items = [...navItems];

    items.forEach(i => (i.isActive = false));

    setNavItems(items);

    setMenuVisible(false);
  };

  // Any change to the route lets close the nav
  useOnRouteChange(menuCloseClick);

  const menuButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    const targetEl = e.target as HTMLElement,
      el = targetEl.closest("li") as HTMLLIElement;

    let items = [...navItems];

    items.forEach(i => {
      if (i.id !== el.getAttribute("data-id")) {
        i.isActive = false;
      }
    });

    let item = items.find(x => x.id === el.getAttribute("data-id"));

    if (item) {
      item.isActive = !item.isActive;
      setNavItems(items);
    }
  };

  const menuButtonMouseEnter = (e: MouseEvent<HTMLButtonElement>) => {
    if (isTouchDevice || isBurgerMenu()) return;

    const targetEl = e.target as HTMLElement;

    if (targetEl) {
      const menuLi = targetEl?.closest("[data-id]");

      if (menuLi?.getAttribute("data-active") !== "true") {
        menuButtonClick(e);
      }
    }
  };

  const menuLiMouseLeaveAndBlur = (event: any) => {
    // Don't bother if we have no active items or relatedTarget is null (seems to happen time to time)
    // Also We don't need this in the burger menu
    if (!hasActiveMenuItem() || event.relatedTarget === null || isBurgerMenu()) return;

    const relatedTarget = event.relatedTarget as HTMLElement,
      isExpectedType = "closest" in relatedTarget,
      targetEl = event.target as HTMLElement;

    // If not an expected type, close whatever we have open
    // This can be caused by having a menu open and then tabbing to another window.
    // relatedTarget became the Window which doesn't have "closest" support
    if (!isExpectedType) {
      menuCloseClick();
    } else {
      const menuLi = targetEl?.closest("[data-id]"),
        focusWithin = menuLi?.contains(relatedTarget);

      // Only close if we're not in the hierarchy of our parent
      if (!focusWithin) {
        menuCloseClick();
      }
    }
  };

  const menuBackClick = (e: MouseEvent<HTMLButtonElement>) => {
    const targetEl = e.target as HTMLElement,
      buttonEl = targetEl.closest("button") as HTMLButtonElement,
      items = [...navItems];

    const itemToMakeInActive = items.find(x => x.id === buttonEl.getAttribute("data-id"));

    if (itemToMakeInActive) {
      itemToMakeInActive.isActive = false;

      setNavItems(items);
    }
  };

  const nextItem = () => {
    const currentActiveEl = document.activeElement as HTMLElement;

    if (!currentActiveEl) return;

    if (currentActiveEl.hasAttribute("data-primary-navigation-item")) {
      const items = Array.from(document.querySelectorAll("[data-primary-navigation-item]")),
        currentIndex = items.indexOf(currentActiveEl),
        nextIndex = getNextIndex(currentIndex, items);

      (items[nextIndex] as HTMLElement)?.focus();
    }
  };

  const prevItem = () => {
    const currentActiveEl = document.activeElement as HTMLElement;

    if (!currentActiveEl) return;

    if (currentActiveEl.hasAttribute("data-primary-navigation-item")) {
      const items = Array.from(document.querySelectorAll("[data-primary-navigation-item]")),
        currentIndex = items.indexOf(currentActiveEl),
        prevIndex = getPrevIndex(currentIndex, items);

      (items[prevIndex] as HTMLElement)?.focus();
    }
  };

  const firstItem = () => {
    const currentActiveEl = document.activeElement as HTMLElement;

    if (!currentActiveEl) return;

    if (currentActiveEl.hasAttribute("data-primary-navigation-item")) {
      const items = Array.from(document.querySelectorAll("[data-primary-navigation-item]")),
        nextIndex = getNextIndex(items.length - 1, items);

      (items[nextIndex] as HTMLElement)?.focus();
    }
  };

  const lastItem = () => {
    const currentActiveEl = document.activeElement as HTMLElement;

    if (!currentActiveEl) return;

    if (currentActiveEl.hasAttribute("data-primary-navigation-item")) {
      const items = Array.from(document.querySelectorAll("[data-primary-navigation-item]")),
        prevIndex = getPrevIndex(0, items);

      (items[prevIndex] as HTMLElement)?.focus();
    }
  };

  const getNextIndex = (currentIndex: number, items: Element[]): number => {
    const nextIndex = currentIndex >= items.length - 1 ? 0 : currentIndex + 1,
      navItemData = navItems[nextIndex];

    if (!!navItemData.isBurgerOnly && !isBurgerMenu()) {
      return getNextIndex(nextIndex, items);
    }

    return nextIndex;
  };

  const getPrevIndex = (currentIndex: number, items: Element[]): number => {
    const prevIndex = currentIndex === 0 ? items.length - 1 : currentIndex - 1,
      navItemData = navItems[prevIndex];

    if (!!navItemData.isBurgerOnly && !isBurgerMenu()) {
      return getPrevIndex(prevIndex, items);
    }

    return prevIndex;
  };

  const onKeyDownEvent = (event: React.KeyboardEvent) => {
    event.stopPropagation();

    if (event.code === "ArrowRight" || event.code === "ArrowDown") {
      nextItem();
      event.preventDefault();
    }

    if (event.code === "ArrowLeft" || event.code === "ArrowUp") {
      prevItem();
      event.preventDefault();
    }

    if (event.code === "Home") {
      firstItem();
      event.preventDefault();
    }

    if (event.code === "End") {
      lastItem();
      event.preventDefault();
    }

    if (event.code === "Escape") {
      menuCloseClick();
      event.preventDefault();
    }
  };

  return (
    <nav
      id="primary-navigation"
      role="navigation"
      aria-label="Main navigation"
      data-cy="site-header-primary-nav"
      className={primaryNavCss()}
      onKeyDown={onKeyDownEvent}
    >
      <div className={innerCss()}>
        <div className={burgerLogoNavCss()}>
          <PrimaryNavigationBurger ref={burgerButtonRef} clickEvent={menuOpenClick} />

          <SiteLogo ariaLabel="Return to home" href={`${apiConfig.gtmSite}/`} />

          <div className={menuCss({ open: menuVisible ? "yes" : "no", primaryNav: "yes" })}>
            <FocusTrapCatcher
              active={menuVisible}
              focusTrapOptions={{
                delayInitialFocus: true,
                preventScroll: true,
              }}
            >
              <div>
                <div className={menuInfoCss()}>
                  <button className={closeButtonCss()} onClick={menuCloseClick}>
                    <Icon name="alert-cross-default" />
                    <span hidden>Close menu</span>
                  </button>
                  <div className={headingContainerCss()}>
                    <h2 className={menuHeadingCss()}>Menu</h2>
                  </div>
                </div>
                <ul
                  data-cy="primary-menubar"
                  className={primaryNavListCss({
                    open: menuVisible ? "yes" : "no",
                  })}
                >
                  {navItems.map(navItem => {
                    return (
                      <MenuItem
                        key={navItem._key}
                        item={navItem}
                        menuButtonClickHandler={menuButtonClick}
                        menuButtonMouseEnterHandler={menuButtonMouseEnter}
                        menuLiMouseLeaveAndBlurHandler={menuLiMouseLeaveAndBlur}
                        menuCloseClickHandler={menuCloseClick}
                        menuBackClickHandler={menuBackClick}
                      />
                    );
                  })}
                </ul>
              </div>
            </FocusTrapCatcher>
          </div>
        </div>

        <ActionsContainer>
          <PrimaryNavigationSearch />
          <GlobalHeaderAccount />
        </ActionsContainer>
      </div>
    </nav>
  );
}
