import { Icon, Text } from "@sourceful/shared-components";
import { IconName } from "@sourceful/shared-types";
import Link from "next/link";
import React from "react";
import { INestedMenuItem } from "./PrimaryNavigation";
import { nestedMenuItemCss, nestedMenuItemLinkCss } from "./styles";

interface NestedMenuItemProps {
  item: INestedMenuItem;
}

export function NestedMenuItem({ item }: NestedMenuItemProps) {
  const { icon, image, title, description } = item;
  return (
    <li className={nestedMenuItemCss()}>
      <Link
        href={item.href || ""}
        passHref
        className={nestedMenuItemLinkCss({
          icon: icon ? "yes" : "no",
          image: image ? "yes" : "no",
        })}
        data-nested-menu-item=""
      >
        {icon && <Icon name={icon as IconName} />}
        {image && <img src={image} width="24" height="24" alt="" />}
        <div>
          <span>{title}</span>
          {description && <Text level={4}>{description}</Text>}
        </div>
      </Link>
    </li>
  );
}
