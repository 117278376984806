import { styled } from "@sourceful/shared-components";

const BlockContentFigure = styled("figure", {
  marginBlockStart: 0,
  marginBlockEnd: 0,
  marginInlineStart: 0,
  marginInlineEnd: 0,
});

export default BlockContentFigure;
