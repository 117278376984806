import { getSanityImgUrl } from "@lib/sanity";
import { PortableTextTypeComponentProps } from "@portabletext/react";
import { Image } from "@sourceful/shared-types";
import BlockContentFigure from "./BlockContentFigure";

const BlockContentImage = (props: PortableTextTypeComponentProps<Image>) => {
  if (!props?.value?.asset?._ref) {
    console.error("Embedded image is missing details");
    return null;
  }

  return (
    <BlockContentFigure>
      <img alt={props?.value?.asset?.altText} src={getSanityImgUrl(props?.value?.asset._ref)} />
    </BlockContentFigure>
  );
};

export default BlockContentImage;
