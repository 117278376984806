import { Link as StyledLink, CSS } from "@sourceful/shared-components";
import Link, { LinkProps } from "next/link";
import { getUrlFromTypeAndSlug } from "@lib/utils/urlHelper";
import { ExternalLink, InternalLink } from "@sourceful/shared-types";

export interface IStandardLinkProps extends Omit<LinkProps, "href"> {
  internalLink?: InternalLink;
  externalLink?: ExternalLink;
  children?: React.ReactNode;
  css?: CSS;
}

export const StandardLink = ({
  internalLink,
  externalLink,
  children,
  css,
  ...props
}: IStandardLinkProps) => {
  if (externalLink && externalLink.link) {
    if (externalLink.link.startsWith("/")) {
      return (
        <Link passHref href={externalLink.link} legacyBehavior>
          <StyledLink
            data-link=""
            css={{
              ...css,
            }}
            {...props}
          >
            {children}
          </StyledLink>
        </Link>
      );
    } else {
      return (
        <StyledLink
          data-link=""
          href={externalLink.link}
          {...(externalLink.newWindow && { target: "_blank", rel: "noopener noreferrer" })}
          css={{
            ...css,
          }}
          {...props}
        >
          {children}
        </StyledLink>
      );
    }
  }

  if (internalLink) {
    return (
      <Link
        passHref
        href={getUrlFromTypeAndSlug(internalLink._type, internalLink.slug)}
        legacyBehavior
      >
        <StyledLink
          data-link=""
          {...(internalLink.newWindow && { target: "_blank", rel: "noopener noreferrer" })}
          css={{
            ...css,
          }}
          {...props}
        >
          {children}
        </StyledLink>
      </Link>
    );
  }

  return <a href="#">{children}</a>;
};

export default StandardLink;
