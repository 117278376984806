import StandardLink from "@components/siteHeader/StandardLink";
import { SanitySiteFooterConfig } from "@groq/siteFooterConfig";
import { useSiteFooterConfig } from "@lib/hooks/useSiteFooterConfig";
import { css, Icon, SourcefulWordmark, styled } from "@sourceful/shared-components";
import { useRouter } from "next/router";
import { i18n } from "../../next-i18next.config";
import staticData from "@lib/static-data/site-footer/data.json";
import { getDataBySanityLocale } from "@lib/utils/getDataBySanityLocale";
import FooterLastLine from "./FooterLastLine";
import FSCLogo from "../../app/components/footer/FSCLogo";

const GlobalFooterWrapper = styled("footer", {
  backgroundColor: "$colour-brand-gtm-grey-black",
  display: "flex",
  "@md": {
    justifyContent: "center",
  },
  width: "100%",
});

const GlobalFooterInternalWrapper = styled("div", {
  width: "100%",
  maxWidth: "1408px",
});

const GridWrapper = styled("div", {
  padding: `50px 20px`,
  paddingTop: "0",
  marginTop: "0.5rem",
  width: "100%",
});

const LogoWrapper = styled("div", {
  margin: "50px 20px 20px 20px",
  color: "$colour-lightest",
});

const ColumnHeading = styled("div", {
  fontWeight: "600",
  fontSize: "17px",
  marginBottom: "20px",
  color: "$colour-lightest",
});

const Grid = styled("div", {
  display: "grid",
  gridTemplateColumns: "1fr",
  borderBottom: "1px solid",
  borderColor: "rgba(255,255,255,.1)", // Let this go, ideally need a HEX value to fit in with our implementation

  "@md": {
    gridTemplateColumns: "1fr 1fr 1fr max-content",
    columnGap: "1rem",
  },
  "@lg": {
    columnGap: "1rem",
    gridTemplateColumns: "1fr 1fr 1fr max-content",
  },
  "@xl": {
    columnGap: "1rem",
    gridTemplateColumns: "1fr 1fr 1fr max-content",
  },
});

const UnorderedList = styled("ul", {
  marginTop: "1rem",
  padding: "0rem",
  variants: {
    withBottomBorder: {
      true: {
        paddingBottom: "30px",
        borderBottom: "1px solid",
        borderColor: "rgba(255,255,255,.1)", // Let this go, ideally need a HEX value to fit in with our implementation

        "@md": {
          borderBottom: "none",
        },
      },
    },
  },
});

const StyledListItem = styled("li", {
  listStyle: "none",

  "&:last-child > a": {
    marginBottom: "0",
  },
});

const FinalColumnWrapper = styled("div", {
  marginTop: "1rem",
  marginBottom: "40px",
  padding: "0rem",

  "@lg": {
    marginTop: "0",
  },
});

const FinalColumnWrapperInternal = styled("div", {
  "@lg": {
    width: "initial !important",
  },

  width: "0px",
});

const FinalColumnWrapperOverflow = styled("div", {
  "@lg": {
    width: "initial !important",
    maxWidth: "initial !important",
  },

  width: "500px",
  maxWidth: "80vw",
});

const SocialIconStyling = css({
  color: "$colour-lightest",
  "&:hover": {
    color: "$colour-brand-gtm-green-hue-base",
  },
});

const SocialsWrapper = styled("div", {
  display: "flex",
  gap: "$inline_xs2",
  alignItems: "center",
  justifyContent: "space-between",
  width: "20%",
  minWidth: "50px",
  marginTop: "30px",

  a: {
    display: "inline-flex",
  },
});

interface GlobalFooterProps {
  appearance?: "simplified" | "default";
}

const GlobalFooter = ({ appearance }: GlobalFooterProps) => {
  let { locale } = useRouter();
  const _locale = locale || i18n.defaultLocale;

  let fallbackSiteFooter = getDataBySanityLocale<SanitySiteFooterConfig>(
    _locale,
    staticData as unknown as Record<string, SanitySiteFooterConfig>
  );

  const { config, isLoading } = useSiteFooterConfig(fallbackSiteFooter);

  if (isLoading && !fallbackSiteFooter) {
    return <></>;
  }

  // Fallback should be available outside of the hook for static page generation - otherwise footer is missing in initial document
  const siteFooterConfig = config || fallbackSiteFooter;

  if (!siteFooterConfig) {
    return null;
  }

  return (
    <GlobalFooterWrapper>
      <GlobalFooterInternalWrapper>
        {appearance !== "simplified" && (
          <LogoWrapper>
            <SourcefulWordmark
              css={{
                marginBottom: "1rem",

                "@md": {
                  gridColumn: "initial",
                },
              }}
            />
          </LogoWrapper>
        )}

        <GridWrapper>
          {appearance !== "simplified" && (
            <Grid>
              {siteFooterConfig?.footerSections?.map((footerSection, sectionIndex) => {
                return (
                  <div key={`${footerSection._key}-${sectionIndex}-footer-section`}>
                    <ColumnHeading>{footerSection.title}</ColumnHeading>
                    <UnorderedList key={`${footerSection._key}-${sectionIndex}`}>
                      {footerSection.sectionLinks.map((sectionLink, index) => (
                        <StyledListItem key={`${sectionLink?._key}-${index}`}>
                          <StandardLink
                            internalLink={sectionLink.internalLink}
                            externalLink={sectionLink.externalLink}
                            css={{
                              fontSize: "17px",
                              textDecoration: "none",
                              marginBottom: "15px",
                              color: "$colour-lightest",

                              "&:hover": {
                                color: "$colour-brand-gtm-green-hue-base",
                              },
                            }}
                          >
                            {sectionLink.name}
                          </StandardLink>
                        </StyledListItem>
                      ))}
                    </UnorderedList>
                  </div>
                );
              })}
              <div>
                <FSCLogo style={{ maxHeight: "7em", width: "5.5em", fill: "white" }} />
              </div>

              <FinalColumnWrapper
                css={{
                  "@md": {
                    gridColumn: "initial",
                  },
                }}
              >
                <FinalColumnWrapperInternal>
                  <FinalColumnWrapperOverflow>
                    <ColumnHeading>{siteFooterConfig.socialMediaTitle}</ColumnHeading>

                    <SocialsWrapper>
                      <a
                        href={siteFooterConfig.instagramLink}
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                        aria-label="Instagram"
                      >
                        <Icon className={SocialIconStyling()} name="social-instagram" />
                      </a>
                      <a
                        href={siteFooterConfig.facebookLink}
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                        aria-label="Facebook"
                      >
                        <Icon className={SocialIconStyling()} name="social-facebook" />
                      </a>
                      <a
                        href={siteFooterConfig.linkedInLink}
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                        aria-label="LinkedIn"
                      >
                        <Icon className={SocialIconStyling()} name="social-linkedin" />
                      </a>
                      <a
                        href={siteFooterConfig.twitterLink}
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                        aria-label="Twitter"
                      >
                        <Icon className={SocialIconStyling()} name="social-twitter" />
                      </a>
                    </SocialsWrapper>
                  </FinalColumnWrapperOverflow>
                </FinalColumnWrapperInternal>
              </FinalColumnWrapper>
            </Grid>
          )}

          <FooterLastLine
            siteFooterConfig={siteFooterConfig}
            containerCss={{
              marginTop: "$spacing-fluid-block-m",
            }}
          />
        </GridWrapper>
      </GlobalFooterInternalWrapper>
    </GlobalFooterWrapper>
  );
};

export default GlobalFooter;
