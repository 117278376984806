import { SanityStandardLink } from "@sourceful/shared-types";
import {
  SanityFetcher,
  filterDataToSingleItem,
  standardLinkFields,
} from "@sourceful/shared-utils/sanity-utils";
import groq from "groq";

export interface SanitySiteFooterConfig {
  _id: string;
  callOutHeading: string;
  callOutSubHeading: string;
  callOutPrimaryCta: SanityStandardLink;
  callOutSecondaryCta: SanityStandardLink;
  footerSections: SanityFooterSection[];
  socialMediaTitle: string;
  socialMediaCopy: string;
  twitterLink: string;
  twitterLinkTooltipText: string;
  linkedInLink: string;
  linkedInLinkTooltipText: string;
  facebookLink: string;
  facebookLinkTooltipText: string;
  instagramLink: string;
  instagramLinkTooltipText: string;
  copyrightText: string;
  policyLinks: SanityStandardLink[];
}

export interface SanityFooterSection {
  _key: string;
  title: string;
  sectionLinks: SanityStandardLink[];
}

export const SITE_FOOTER_CONFIG_BY_LOCALE = groq`
*[_type == "siteFooterConfig" && _locale == $locale] {
    _id,
    _locale,
    callOutHeading,
    callOutSubHeading,
    callOutPrimaryCta {
        ${standardLinkFields}
    },
    callOutSecondaryCta {
        ${standardLinkFields}
    },
    footerSections[] {
        title,
        sectionLinks[] {
            ${standardLinkFields}
        } 
    },
    socialMediaTitle,
    socialMediaCopy,
    twitterLink,
    twitterLinkTooltipText,
    linkedInLink,
    linkedInLinkTooltipText,
    facebookLink,
    facebookLinkTooltipText,
    instagramLink,
    instagramLinkTooltipText,
    copyrightText,
    policyLinks[] {
      ${standardLinkFields}
    },
  }
`;

export class SanitySiteFooterConfigFetcher extends SanityFetcher {
  async fetchConfig(): Promise<SanitySiteFooterConfig | null> {
    const result: SanitySiteFooterConfig | SanitySiteFooterConfig[] = await this.client.fetch(
      SITE_FOOTER_CONFIG_BY_LOCALE,
      {
        locale: this.locale,
      }
    );

    return filterDataToSingleItem(result, !!this.isPreview);
  }
}
