import React from "react";
import { styled, Link as StyledLink } from "@sourceful/shared-components";

import { PortableText, PortableTextReactComponents, PortableTextProps } from "@portabletext/react";
import Link from "next/link";
import BlockContentYoutube from "@components/blockContent/BlockContentYoutube";
import BlockContentImage from "@components/blockContent/BlockContentImage";
import BlockContentBodyImage from "@components/blockContent/BlockContentBodyImage";
import BlockContentIconTextContainer from "@components/blockContent/BlockContentIconTextContainer";
import BlockContentCtaLink from "@components/blockContent/BlockContentCtaLink";

// Set up Portable Text serialization

const PortableTextWrapper = styled("div", {
  a: {
    color: "$palette1_colour1",
    fontSize: "initial",
    textDecoration: "underline",
    "&:hover": {
      color: "$palette1_colour1_shade20",
    },
  },
  img: {
    width: "100%",
  },
  iframe: {
    width: "100%",
  },
  blockquote: {
    margin: "auto",
    quotes: '"“" "”"',
  },
});

export const LinkRenderer = (props: any) => {
  // It appears possible to get a weird nested structure if the link isnt applied correctly.
  // value.href can be null in this case...
  // I noticed multiple marks[] and multiple markDefs[] in the same block.
  if (!props?.value?.href) return null;

  if (props.value.href.startsWith("#")) {
    return <a href={props.value.href}>{props.children}</a>;
  }
  if (props.value.href.startsWith("/")) {
    return (
      <Link passHref href={props.value.href} legacyBehavior>
        <StyledLink>{props.children}</StyledLink>
      </Link>
    );
  }
  return (
    <a href={props.value.href} target={"_blank"} rel="noopener noreferrer">
      {props.children}
    </a>
  );
};

const portableTextComponents: Partial<PortableTextReactComponents> = {
  marks: { link: LinkRenderer },
  types: {
    youtube: BlockContentYoutube,
    image: BlockContentImage,
    bodyImage: BlockContentBodyImage,
    iconTextContainer: BlockContentIconTextContainer,
    ctaLink: BlockContentCtaLink,
  },
};

interface BlockContentExtendedProps extends PortableTextProps {
  wrapperClassName?: string;
  color?: string;
}

export const BlockContent = ({
  wrapperClassName,
  color = "$neutral_solid_100",
  ...props
}: BlockContentExtendedProps) => {
  return (
    <PortableTextWrapper css={{ color }} className={wrapperClassName}>
      <PortableText value={props.value} components={portableTextComponents} />
    </PortableTextWrapper>
  );
};
