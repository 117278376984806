import React, { useRef } from "react";
import { Modal } from "@sourceful/shared-components";

import PostcodeSelector from "@components/PostcodeSelector";
import { useFinalMileContext } from "@providers/FinalMileProvider";

interface PostcodeSelectorModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const PostcodeSelectorModal = ({ isOpen, handleClose }: PostcodeSelectorModalProps) => {
  const { finalMile, updateFinalMileMeta, closePostcodeModal } = useFinalMileContext();
  const postcodeModalTriggerRef = useRef<HTMLButtonElement | null>(null);

  return (
    <Modal
      id="postcode-modal"
      triggerRef={postcodeModalTriggerRef}
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <PostcodeSelector
        initialPostcode={finalMile?.postcode || "E4 6AN"}
        handleSubmit={(postcode, countryCode) => {
          updateFinalMileMeta({ postcode, countryCode });
          closePostcodeModal();
        }}
      />
    </Modal>
  );
};

export default PostcodeSelectorModal;
