import { AppState, RedirectLoginOptions } from "@auth0/auth0-react";
import { handleBeforeUnloadConfigurator } from "@components/rfq/RFQModal/helpers";
import { removeGuestBasketItemIdPrefix } from "@providers/ConfiguratorProviderV2/helpers/helpers";

const getConfiguratorUrl = ({
  step,
  productMain,
  basketItemId,
}: {
  step: string;
  productMain: string;
  basketItemId: string;
}) => {
  // remove gues G- prefix as this will have been removed when creating the product
  const basketItemIdFormatted = removeGuestBasketItemIdPrefix(basketItemId);

  const currentPath = `/buy/${productMain}`;
  return `${currentPath}/customise?basketItemId=${basketItemIdFormatted}${
    step ? "&step=" + step : ""
  }`;
};

export const handleConfiguratorFlowSignin = (
  basketItemId: string | number,
  productMain: string,
  step: string,
  loginWithRedirect: (options?: RedirectLoginOptions) => void
) => {
  window.removeEventListener("beforeunload", handleBeforeUnloadConfigurator);
  const appState: AppState = {
    returnTo: getConfiguratorUrl({ step, productMain, basketItemId: basketItemId as string }),
  };

  loginWithRedirect({
    authorizationParams: {
      redirect_uri: window.location.origin + "/handleRedirect",
    },
    appState,
  });
};

export const handleSignin = (
  loginWithRedirect: (options?: RedirectLoginOptions) => Promise<void>,
  appState: AppState
) => {
  loginWithRedirect({
    authorizationParams: {
      redirect_uri: window.location.origin + "/handleRedirect",
    },
    appState,
  });
};

export default handleSignin;
