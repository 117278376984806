import React from "react";

import handleSignin from "@lib/handleSignin";
import { PrimaryButton, styled } from "@sourceful/shared-components";

import AuthenticatedGlobalHeaderAccount, {
  DropdownLinkWithCount,
} from "./Authenticated/AuthenticatedGlobalHeaderAccount";
import { useOnRouteChange } from "@lib/hooks/useOnRouteChange";
import { useAuthentication } from "@providers/AuthenticationProvider";

const SectionWrapper = styled("div", {
  width: "100%",
  //marginBottom: "24px",
});

interface GlobalHeaderAccountContentProps {
  handleClose: () => void;
  draftsCount?: number;
  quotesCount?: number;
  orderCount?: number;
}

export const GlobalHeaderAccountContent = ({
  handleClose,
  orderCount,
}: GlobalHeaderAccountContentProps) => {
  const { loginWithRedirect, isAuthenticated } = useAuthentication();

  useOnRouteChange(handleClose);

  const dropdownLinks: DropdownLinkWithCount[] = [
    {
      _key: "1",
      title: "Account",
      iconName: "user",
      url: "/account",
    },
    {
      _key: "2",
      title: "Orders",
      iconName: "shopping-cart-full",
      url: "/account/orders",
      count: orderCount,
    },
  ];

  return (
    <SectionWrapper>
      {isAuthenticated && <AuthenticatedGlobalHeaderAccount menuItems={dropdownLinks} />}
      {!isAuthenticated && (
        <PrimaryButton
          data-cy="login-button"
          css={{ width: "100%" }}
          onClick={() => {
            handleSignin(loginWithRedirect, { returnTo: "/" });
          }}
        >
          Log in / Sign up
        </PrimaryButton>
      )}
    </SectionWrapper>
  );
};

export default GlobalHeaderAccountContent;
