import FocusTrap from "focus-trap-react";
import { FC } from "react";
import { ErrorStatus, useErrorContext } from "@providers/ErrorProvider";

const FocusTrapCatcher: FC<any> = props => {
  const { children, ...other_props } = props;
  const { reportError } = useErrorContext();

  try {
    return <FocusTrap {...other_props}>{children}</FocusTrap>;
  } catch (error) {
    reportError({
      status: ErrorStatus.local,
      message: "ERROR: Trap Focus race condition",
      stack: error,
    });
    return children;
  }
};

export default FocusTrapCatcher;
