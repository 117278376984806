import groq from "groq";
import {
  SanityFetcher,
  filterDataToSingleItem,
  internalLinkFields,
} from "@sourceful/shared-utils/sanity-utils";
import { Image, PortableTextBlock, SanityStandardLink } from "@sourceful/shared-types";

export interface SanitySiteHeaderConfig {
  _id: string;
  _locale: string;
  primaryNavigation: SanityPrimaryNavigation;
  secondaryNavigation: SanitySecondaryNavigation;
}

export interface SanitySecondaryNavigation {
  isEnabled: boolean;
  content?: PortableTextBlock;
}

export interface SanityPrimaryNavigation {
  menuItems: SanityMenuItem[];
}

export interface SanityMenuItem {
  _key: string;
  linkText: string;
  linkDescription: string;
  linkImage: Image;
  linkIcon: string;
  standardLink: SanityStandardLink;
  menuItems: SanityMenuItem[];
  burgerMenuOnly: boolean;
}

// The reason this is a separate standardLinkFields definition (not the same as the footer)
// is because we are mapping from the linkUrl field in the underlying header data to
// a field named 'standardLink' for consistency across the header/footer
const standardLinkFields = groq`
    "internalLink": linkUrl.internalLink -> {
      ${internalLinkFields}
    },
    "externalLink": {
      "link": linkUrl.externalUrl,
      "newWindow": linkUrl.newWindow
    }
`;

const menuItemFields = groq`
  _key,
  linkText,
  linkDescription,
  "standardLink": {
    ${standardLinkFields},
  },
  linkImage,
  linkIcon,
  burgerMenuOnly,
  menuItems[] {
    _key,
    linkText,
    linkIcon,
    linkDescription,
    "standardLink": {
      ${standardLinkFields},
    },
    linkImage,
    menuItems[] {
      _key,
      linkText,
      linkDescription,
      "standardLink": {
        ${standardLinkFields},
      },
      linkImage,
      linkIcon,
      menuItems[] {
        _key,
        linkText,
        linkDescription,
        "standardLink": {
          ${standardLinkFields},
        },
        linkImage
      }
    }
  }
`;

export const SITE_HEADER_CONFIG_BY_LOCALE = groq`
*[_type == "siteHeaderConfig" && _locale == $locale] {
    _id,
    _locale,
    primaryNavigation {
      menuItems[] {
        ${menuItemFields}
      }
    },
    secondaryNavigation {
      isEnabled,
      content,
    }
  }
`;

export interface SanitySiteHeaderConfigsByLocale {
  [locale: string]: SanitySiteHeaderConfig;
}

export class SanitySiteHeaderConfigFetcher extends SanityFetcher {
  async fetchConfig(): Promise<SanitySiteHeaderConfig | null> {
    const result: SanitySiteHeaderConfig | SanitySiteHeaderConfig[] = await this.client.fetch(
      SITE_HEADER_CONFIG_BY_LOCALE,
      {
        locale: this.locale,
      }
    );

    return filterDataToSingleItem(result, !!this.isPreview);
  }
}
