import { useRouter } from "next/router";
import { useEffect } from "react";

export const useOnRouteChange = (callback: () => void) => {
  const router = useRouter();

  useEffect(() => {
    router.events.on("routeChangeComplete", callback);
    router.events.on("routeChangeError", callback);

    return () => {
      router.events.off("routeChangeComplete", callback);
      router.events.off("routeChangeError", callback);
    };
  }, [callback]);
};
