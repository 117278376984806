import { createContext, FunctionComponent, useContext, useState } from "react";
import { FinalMileMeta } from "@sourceful/shared-types";
import { getFinalMileMeta, saveFinalMileMeta } from "./helpers";
import PostcodeSelectorModal from "./PostcodeSelectorModal";

export interface FinalMileProviderInjectedProps {
  finalMile: FinalMileMeta;
  updateFinalMileMeta: (meta: FinalMileMeta) => void;
  isPostcodeModalOpen: boolean;
  openPostcodeModal: () => void;
  closePostcodeModal: () => void;
}

export interface FinalMileProviderProps {
  children?: React.ReactNode;
}

const FinalMileContext = createContext({} as FinalMileProviderInjectedProps);

const useFinalMileContext = () => useContext(FinalMileContext);

const FinalMileProvider: FunctionComponent<FinalMileProviderProps> = ({ children }) => {
  const initial = getFinalMileMeta();
  const [finalMile, setFinalMile] = useState<FinalMileMeta>(initial);
  const [isPostcodeModalOpen, setIsPostcodeModalOpen] = useState(false);

  const updateFinalMileMeta = (meta: FinalMileMeta) => {
    setFinalMile(meta);
    saveFinalMileMeta(meta);
  };

  return (
    <FinalMileContext.Provider
      value={{
        finalMile,
        updateFinalMileMeta,
        isPostcodeModalOpen,
        openPostcodeModal: () => setIsPostcodeModalOpen(true),
        closePostcodeModal: () => setIsPostcodeModalOpen(false),
      }}
    >
      <PostcodeSelectorModal
        isOpen={isPostcodeModalOpen}
        handleClose={() => setIsPostcodeModalOpen(false)}
      />
      {children}
    </FinalMileContext.Provider>
  );
};

export { FinalMileProvider, useFinalMileContext, FinalMileContext };
