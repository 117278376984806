import FocusTrapCatcher from "@components/focusTrapCatcher";
import { SanityMenuItem, SanityPrimaryNavigation } from "@groq/siteHeaderConfig";
import { useOnRouteChange } from "@lib/hooks/useOnRouteChange";
import { getSanityImgUrl } from "@lib/sanity";
import { getUrlFromTypeAndSlug } from "@lib/utils/urlHelper";
import { useWindowWidth } from "@react-hook/window-size";
import { Icon, breakpoints, css, styled } from "@sourceful/shared-components";
import { SanityStandardLink } from "@sourceful/shared-types";
import toRem from "@styles/postprocess/conversion/to-rem";
import { apiConfig } from "apiConfig";
import React, { FocusEvent, useCallback, useEffect, useRef, useState } from "react";
import { DisplayType } from "./MenuItem";
import MenuSection from "./MenuSection";
import PrimaryNavigationBurger from "./PrimaryNavigationBurger";
import PrimaryNavigationInteractionPanel from "./PrimaryNavigationInteractionPanel";
import PrimaryNavigationItem from "./PrimaryNavigationItem";
import PrimaryNavigationSearch from "./PrimaryNavigationSearch";
import SiteLogo from "./SiteLogo";
import GlobalHeaderAccount from "./headerAccount";

export const PrimaryNav = styled("nav", {
  display: "flex",
  width: "100%",
  justifyContent: "center",
  alignContent: "center",
  padding: "$inline_m1",
  //paddingInline: "clamp(20px, 3vw, 60px)",
  position: "relative",

  "@<lg": {
    // Used to help with the transition from one variant to another.
    // Issue being the state of the panel is controlled by the windows size detected width.
    // This isn't instant so fast snaps between breakpoints can cause a little flicker.
    // Couldn't just hide completely as it caused FocusTrap to bomb out.
    "& .nav-panel-menu-closed": {
      opacity: "0",
      height: "0",
    },
  },
});

export const Inner = styled("div", {
  display: "grid",
  gridTemplateColumns: "1fr min-content",
  gap: "10px",
  width: "100%",
  maxWidth: "1200px",
  position: "relative",

  "@lg": {
    paddingBlockEnd: "0",
  },
});

const PrimaryNavList = styled("ul", {
  //display: "none",
  listStyle: "none",

  "@lg": {
    display: "flex",
    margin: "0",
    marginInlineStart: "clamp(20px, 30px, 50px)",
  },
});

const ActionsContainer = styled("div", {
  display: "flex",
  justifyContent: "end",
  alignItems: "center",

  "& > div": {
    marginLeft: toRem(10),

    "&:first-child": {
      marginLeft: "0",
    },
  },
});

const menuContainerCss = css({
  position: "relative",
});

const headingContainerCss = css({
  display: "flex",
  alignItems: "start",
  marginBottom: "30px",
});

const sectionContainerCss = css({});

const menuHeadingCss = css({
  fontSize: toRem(24),
  color: "#000",
  margin: "0",
  marginInlineEnd: toRem(50),
});

const backButtonCss = css({
  marginInlineEnd: "20px",
  border: "none",
  cursor: "pointer",
  borderRadius: "100%",
  boxShadow: "$lift",
  color: "#404044",

  "&:focus-visible": {
    outline: "none",
  },

  "&:focus-within": {
    boxShadow: "rgb(204 222 249) 0px 0px 0px 2px",
    outline: "none",
  },
});

const closeButtonCss = css({
  border: "none",
  position: "absolute",
  right: "clamp(30px, 6vw, 60px)",
  top: "clamp(30px, 6vw, 60px)",
  cursor: "pointer",
  borderRadius: "100%",

  "@lg": {
    display: "none",
  },

  "&:focus-visible": {
    outline: "none",
  },

  "&:focus-within": {
    boxShadow: "rgb(204 222 249) 0px 0px 0px 2px",
    outline: "none",
  },
});

export const burgerLogoNavWrapper = css({
  display: "flex",
  alignItems: "center",
});

const menuCss = css({
  outline: "none",
});

export interface IMenuItem {
  title: string;
  href?: string;
  description?: string;
  icon?: string;
  image?: string;
  menu?: IMenu;
}

export interface IMenuSection {
  heading?: string;
  items: IMenuItem[];
}

export interface IMenu {
  id: string;
  heading: string;
  sections: IMenuSection[];
}

export interface INavigationItem {
  id: string;
  panelId?: string;
  title: string;
  href?: string;
  menu?: IMenu;
  isActive: boolean;
  isTabbable: boolean;
  shouldFocus?: boolean;
  isBurgerOnly?: boolean;
  activeMenu?: IMenu[];
}

interface IPrimaryNavigationProps {
  data: SanityPrimaryNavigation;
}

export function mapNavigationItem(
  item: SanityMenuItem,
  index: number,
  level: number
): INavigationItem {
  let navItem: INavigationItem = {
    id: `p-${level}-${index}`,
    title: item.linkText,
    href: getHrefFromStandardLink(item.standardLink),
    isActive: false,
    isTabbable: true,
    panelId: `panel-${level}-${index}`,
    activeMenu: [],
    isBurgerOnly: item.burgerMenuOnly,
  };

  if (item.menuItems && item.menuItems.length > 0) {
    const menu: IMenu = {
      id: `menu-${level}-${index}`,
      heading: item.linkText,
      sections: [{ items: [] }],
    };

    menu.sections[0].items = item.menuItems.map((x, i) => {
      return mapMenuItem(x, i, level + 1);
    });

    navItem.menu = menu;
  }

  return navItem;
}

function getHrefFromStandardLink(standardLink: SanityStandardLink): string {
  //console.log("getHrefFromStandardLink", standardLink);

  if (standardLink.internalLink) {
    return getUrlFromTypeAndSlug(standardLink.internalLink._type, standardLink.internalLink.slug);
  }

  if (standardLink.externalLink) {
    return standardLink.externalLink.link;
  }

  return "#";
}

function mapMenuItem(item: SanityMenuItem, index: number, level: number): IMenuItem {
  let navItem: IMenuItem = {
    title: item.linkText,
    href: getHrefFromStandardLink(item.standardLink),
    description: item.linkDescription,
  };

  if (item.linkIcon) {
    navItem.icon = item.linkIcon;
  }

  if (item.linkImage) {
    navItem.image = getSanityImgUrl(item.linkImage.asset._ref, base => base.width(80).height(80));
  }

  if (item.menuItems && item.menuItems.length > 0) {
    const menu: IMenu = {
      id: `menu-${level}-${index}`,
      heading: item.linkText,
      sections: [{ items: [] }],
    };

    menu.sections[0].items = item.menuItems.map((x, i) => {
      return mapMenuItem(x, i, level + 1);
    });

    navItem.menu = menu;
  }

  return navItem;
}

function transformDataToNavItems(data: SanityPrimaryNavigation): INavigationItem[] {
  const navItems = data.menuItems.map((x, i) => {
    return mapNavigationItem(x, i, 1);
  });

  //console.log("PrimaryNavigation.transformDataToNavItems: navItems", navItems);

  return navItems;
}

// deprecated?
export const PrimaryNavigation = ({ data }: IPrimaryNavigationProps) => {
  //console.log("PrimaryNavigation", data);

  const initialRender = useRef(true);

  const [navItems, setNavItems] = useState<INavigationItem[]>(transformDataToNavItems(data));

  const [menuVisible, setMenuVisible] = useState(false);
  const [mainMenuVisible, setMainMenuVisible] = useState(false);

  const [isLgOrBigger, setIsLgOrBigger] = useState(false);

  const burgerButtonRef = useRef<HTMLButtonElement>(null);
  const closeMenuButtonRef = useRef<HTMLButtonElement>(null);
  const closeSectionButtonRef = useRef<HTMLButtonElement>(null);

  const width = useWindowWidth({ wait: 1 });

  const menuSectionCloseClick = () => {
    //console.log("PrimaryNavigation.menuSectionCloseClick");

    clearActiveMenus();

    closeItem();
  };

  const menuCloseClick = useCallback(() => {
    //console.log("PrimaryNavigation.menuCloseClick");
    clearActiveMenus();

    setMenuVisible(false);
  }, []);

  const menuOpenClick = () => {
    setMenuVisible(true);
  };

  useEffect(() => {
    // Move this out of this components, tried adding to _document.tsx but was struggling with Class syntax
    document.body.className = "js";
  }, []);

  useEffect(() => {
    menuCloseClick();

    setIsLgOrBigger(width >= breakpoints.lg);

    //console.log("PrimaryNavigation.useEffect - width", width);
  }, [width]);

  useEffect(() => {
    // TODO: Can be turned into custom hook - https://www.thearmchaircritic.org/tech-journal/prevent-useeffects-callback-firing-during-initial-render
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      //console.log("PrimaryNavigation.useEffect - menuVisible", menuVisible);

      setMainMenuVisible((menuVisible && !isLgOrBigger) || (!menuVisible && isLgOrBigger));

      const timer = menuVisible
        ? setTimeout(() => {
            closeMenuButtonRef.current?.focus();
          }, 1)
        : setTimeout(() => {
            burgerButtonRef.current?.focus();
          }, 1);

      return () => timer && clearTimeout(timer);
    }
  }, [menuVisible]);

  useEffect(() => {
    //console.log("PrimaryNavigation.useEffect - isLgOrBigger", isLgOrBigger);

    setMainMenuVisible((menuVisible && !isLgOrBigger) || (!menuVisible && isLgOrBigger));

    clearActiveMenus();
  }, [isLgOrBigger]);

  const setTabIndexes = (activeIndex: number) => {
    const items = [...navItems];

    items.forEach((item, index) => {
      item.isTabbable = index === activeIndex;
    });

    setNavItems(items);
  };

  const clearActiveMenus = () => {
    const items = [...navItems];

    items.forEach(i => {
      if (i.activeMenu) {
        i.isActive = false;
        i.activeMenu = [];
      }
    });

    setNavItems(items);
  };

  useOnRouteChange(menuCloseClick);

  const listItemClickEvent = (index: number, menu?: IMenu) => {
    const items = [...navItems],
      currentActive = items.find(x => x.isActive),
      targetItem = items[index];

    //console.log("PrimaryNavigation.listItemClickEvent", index, currentActive, targetItem);

    // Toggle active item
    if (currentActive === targetItem) {
      currentActive.isTabbable = true;
      currentActive.isActive = !currentActive?.isActive;
    } else {
      items.forEach(i => {
        i.isActive = false;
        i.isTabbable = false;
        i.shouldFocus = false;
      });

      targetItem.isActive = true;
      targetItem.isTabbable = true;
      targetItem.shouldFocus = true;
    }

    menuItemButtonClick(menu, items);
  };

  const menuOnKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    const target = event.target as HTMLElement,
      menu = target.closest("[role='menu']") as HTMLElement,
      focusabableElements = menu ? getKeyboardFocusableElements(menu, "[role='menuitem']") : null,
      currentFocus = document.activeElement as HTMLElement;

    // console.log(
    //   target,
    //   menu,
    //   focusabableElements,
    //   currentFocus,
    //   focusabableElements?.includes(currentFocus)
    // );

    if (event.code === "Escape") {
      menuSectionCloseClick();
    }

    if (event.code === "ArrowDown") {
      if (focusabableElements?.includes(currentFocus)) {
        const currentIndex = focusabableElements.indexOf(currentFocus),
          nextIndex = currentIndex + 1 > focusabableElements.length - 1 ? 0 : currentIndex + 1;

        focusabableElements[nextIndex].focus();

        event.preventDefault();
      }
    }

    if (event.code === "ArrowUp") {
      if (focusabableElements?.includes(currentFocus)) {
        const currentIndex = focusabableElements.indexOf(currentFocus),
          prevIndex = currentIndex - 1 < 0 ? focusabableElements.length - 1 : currentIndex - 1;

        focusabableElements[prevIndex].focus();

        event.preventDefault();
      }
    }

    if (event.code === "Home") {
      if (focusabableElements?.includes(currentFocus)) {
        focusabableElements[0].focus();

        event.preventDefault();
      }
    }

    if (event.code === "End") {
      if (focusabableElements?.includes(currentFocus)) {
        focusabableElements[focusabableElements.length - 1].focus();

        event.preventDefault();
      }
    }

    event.stopPropagation();
  };

  const getKeyboardFocusableElements = (
    element: HTMLElement,
    selector: string = 'a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])'
  ): HTMLElement[] => {
    const els = element.querySelectorAll<HTMLElement>(selector);

    return Array.from(els).filter(
      el => !el.hasAttribute("disabled") && !el.getAttribute("aria-hidden")
    );
  };

  const onKeyDownEvent = (event: React.KeyboardEvent) => {
    //console.log("PrimaryNavigation.onKeyDownEvent", event);

    event.stopPropagation();

    if (event.code === "ArrowRight" || event.code === "ArrowDown") {
      nextItem();
      event.preventDefault();
    }
    if (event.code === "ArrowLeft" || event.code === "ArrowUp") {
      prevItem();
      event.preventDefault();
    }
    if (event.code === "Home") {
      firstItem();
      event.preventDefault();
    }
    if (event.code === "End") {
      lastItem();
      event.preventDefault();
    }
    if (event.code === "Escape") {
      if (isLgOrBigger) {
        closeItem();
      } else {
        menuCloseClick();
      }
      event.preventDefault();
    }
  };

  const closeItem = () => {
    const items = [...navItems],
      currentActive = items.find(x => x.isActive);

    //console.log("PrimaryNavigation.closeItem", currentActive);

    if (currentActive) {
      currentActive.isActive = false;
    } else {
      menuCloseClick();
    }

    setNavItems(items);
  };

  const setItemByIndex = (index: number) => {
    const currentTabIndex = navItems.findIndex(x => x.isTabbable);

    if (index === currentTabIndex) {
      return;
    }

    const items = [...navItems],
      currentTab = items.find(x => x.isTabbable),
      currentActive = items.find(x => x.isActive);

    //console.log("PrimaryNavigation.setItemByIndex", index, currentTab, currentActive);

    if (currentTab) {
      currentTab.isTabbable = false;
      currentTab.shouldFocus = false;
    }

    if (currentActive) {
      currentActive.isActive = false;
      currentActive.shouldFocus = false;
    }

    const item = items[index];
    item.isTabbable = true;
    item.shouldFocus = true;

    setNavItems(items);
  };

  const prevItem = () => {
    const currentIndex = navItems.findIndex(x => x.isTabbable);

    if (currentIndex === -1) {
      return;
    }

    const prevIndex = currentIndex - 1;

    setItemByIndex(getPrevTabableItem(prevIndex));
  };

  const nextItem = () => {
    const currentIndex = navItems.findIndex(x => x.isTabbable);

    if (currentIndex === -1) {
      return;
    }

    const nextIndex = currentIndex + 1;

    setItemByIndex(getNextTabableItem(nextIndex));
  };

  const firstItem = () => {
    setItemByIndex(getNextTabableItem(0));
  };

  const lastItem = () => {
    setItemByIndex(getPrevTabableItem(navItems.length - 1));
  };

  const getNextTabableItem = (nextIndex: number): number => {
    //console.log("PrimaryNavigation.getNextTabableItem", nextIndex);

    if (nextIndex > navItems.length - 1) {
      return getNextTabableItem(0);
    }

    const item = navItems[nextIndex];

    if (isLgOrBigger && item.isBurgerOnly) {
      return getNextTabableItem(nextIndex + 1);
    }

    return nextIndex;
  };

  const getPrevTabableItem = (prevIndex: number): number => {
    //console.log("PrimaryNavigation.getPrevTabableItem", prevIndex);

    if (prevIndex < 0) {
      return getPrevTabableItem(navItems.length - 1);
    }

    const item = navItems[prevIndex];

    if (isLgOrBigger && item.isBurgerOnly) {
      return getPrevTabableItem(prevIndex - 1);
    }

    return prevIndex;
  };

  const menuItemButtonClick = (menu?: IMenu, updatedNavItems?: INavigationItem[]) => {
    //console.log("PrimaryNavigation.menuItemButtonClick", menu, updatedNavItems);

    const items = updatedNavItems ?? [...navItems],
      currentActive = items.find(x => x.isActive);

    if (currentActive && menu && !currentActive.activeMenu?.includes(menu)) {
      currentActive.activeMenu?.push(menu);
    }

    setNavItems(items);
  };

  const menuSectionBackButtonClick = () => {
    //console.log("menuSectionBackButtonClick");

    const items = [...navItems],
      currentActive = items.find(x => x.isActive);

    if (currentActive) {
      currentActive.activeMenu?.pop();

      if (currentActive.activeMenu?.length === 0) {
        currentActive.isActive = false;
      }
    }

    setNavItems(items);
  };

  const getActiveMenuCount = (isLgOrBigger: boolean) => {
    if (isLgOrBigger) {
      return 1;
    }
    return 0;
  };

  const onFocusEvent = (e: FocusEvent) => {
    const item = e.target;

    // console.log(
    //   "PrimaryNavigation.onFocusEvent",
    //   e,
    //   item.hasAttribute("data-primary-navigation"),
    //   item.getAttribute("data-index")
    // );

    if (item.hasAttribute("data-primary-navigation")) {
      const index = Number.parseInt(item.getAttribute("data-index") ?? "0");
      setTabIndexes(index);
    }
  };

  return (
    <PrimaryNav
      id="primary-navigation"
      role="navigation"
      aria-label="Main navigation"
      onKeyDown={onKeyDownEvent}
      data-cy="site-header-primary-nav"
    >
      <Inner>
        <div className={burgerLogoNavWrapper()}>
          <PrimaryNavigationBurger ref={burgerButtonRef} clickEvent={menuOpenClick} />

          <SiteLogo ariaLabel="Return to home" href={`${apiConfig.gtmSite}/`} />

          {/* NOTE: Tried to use the stitchescss responsive variants, struggling with the initial usage and the styles being inherited */}

          <PrimaryNavigationInteractionPanel
            id="nav-panel"
            labelledby="primary-navigation"
            interaction={isLgOrBigger ? "no" : "yes"}
            layout={isLgOrBigger ? "container" : "full"}
            visible={mainMenuVisible ? "yes" : "no"}
            className={menuVisible ? "nav-panel-menu-open" : "nav-panel-menu-closed"}
          >
            <FocusTrapCatcher
              active={mainMenuVisible && !isLgOrBigger}
              focusTrapOptions={{
                initialFocus: closeMenuButtonRef.current || undefined,
                delayInitialFocus: true,
                allowOutsideClick: isLgOrBigger,
                preventScroll: true,
              }}
            >
              <div>
                {!isLgOrBigger && (
                  <div>
                    <button
                      ref={closeMenuButtonRef}
                      className={closeButtonCss()}
                      onClick={menuCloseClick}
                    >
                      <Icon name="alert-cross-default" />
                      <span hidden>Close menu</span>
                    </button>
                    <div className={headingContainerCss()}>
                      <h2 className={menuHeadingCss()}>Menu</h2>
                    </div>
                  </div>
                )}

                <PrimaryNavList role="menubar" onFocus={onFocusEvent}>
                  {navItems.map((item, index) => {
                    // Find our last activeMenu, this is what we'll display
                    var menu = item.activeMenu?.slice(-1)[0];

                    return (
                      <PrimaryNavigationItem
                        key={item.id}
                        index={index}
                        id={item.id}
                        panelId={item.panelId}
                        title={item.title}
                        href={item.href}
                        isActive={item.isActive}
                        isTabbable={item.isTabbable}
                        shouldFocus={item.shouldFocus}
                        isBurgerOnly={item.isBurgerOnly}
                        onKeyDownEvent={onKeyDownEvent}
                        menu={item.menu}
                        displayType={
                          isLgOrBigger ? DisplayType.PrimaryNavigation : DisplayType.Default
                        }
                        //width={isLgOrBigger ? 1024 : 0}
                        closeRef={closeSectionButtonRef}
                        onMenuItemButtonClick={() => {
                          listItemClickEvent(index, item.menu);
                        }}
                        onMenuItemLinkClick={() => {
                          menuCloseClick();
                        }}
                      >
                        {menu && (
                          <PrimaryNavigationInteractionPanel
                            key={`panel-${item.panelId}`}
                            id={item.panelId ?? "todo"}
                            labelledby={item.id}
                            interaction="yes"
                            layout={{
                              "@initial": "full",
                              "@lg": isLgOrBigger ? "fixed" : "container",
                            }}
                            visible={item.isActive ? "yes" : "no"}
                          >
                            {/* NOTE: Deactivate/focus functionality is useful however needed to find a way to 
                            allow jumping between top level menu items when menu is open. Did something manual
                            for now as it was overriding focus and jumping back. */}

                            {/* Couldn't find a way to make TS allow me to have this signiture for setReturnFocus => (previousActiveElement)
                            https://github.com/focus-trap/focus-trap/blob/master/docs/js/set-return-focus-function.js 
                            Might be useful for figuring our some focus issues... */}

                            <FocusTrapCatcher
                              active={item.isActive}
                              focusTrapOptions={{
                                initialFocus: closeSectionButtonRef.current || undefined,
                                delayInitialFocus: true,
                                allowOutsideClick: isLgOrBigger,
                                returnFocusOnDeactivate: false,
                                checkCanFocusTrap: (): Promise<void> => {
                                  if (closeSectionButtonRef.current) {
                                    return Promise.resolve();
                                  }

                                  return Promise.reject();
                                },
                              }}
                            >
                              <div
                                className={menuCss()}
                                role="menu"
                                onKeyDown={menuOnKeyDown}
                                tabIndex={-1}
                              >
                                <button
                                  ref={item.isActive ? closeSectionButtonRef : null}
                                  className={closeButtonCss()}
                                  onClick={isLgOrBigger ? menuSectionCloseClick : menuCloseClick}
                                  tabIndex={0}
                                >
                                  <Icon name="alert-cross-default" />
                                  <span hidden>Close menu</span>
                                </button>
                                <div className={headingContainerCss()}>
                                  {item.activeMenu &&
                                    item.activeMenu?.length > getActiveMenuCount(isLgOrBigger) && (
                                      <button
                                        className={backButtonCss()}
                                        onClick={menuSectionBackButtonClick}
                                      >
                                        <Icon name="arrow-direction-left" />
                                        <span hidden>Back to parent</span>
                                      </button>
                                    )}
                                  <h2 className={menuHeadingCss()}>{menu.heading}</h2>
                                </div>

                                <div className={menuContainerCss()}>
                                  <div className={sectionContainerCss()}>
                                    {menu.sections.map((section, index) => {
                                      return (
                                        <MenuSection
                                          {...section}
                                          key={`${item.panelId}-section-${index}`}
                                          onMenuItemButtonClick={menuItemButtonClick}
                                        />
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </FocusTrapCatcher>
                          </PrimaryNavigationInteractionPanel>
                        )}
                      </PrimaryNavigationItem>
                    );
                  })}
                </PrimaryNavList>
              </div>
            </FocusTrapCatcher>
          </PrimaryNavigationInteractionPanel>
        </div>

        {/* NOTE: TODO: These global buttons need to allow configuration to set size, they should be 40/40 but currently 50/50 */}
        <ActionsContainer>
          <PrimaryNavigationSearch />
          <GlobalHeaderAccount />
        </ActionsContainer>
      </Inner>
    </PrimaryNav>
  );
};
