import React, { SVGProps } from "react";

type Props = Partial<SVGProps<SVGSVGElement>>;
const FSCLogo = React.forwardRef<SVGSVGElement, Props>((props, ref) => {
  return (
    <svg
      ref={ref}
      //   xml:space="preserve"
      className="fill-current"
      //   style={ { enableBackground: new 0 0 200 254.5}}
      viewBox="0 0 200 254.5"
      y="0px"
      x="0px"
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      version="1.1"
      {...props}
    >
      <g>
        <path
          d="M171.3,18.8H28.1c-4.4,0-8.1,3.6-8.1,8.1v169.9c0,4.4,3.6,8.1,8.1,8.1h143.2c4.4,0,8.1-3.6,8.1-8.1V26.9
            C179.4,22.5,175.8,18.8,171.3,18.8z M177.9,196.8c0,3.6-3,6.6-6.6,6.6H28.1c-3.6,0-6.6-3-6.6-6.6V26.9c0-3.6,3-6.6,6.6-6.6h143.2
            c3.6,0,6.6,3,6.6,6.6L177.9,196.8L177.9,196.8z"
          className="st0"
        ></path>
        <path
          d="M68.4,163.6L68,165l-0.4-1.4l-1.1-4.3h-1.3l-1.2,4.3c-0.2,0.9-0.4,1.4-0.4,1.5l-0.4-1.6l-1.2-4.3h-1.3l2.3,7.4
            h1.3l1.5-5.7l0.3,1.3l1.2,4.4h1.3l2.3-7.4h-1.2L68.4,163.6z"
          className="st0"
        ></path>
        <path
          d="M80.9,159.3l-1.3,4.3l-0.4,1.4l-0.4-1.4l-1.1-4.3h-1.3l-1.2,4.3c-0.2,0.9-0.4,1.4-0.4,1.5l-0.4-1.6l-1.2-4.3
            h-1.3l2.3,7.4h1.3L77,161l0.3,1.3l1.2,4.4h1.3l2.3-7.4H80.9z"
          className="st0"
        ></path>
        <path
          d="M92,159.3l-1.3,4.3l-0.4,1.4l-0.4-1.4l-1.1-4.3h-1.3l-1.2,4.3c-0.2,0.9-0.4,1.4-0.4,1.5l-0.4-1.6l-1.2-4.3H83
            l2.3,7.4h1.3l1.5-5.7l0.3,1.3l1.2,4.4H91l2.3-7.4H92V159.3z"
          className="st0"
        ></path>
        <rect height="1.4" width="1.4" className="st0" y="165.3" x="94.2"></rect>
        <path
          d="M99.1,156.6c-0.3,0.2-0.5,0.5-0.6,0.8c-0.1,0.2-0.1,0.6-0.1,1.1v0.8h-1.1v1h1.1v6.4h1.2v-6.4h1.4v-1h-1.4v-0.7
            c0-0.4,0.1-0.8,0.2-0.9c0.2-0.2,0.4-0.2,0.8-0.2c0.2,0,0.5,0,0.7,0.1l0.2-1.1c-0.4-0.1-0.8-0.1-1.2-0.1
            C99.9,156.3,99.5,156.4,99.1,156.6z"
          className="st0"
        ></path>
        <path
          d="M106.9,162.8c-0.4-0.2-1-0.3-1.9-0.6c-0.6-0.2-1-0.3-1.1-0.3c-0.2-0.1-0.4-0.2-0.5-0.3s-0.2-0.3-0.2-0.4
            c0-0.3,0.1-0.5,0.4-0.7s0.7-0.3,1.2-0.3s0.9,0.1,1.1,0.3c0.3,0.2,0.4,0.5,0.5,0.9l1.2-0.2c-0.1-0.5-0.2-0.9-0.4-1.1
            c-0.2-0.3-0.5-0.5-1-0.7c-0.4-0.2-1-0.3-1.5-0.3c-0.4,0-0.7,0.1-1.1,0.1c-0.3,0.1-0.6,0.2-0.8,0.4c-0.3,0.2-0.5,0.4-0.6,0.7
            s-0.2,0.6-0.2,0.9c0,0.4,0.1,0.7,0.3,1c0.2,0.3,0.5,0.5,0.8,0.7c0.4,0.2,1,0.4,1.9,0.6c0.7,0.2,1.1,0.3,1.3,0.4
            c0.2,0.2,0.4,0.4,0.4,0.7s-0.1,0.6-0.4,0.8s-0.7,0.4-1.3,0.4c-0.6,0-1-0.1-1.3-0.4c-0.3-0.3-0.5-0.6-0.6-1.1l-1.2,0.2
            c0.1,0.8,0.5,1.4,1,1.8c0.5,0.4,1.2,0.6,2.2,0.6c0.6,0,1.1-0.1,1.6-0.3s0.8-0.5,1.1-0.9c0.2-0.4,0.4-0.8,0.4-1.2s-0.1-0.8-0.3-1.1
            C107.5,163.2,107.2,162.9,106.9,162.8z"
          className="st0"
        ></path>
        <path
          d="M114.8,166.1c0.6-0.5,0.9-1.1,1-2l-1.2-0.2c-0.1,0.6-0.3,1.1-0.6,1.4c-0.3,0.3-0.7,0.5-1.2,0.5
            c-0.6,0-1.1-0.2-1.5-0.7s-0.6-1.2-0.6-2.2s0.2-1.7,0.6-2.2s0.9-0.7,1.5-0.7c0.4,0,0.8,0.1,1.1,0.4c0.3,0.3,0.5,0.6,0.6,1.1l1.2-0.2
            c-0.1-0.7-0.5-1.3-1-1.7s-1.2-0.6-2-0.6c-0.6,0-1.2,0.1-1.8,0.4c-0.5,0.3-0.9,0.7-1.2,1.3c-0.3,0.6-0.4,1.3-0.4,2.1
            c0,1.2,0.3,2.2,0.9,2.8c0.6,0.7,1.4,1,2.4,1C113.6,166.9,114.2,166.6,114.8,166.1z"
          className="st0"
        ></path>
        <rect height="1.4" width="1.4" className="st0" y="165.3" x="117.5"></rect>
        <path
          d="M127.5,165.1c0.3-0.5,0.4-1.3,0.4-2.2c0-1.2-0.3-2.1-1-2.8c-0.6-0.7-1.5-1-2.5-1c-0.9,0-1.7,0.3-2.3,0.8
            c-0.8,0.7-1.1,1.7-1.1,3s0.3,2.2,1,2.9c0.6,0.7,1.5,1,2.5,1c0.6,0,1.2-0.2,1.8-0.5C126.8,166.1,127.2,165.7,127.5,165.1z
             M126,165.1c-0.4,0.5-0.9,0.7-1.6,0.7c-0.6,0-1.1-0.2-1.6-0.7c-0.4-0.5-0.6-1.2-0.6-2.1c0-0.9,0.2-1.7,0.6-2.1
            c0.4-0.5,0.9-0.7,1.6-0.7c0.6,0,1.1,0.2,1.6,0.7c0.4,0.5,0.6,1.2,0.6,2.1C126.6,163.9,126.4,164.7,126,165.1z"
          className="st0"
        ></path>
        <path
          d="M131.6,159.4c-0.2,0.2-0.5,0.5-0.8,1v-1.1h-1.1v7.4h1.3v-3.9c0-0.5,0.1-1,0.2-1.5c0.1-0.3,0.2-0.5,0.5-0.7
            s0.5-0.2,0.7-0.2c0.3,0,0.6,0.1,0.9,0.3l0.4-1.2c-0.4-0.3-0.9-0.4-1.3-0.4C132.1,159.1,131.8,159.2,131.6,159.4z"
          className="st0"
        ></path>
        <path
          d="M139.6,160.2c-0.6-0.7-1.3-1.1-2.2-1.1c-0.7,0-1.2,0.2-1.7,0.5s-0.9,0.8-1.1,1.4c-0.3,0.6-0.4,1.3-0.4,2
            c0,1,0.3,1.9,0.8,2.6c0.6,0.7,1.3,1.1,2.4,1.1c0.8,0,1.5-0.3,2.1-1c0,0.8,0,1.4-0.1,1.6c-0.1,0.4-0.3,0.7-0.6,1
            c-0.3,0.2-0.8,0.3-1.3,0.3s-1-0.1-1.3-0.3c-0.2-0.2-0.4-0.4-0.4-0.8l-1.2-0.2c0,0.8,0.2,1.4,0.8,1.8c0.5,0.4,1.3,0.6,2.1,0.6
            c0.7,0,1.4-0.1,1.9-0.4c0.5-0.3,0.9-0.7,1.1-1.1c0.2-0.5,0.4-1.3,0.4-2.5v-6.4h-1.2v0.9H139.6z M139,165c-0.4,0.4-0.9,0.7-1.5,0.7
            s-1.1-0.2-1.5-0.7c-0.4-0.4-0.6-1.2-0.6-2.1c0-0.9,0.2-1.6,0.6-2c0.4-0.5,0.9-0.7,1.4-0.7c0.6,0,1.1,0.2,1.5,0.7s0.6,1.1,0.6,2.1
            C139.6,163.9,139.4,164.6,139,165z"
          className="st0"
        ></path>
        <path
          d="M65.6,108.2c1.7-1,1.9-2.8,2-2.8c14.2-61.4,26.8-65.9,35-65.9c6.9,0,14.9,4.9,14.9,14.8
            c10.3,0,16.8,13.1,8.5,21.1c6.9,3.2,9.9,7.2,9.9,12.2s-4.3,10.1-10.7,10.1c-6.3,0-9.5-3.9-14.4-3.9c-2.2,0-3.6,1.7-3.6,3.4v12.4H98
            V97.3c0-1.9-1.5-3.5-3.3-3.5h-0.8l-12.4,1.5c-1.5,0.2-2.9,1.8-2.9,3.4c0,1.9,1.6,3.5,3.8,3.4c0.3,0,0.6,0,0.6,0l7.8-1v12.2
            c0,1.6,1.8,3.1,3.4,3.1h16.4c1.9,0,3.6-1.6,3.6-3.5v-11.2c3.4,2,8.2,2.8,10.7,2.8c9.2,0,18-7,18-17.3c0-9.3-7.4-14-7.4-14
            c4.3-11.1-2.7-21.9-11.7-24.9c-3.3-11.5-13.4-15.9-21.3-15.9c-24.2,0-32.8,36.5-40.8,65.8C59,95.5,50.1,87,50.1,87
            c-1.8-1.7-3.8-1.2-5.1,0s-1.4,3.5,0,4.9c0.1,0.1,12,11.6,16.2,15.6C62.3,108.5,64,109.2,65.6,108.2z"
          className="st0"
        ></path>
        <path
          d="M112.7,142.3c0-2.4-2.4-4.8-7.6-7.4l-2.4-1.1c-3.6-1.7-5.7-2.7-5.7-4.5c0-1.7,2.2-3,5.4-3
            c4.2,0,5.3,2.3,5.8,4.4l0.1,0.4h0.4c0.9,0,1.5-0.1,1.9-0.5c0.2-0.2,0.3-0.5,0.2-0.8l-0.3-3.5c-0.1-1.1-0.9-1.3-2.1-1.5
            c-1.4-0.3-3.1-0.6-5.6-0.6c-7.2,0-11.6,2.1-11.6,5.7c0,3,3.3,4.8,8.8,7.4c4.3,2,6.8,4.2,6.8,5.9c0,2.8-3,3.8-5.8,3.8
            c-4.4,0-6.9-1.7-8.4-5.6l-0.1-0.3h-0.4c-0.8,0-1.4,0-1.7,0.5c-0.3,0.4-0.2,0.9-0.1,1.4l1,4c0.3,1,0.6,1.1,2.2,1.4
            c0.7,0.1,4.2,0.7,7.2,0.7C109,149.1,112.7,145.7,112.7,142.3z"
          className="st0"
        ></path>
        <path
          d="M140.2,145.5c-0.2-0.3-0.4-0.4-0.7-0.4l-0.5,0.2c-0.8,0.4-3.1,1.4-7.3,1.4c-5.2,0-10.8-3.4-10.8-10.8
            c0-7.1,6-9.6,11.2-9.6c3.7,0,5.7,1.3,6.1,4.1l0.1,0.5h0.5c0.4,0,1.6,0,1.6-0.9v-4.1c0-1-1.2-1.2-1.7-1.2l-0.5-0.1
            c-1.2-0.2-2.3-0.3-5.4-0.3c-10.2,0-17.6,5.3-17.6,12.7c0,6,5.2,12.1,15.2,12.1c5.1,0,7.9-1.2,10-2.3l0.6-0.3L140.2,145.5
            L140.2,145.5z"
          className="st0"
        ></path>
        <path
          d="M65.3,145.9c0,0.5-0.1,1-3.3,1c-0.9,0-1.4,0.2-1.4,1.1c0,0.3,0,0.9,0.9,0.9h0.2c0.4,0,1.4-0.1,6.4-0.1
            c5.3,0,6.4,0.1,6.8,0.1h0.2c0.7,0,1.3-0.1,1.3-0.9c0-1-0.8-1.1-2.2-1.1c-3.2,0-3.2-0.5-3.3-0.9c-0.1-1.6-0.1-3.7-0.1-5.1
            c0,0,0-2.1,0-2.9c0.7,0,2.7,0,2.7,0c4.1,0,4.6,0.1,5.2,3.3c0.2,0.7,0.7,0.8,1.2,0.8c1,0,1.1-0.5,1.1-0.8l-0.1-1.2
            c-0.1-0.7-0.1-1.5-0.1-2.7v-4.9c0-0.7-0.9-0.7-1.4-0.7c-0.8,0-1,0.5-1.5,2.8c-0.2,0.9-1.9,1.4-4.7,1.4c0,0-1.7,0-2.4,0
            c0-0.9,0-7.2,0-7.2c0-2,0.3-2,0.8-2c1.6-0.1,5.4-0.2,6.6-0.2c1.1,0,2.1,0.3,2.8,0.7c2,1.3,3.1,2.1,3.7,2.7c0.6,0.5,1,0.8,1.5,0.8
            c0.3,0,1.2,0,1.2-0.8c0-0.2-0.1-0.4-0.4-1c-0.3-0.7-0.9-2-1.6-3.8c-0.2-0.6-0.9-0.6-2.1-0.6l-5.7,0.1l-9,0.1l-6-0.1h-0.9
            c-0.5,0-0.9,0.4-0.9,0.9c0,0.3,0,1.1,1,1.1c3.6,0,3.8,0.3,3.8,1.7v0.9l0.1,3.7v8C65.5,142.1,65.4,144.2,65.3,145.9z"
          className="st0"
        ></path>
        <polygon
          points="53.4,192.5 54.6,192.5 54.6,188.1 59.1,188.1 59.1,187 54.6,187 54.6,184 59.8,184 59.8,182.9 
            53.4,182.9"
          className="st0"
        ></polygon>
        <path
          d="M67.4,187.3c-0.5-0.2-1.1-0.4-1.9-0.5c-0.9-0.2-1.5-0.4-1.8-0.6c-0.3-0.2-0.5-0.5-0.5-0.9
            c0-0.5,0.2-0.8,0.6-1.1c0.4-0.2,0.9-0.4,1.6-0.4c0.7,0,1.3,0.2,1.7,0.5c0.4,0.3,0.6,0.8,0.7,1.4l1.2-0.1c0-0.9-0.4-1.6-1-2.1
            s-1.5-0.7-2.6-0.7c-1,0-1.9,0.3-2.5,0.8s-0.9,1.1-0.9,1.9c0,0.7,0.2,1.2,0.7,1.6c0.5,0.4,1.3,0.8,2.3,1c1.1,0.2,1.8,0.5,2.3,0.7
            s0.7,0.6,0.7,1.1s-0.2,0.9-0.7,1.2s-1,0.4-1.7,0.4c-0.8,0-1.4-0.2-1.9-0.5s-0.8-0.9-0.9-1.7l-1.2,0.1c0,1,0.4,1.7,1.1,2.3
            c0.7,0.6,1.7,0.9,3,0.9c1.1,0,1.9-0.3,2.6-0.8s1-1.2,1-2.1c0-0.7-0.2-1.2-0.5-1.6C68.3,187.8,67.9,187.5,67.4,187.3z"
          className="st0"
        ></path>
        <path
          d="M77.3,190.9c-0.5,0.4-1.1,0.6-1.8,0.6c-0.9,0-1.7-0.3-2.2-1c-0.6-0.6-0.8-1.6-0.8-3c0-1.2,0.3-2.1,0.8-2.8
            s1.3-1,2.4-1c0.6,0,1.2,0.2,1.6,0.5s0.7,0.8,1,1.5l1.2-0.3c-0.3-0.9-0.7-1.6-1.4-2.1c-0.7-0.5-1.5-0.7-2.4-0.7
            c-1.3,0-2.4,0.4-3.3,1.3c-0.8,0.9-1.3,2.1-1.3,3.6c0,1.4,0.4,2.6,1.1,3.5c0.7,1,1.9,1.5,3.4,1.5c1,0,1.8-0.3,2.5-0.8
            s1.2-1.3,1.5-2.4l-1.3-0.3C78.1,189.9,77.8,190.5,77.3,190.9z"
          className="st0"
        ></path>
        <path
          d="M83.1,182.8c-0.7,0-1.3,0.2-1.7,0.7c-0.5,0.5-0.7,1.1-0.7,1.7c0,0.7,0.2,1.3,0.7,1.7c0.5,0.5,1.1,0.7,1.7,0.7
            c0.7,0,1.3-0.2,1.7-0.7c0.5-0.5,0.7-1.1,0.7-1.7c0-0.7-0.2-1.3-0.7-1.7C84.4,183.1,83.8,182.8,83.1,182.8z M84.6,186.7
            c-0.4,0.4-0.9,0.6-1.5,0.6s-1.1-0.2-1.5-0.6c-0.4-0.4-0.6-0.9-0.6-1.5s0.2-1.1,0.6-1.5s0.9-0.6,1.5-0.6s1.1,0.2,1.5,0.6
            c0.4,0.4,0.6,0.9,0.6,1.5S85,186.3,84.6,186.7z"
          className="st0"
        ></path>
        <path
          d="M83.4,185.5L83.4,185.5c0.2,0,0.4-0.1,0.6-0.3c0.1-0.1,0.2-0.3,0.2-0.5c0-0.1,0-0.3-0.1-0.4
            c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1,0-0.4-0.1-0.6-0.1h-0.9v2.6h0.4v-1.1h0.3c0.2,0,0.3,0,0.4,0.1s0.3,0.4,0.6,1h0.5l-0.3-0.5
            C83.7,185.7,83.5,185.5,83.4,185.5z M83,185.1h-0.5v-0.8H83c0.3,0,0.4,0,0.5,0.1s0.1,0.2,0.1,0.3s0,0.2-0.1,0.3
            C83.4,185.1,83.3,185.1,83,185.1z"
          className="st0"
        ></path>
        <path
          d="M99.2,190.9c-0.5,0.4-1.1,0.6-1.8,0.6c-0.9,0-1.7-0.3-2.2-1c-0.6-0.6-0.8-1.6-0.8-3c0-1.2,0.3-2.1,0.8-2.8
            s1.3-1,2.4-1c0.6,0,1.2,0.2,1.6,0.5s0.7,0.8,1,1.5l1.2-0.3c-0.3-0.9-0.7-1.6-1.4-2.1c-0.7-0.5-1.5-0.7-2.4-0.7
            c-1.3,0-2.4,0.4-3.3,1.3c-0.8,0.9-1.3,2.1-1.3,3.6c0,1.4,0.4,2.6,1.1,3.5c0.7,1,1.9,1.5,3.4,1.5c1,0,1.8-0.3,2.5-0.8
            s1.2-1.3,1.5-2.4l-1.3-0.3C100,189.9,99.7,190.5,99.2,190.9z"
          className="st0"
        ></path>
        <path
          d="M104,185.2v1.1c1-0.4,1.8-0.9,2.4-1.4v7.5h1.2v-9.6h-0.8C106.3,183.8,105.3,184.6,104,185.2z"
          className="st0"
        ></path>
        <path
          d="M114.5,186.2c-1,0-1.8,0.4-2.3,1.3l0,0c0-1.3,0.2-2.2,0.6-2.8s0.9-0.9,1.5-0.9c0.9,0,1.4,0.5,1.6,1.5l1.2-0.1
            c-0.1-0.8-0.4-1.4-0.9-1.8c-0.5-0.4-1.1-0.6-1.8-0.6c-1.1,0-2,0.4-2.5,1.3c-0.6,0.9-0.9,2.1-0.9,3.8s0.3,2.9,1,3.6
            c0.6,0.7,1.4,1,2.3,1s1.6-0.3,2.2-1c0.5-0.6,0.8-1.4,0.8-2.3c0-0.9-0.3-1.6-0.8-2.2C116,186.5,115.3,186.2,114.5,186.2z M115.6,191
            c-0.3,0.4-0.8,0.7-1.3,0.7c-0.6,0-1-0.2-1.4-0.7c-0.4-0.4-0.5-1-0.5-1.6s0.2-1.1,0.6-1.5s0.8-0.6,1.3-0.6c0.6,0,1,0.2,1.4,0.6
            c0.3,0.4,0.5,0.9,0.5,1.5C116.1,190,116,190.6,115.6,191z"
          className="st0"
        ></path>
        <path
          d="M119.1,184.1h4.7c-1,1.1-1.8,2.5-2.4,4c-0.6,1.6-0.9,3-1,4.3h1.2c0.1-1.6,0.5-3.2,1.2-4.7s1.5-2.8,2.4-3.8V183
            H119v1.1H119.1z"
          className="st0"
        ></path>
        <path
          d="M131.5,187.3L131.5,187.3c0.9-0.4,1.4-1,1.4-1.9c0-0.7-0.3-1.3-0.8-1.8s-1.2-0.7-2-0.7s-1.5,0.2-2,0.7
            s-0.8,1.1-0.8,1.8c0,1,0.5,1.6,1.4,1.9l0,0c-0.6,0.1-1,0.4-1.3,0.8c-0.3,0.5-0.5,1-0.5,1.6c0,0.8,0.3,1.5,0.9,2.1s1.3,0.8,2.3,0.8
            c0.9,0,1.7-0.3,2.3-0.8c0.6-0.6,0.9-1.2,0.9-2.1C133.3,188.5,132.7,187.7,131.5,187.3z M128.5,185.3c0-0.4,0.1-0.7,0.4-1
            c0.3-0.3,0.7-0.4,1.1-0.4c0.4,0,0.8,0.1,1.1,0.4c0.3,0.3,0.5,0.7,0.5,1.1c0,0.4-0.2,0.8-0.5,1.1s-0.7,0.4-1.1,0.4
            c-0.4,0-0.8-0.1-1.1-0.4C128.7,186.1,128.5,185.7,128.5,185.3z M131.5,191.1c-0.4,0.4-0.8,0.5-1.4,0.5c-0.6,0-1-0.2-1.4-0.6
            c-0.4-0.4-0.5-0.8-0.5-1.4c0-0.5,0.2-1,0.5-1.4c0.4-0.4,0.8-0.6,1.4-0.6c0.6,0,1,0.2,1.4,0.5c0.4,0.4,0.6,0.8,0.6,1.4
            S131.9,190.8,131.5,191.1z"
          className="st0"
        ></path>
        <path
          d="M135.8,185.2v1.1c1-0.4,1.8-0.9,2.4-1.4v7.5h1.2v-9.6h-0.8C138.1,183.8,137.2,184.6,135.8,185.2z"
          className="st0"
        ></path>
        <path
          d="M147.4,187.3L147.4,187.3c0.9-0.4,1.3-1.1,1.3-2c0-0.7-0.3-1.3-0.8-1.8s-1.2-0.7-2-0.7c-0.7,0-1.4,0.2-1.9,0.6
            c-0.5,0.4-0.9,1-1,1.9l1.2,0.2c0.1-0.6,0.3-1,0.6-1.3s0.7-0.5,1.2-0.5c0.4,0,0.8,0.1,1.1,0.4c0.3,0.3,0.5,0.6,0.5,1.1
            c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.2-0.3,0.4-0.6,0.5c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.4,0-0.7,0l-0.1,1c0.3-0.1,0.6-0.1,0.8-0.1
            c0.6,0,1,0.2,1.4,0.5c0.3,0.4,0.5,0.8,0.5,1.3c0,0.6-0.2,1-0.6,1.4s-0.8,0.6-1.4,0.6c-0.5,0-0.9-0.2-1.2-0.5s-0.5-0.8-0.6-1.4
            l-1.2,0.2c0.1,0.8,0.4,1.5,1,2c0.5,0.5,1.2,0.7,2.1,0.7c0.9,0,1.6-0.3,2.3-0.8c0.6-0.6,0.9-1.3,0.9-2.1c0-0.5-0.2-1-0.5-1.5
            C148.4,187.7,148,187.4,147.4,187.3z"
          className="st0"
        ></path>
        <path
          d="M132.4,46.2c1.5,0,2.8-0.5,3.9-1.6c1.1-1.1,1.6-2.4,1.6-3.9s-0.5-2.8-1.6-3.9c-1.1-1.1-2.4-1.6-3.9-1.6l0,0
            c-1.5,0-2.8,0.6-3.9,1.6c-1.1,1.1-1.6,2.4-1.6,3.9s0.5,2.8,1.6,3.9C129.6,45.6,130.9,46.2,132.4,46.2z M133.3,39.5
            c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.4,0.2-0.9,0.2h-0.9v-1.2h0.8c0.7,0,0.9,0.1,1,0.2C133.2,39.2,133.3,39.3,133.3,39.5z
             M134.4,42.3c-0.3-0.6-0.6-0.9-0.9-1.2c0.3-0.1,0.6-0.3,0.8-0.5c0.3-0.4,0.5-0.8,0.5-1.2s-0.1-0.7-0.3-1s-0.5-0.5-0.9-0.6
            c-0.3-0.1-0.8-0.1-1.4-0.1H130v6.2h1.4v-2.4h0.3c0.3,0,0.5,0.1,0.7,0.2c0.1,0.1,0.4,0.5,1.3,2.1l0.1,0.1h1.5c-0.8,0.7-1.7,1-2.7,1
            c-1.2,0-2.1-0.4-3-1.2s-1.2-1.8-1.2-3s0.4-2.1,1.2-3c0.8-0.8,1.8-1.2,3-1.2c1.1,0,2.1,0.4,3,1.2c0.8,0.8,1.2,1.8,1.2,3
            s-0.4,2.2-1.2,3l-0.1,0.1L134.4,42.3z"
          className="st0"
        ></path>
        <polygon
          points="65.5,231.8 65.5,222.6 69,222.6 69,221.4 60.7,221.4 60.7,222.6 64.2,222.6 64.2,231.8"
          className="st0"
        ></polygon>
        <path
          d="M74.7,225.6c0.3,0.3,0.4,0.8,0.4,1.4v4.8h1.3V227c0-0.7-0.1-1.3-0.3-1.7c-0.2-0.4-0.5-0.7-0.9-0.9
            s-0.9-0.3-1.5-0.3c-0.9,0-1.7,0.3-2.3,1v-3.8h-1.3v10.5h1.3v-4.2c0-0.6,0.1-1.1,0.2-1.4c0.1-0.3,0.4-0.6,0.7-0.8
            c0.3-0.2,0.7-0.3,1.1-0.3C74.1,225.2,74.5,225.3,74.7,225.6z"
          className="st0"
        ></path>
        <path
          d="M85,228c0-1.3-0.3-2.2-1-2.9c-0.6-0.7-1.5-1-2.5-1s-1.9,0.4-2.6,1.1c-0.7,0.7-1,1.7-1,3c0,1.2,0.3,2.2,1,2.9
            s1.5,1,2.6,1c0.9,0,1.6-0.2,2.2-0.6s1-1,1.2-1.8l-1.3-0.2c-0.2,0.5-0.5,0.9-0.8,1.2c-0.3,0.2-0.7,0.4-1.2,0.4
            c-0.6,0-1.2-0.2-1.6-0.7s-0.7-1.1-0.7-1.9H85C85,228.2,85,228.1,85,228z M79.4,227.3c0-0.7,0.3-1.2,0.7-1.6
            c0.4-0.4,0.9-0.6,1.5-0.6c0.7,0,1.2,0.2,1.6,0.7c0.3,0.3,0.4,0.8,0.5,1.4h-4.3V227.3z"
          className="st0"
        ></path>
        <path
          d="M100.9,231.8v-5.2c0-0.9-0.2-1.5-0.6-1.9s-1-0.6-1.7-0.6c-1,0-1.7,0.4-2.3,1.3c-0.1-0.4-0.4-0.7-0.8-1
            c-0.4-0.2-0.8-0.4-1.4-0.4c-0.5,0-1,0.1-1.4,0.3s-0.7,0.5-1,0.9v-1.1h-1.2v7.6h1.3v-3.9c0-0.7,0.1-1.2,0.2-1.6
            c0.1-0.4,0.4-0.6,0.7-0.8s0.6-0.3,1-0.3c0.5,0,0.8,0.1,1,0.4s0.3,0.7,0.3,1.3v4.9h1.3v-4.4c0-0.8,0.2-1.4,0.5-1.7
            c0.4-0.4,0.8-0.5,1.3-0.5c0.3,0,0.6,0.1,0.8,0.2c0.2,0.1,0.4,0.3,0.5,0.5s0.1,0.6,0.1,1.1v4.8L100.9,231.8L100.9,231.8z"
          className="st0"
        ></path>
        <path
          d="M105.4,227.5c-0.5,0.1-0.9,0.1-1.1,0.2c-0.3,0.1-0.7,0.2-0.9,0.4s-0.5,0.4-0.7,0.7s-0.3,0.6-0.3,1
            c0,0.6,0.2,1.2,0.7,1.6c0.4,0.4,1.1,0.6,1.9,0.6c0.5,0,1-0.1,1.4-0.3s0.9-0.5,1.4-0.9c0,0.4,0.1,0.7,0.3,0.9h1.3
            c-0.2-0.3-0.3-0.6-0.3-0.9c-0.1-0.3-0.1-1.1-0.1-2.3v-1.7c0-0.6,0-1-0.1-1.2c-0.1-0.4-0.2-0.6-0.4-0.9c-0.2-0.2-0.5-0.4-0.9-0.6
            c-0.4-0.2-0.9-0.2-1.6-0.2s-1.2,0.1-1.7,0.3s-0.9,0.4-1.1,0.8c-0.3,0.3-0.5,0.8-0.6,1.3l1.3,0.2c0.1-0.5,0.4-0.9,0.6-1.1
            c0.3-0.2,0.7-0.3,1.3-0.3c0.6,0,1.1,0.1,1.5,0.4c0.2,0.2,0.4,0.6,0.4,1.1c0,0,0,0.2,0,0.3C107.2,227.2,106.4,227.3,105.4,227.5z
             M107.7,228.5c0,0.6-0.1,1-0.2,1.3c-0.2,0.4-0.5,0.7-0.9,0.9s-0.8,0.3-1.3,0.3s-0.9-0.1-1.1-0.3c-0.3-0.2-0.4-0.5-0.4-0.9
            c0-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.3-0.3,0.5-0.4s0.6-0.2,1.1-0.3c0.9-0.1,1.6-0.3,2.1-0.5C107.7,228,107.7,228.5,107.7,228.5z"
          className="st0"
        ></path>
        <path
          d="M115.1,224.5c-0.4-0.3-0.9-0.4-1.3-0.4c-0.3,0-0.6,0.1-0.8,0.3c-0.3,0.2-0.5,0.5-0.8,1.1v-1.2H111v7.6h1.3v-4
            c0-0.5,0.1-1,0.2-1.5c0.1-0.3,0.3-0.5,0.5-0.7s0.5-0.3,0.8-0.3s0.6,0.1,0.9,0.3L115.1,224.5z"
          className="st0"
        ></path>
        <polygon
          points="115.9,221.4 115.9,231.8 117.2,231.8 117.2,228.8 118.1,228 120.6,231.8 122.2,231.8 119,227.1 
            121.9,224.2 120.2,224.2 117.2,227.3 117.2,221.4"
          className="st0"
        ></polygon>
        <path
          d="M130.3,232c0.7,0,1.3-0.2,1.8-0.5s1-0.7,1.3-1.3s0.4-1.3,0.4-2.3c0-1.2-0.3-2.2-1-2.8c-0.7-0.7-1.5-1-2.6-1
            c-0.9,0-1.7,0.3-2.4,0.8c-0.8,0.7-1.2,1.7-1.2,3.1c0,1.3,0.3,2.3,1,2.9C128.4,231.7,129.3,232,130.3,232z M128.7,225.9
            c0.4-0.5,1-0.7,1.6-0.7s1.2,0.2,1.6,0.7c0.4,0.5,0.6,1.2,0.6,2.1c0,1-0.2,1.7-0.6,2.2c-0.4,0.5-1,0.7-1.6,0.7s-1.2-0.2-1.6-0.7
            s-0.6-1.2-0.6-2.2S128.3,226.3,128.7,225.9z"
          className="st0"
        ></path>
        <path
          d="M137.2,222.6c0.2-0.2,0.4-0.3,0.8-0.3c0.2,0,0.5,0,0.8,0.1l0.2-1.1c-0.4-0.1-0.8-0.1-1.2-0.1
            c-0.6,0-1,0.1-1.3,0.3s-0.5,0.5-0.7,0.8c-0.1,0.2-0.1,0.6-0.1,1.1v0.8h-1.1v1h1.1v6.6h1.3v-6.6h1.5v-1H137v-0.7
            C137,223.1,137.1,222.8,137.2,222.6z"
          className="st0"
        ></path>
        <path
          d="M38.4,239c-0.3,0.2-0.5,0.5-0.8,1.1v-1.2h-1.2v7.6h1.3v-4c0-0.5,0.1-1,0.2-1.5c0.1-0.3,0.3-0.5,0.5-0.7
            s0.5-0.3,0.8-0.3s0.6,0.1,0.9,0.3l0.4-1.2c-0.4-0.3-0.9-0.4-1.3-0.4C39,238.7,38.7,238.8,38.4,239z"
          className="st0"
        ></path>
        <path
          d="M44.5,238.7c-1,0-1.9,0.4-2.6,1.1c-0.7,0.7-1,1.7-1,3c0,1.2,0.3,2.2,1,2.9s1.5,1,2.6,1c0.9,0,1.6-0.2,2.2-0.6
            s1-1,1.2-1.8l-1.3-0.2c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.4-1.2,0.4c-0.6,0-1.2-0.2-1.6-0.7s-0.7-1.1-0.7-1.9H48c0-0.2,0-0.3,0-0.3
            c0-1.3-0.3-2.2-1-2.9S45.5,238.7,44.5,238.7z M42.3,242c0-0.7,0.3-1.2,0.7-1.6c0.4-0.4,0.9-0.6,1.5-0.6c0.7,0,1.2,0.2,1.6,0.7
            c0.3,0.3,0.4,0.8,0.5,1.4h-4.3V242z"
          className="st0"
        ></path>
        <path
          d="M54.1,242.5c-0.4-0.2-1-0.4-1.9-0.6c-0.6-0.2-1-0.3-1.1-0.3c-0.2-0.1-0.4-0.2-0.5-0.3s-0.2-0.3-0.2-0.5
            c0-0.3,0.1-0.5,0.4-0.7c0.3-0.2,0.7-0.3,1.3-0.3c0.5,0,0.9,0.1,1.2,0.3s0.4,0.5,0.5,0.9l1.3-0.2c-0.1-0.5-0.2-0.9-0.4-1.2
            s-0.5-0.5-1-0.7s-1-0.3-1.6-0.3c-0.4,0-0.8,0.1-1.1,0.2c-0.3,0.1-0.6,0.2-0.8,0.4c-0.3,0.2-0.5,0.4-0.6,0.7s-0.2,0.6-0.2,0.9
            c0,0.4,0.1,0.7,0.3,1s0.5,0.5,0.8,0.7s1,0.4,2,0.6c0.7,0.2,1.1,0.3,1.3,0.4c0.3,0.2,0.4,0.4,0.4,0.7s-0.1,0.6-0.4,0.8
            c-0.3,0.2-0.7,0.4-1.3,0.4s-1-0.1-1.4-0.4c-0.3-0.3-0.5-0.7-0.6-1.2l-1.3,0.2c0.1,0.8,0.5,1.4,1,1.8c0.5,0.4,1.3,0.6,2.3,0.6
            c0.6,0,1.1-0.1,1.6-0.3s0.8-0.5,1.1-0.9s0.4-0.8,0.4-1.2s-0.1-0.8-0.3-1.1C54.8,242.9,54.5,242.6,54.1,242.5z"
          className="st0"
        ></path>
        <path
          d="M61.9,239.2c-0.5-0.3-1.1-0.5-1.7-0.5c-0.5,0-0.9,0.1-1.3,0.3c-0.3,0.2-0.7,0.5-0.9,0.9v-1h-1.2v10.5h1.3v-3.7
            c0.2,0.3,0.5,0.5,0.8,0.7s0.7,0.3,1.2,0.3c0.6,0,1.1-0.2,1.7-0.5c0.5-0.3,0.9-0.8,1.2-1.4c0.3-0.6,0.4-1.3,0.4-2.1
            c0-0.7-0.1-1.4-0.4-2C62.8,240,62.4,239.6,61.9,239.2z M61.5,244.9c-0.4,0.5-0.9,0.7-1.5,0.7s-1-0.2-1.4-0.7s-0.6-1.2-0.6-2.2
            s0.2-1.7,0.6-2.2c0.4-0.5,0.9-0.8,1.5-0.8s1,0.2,1.4,0.7s0.6,1.2,0.6,2.2C62.1,243.6,61.9,244.4,61.5,244.9z"
          className="st0"
        ></path>
        <path
          d="M68.1,238.7c-0.9,0-1.7,0.3-2.4,0.8c-0.8,0.7-1.2,1.7-1.2,3.1c0,1.3,0.3,2.3,1,2.9s1.5,1,2.6,1
            c0.7,0,1.3-0.2,1.8-0.5c0.6-0.3,1-0.7,1.3-1.3s0.4-1.3,0.4-2.3c0-1.2-0.3-2.2-1-2.8S69.1,238.7,68.1,238.7z M69.7,244.9
            c-0.4,0.5-1,0.7-1.6,0.7s-1.2-0.2-1.6-0.7s-0.6-1.2-0.6-2.2s0.2-1.7,0.6-2.2s1-0.7,1.6-0.7s1.2,0.2,1.6,0.7s0.6,1.2,0.6,2.1
            C70.3,243.6,70.1,244.4,69.7,244.9z"
          className="st0"
        ></path>
        <path
          d="M78.8,239.6c-0.2-0.3-0.5-0.5-0.9-0.6c-0.4-0.2-0.8-0.2-1.3-0.2c-1.1,0-1.9,0.4-2.4,1.3V239H73v7.6h1.3v-4.1
            c0-1,0.2-1.6,0.6-2c0.4-0.3,0.9-0.5,1.4-0.5c0.3,0,0.6,0.1,0.9,0.2c0.3,0.2,0.4,0.4,0.5,0.6c0.1,0.3,0.2,0.7,0.2,1.2v4.6h1.3v-4.7
            c0-0.6,0-1-0.1-1.3C79.2,240.2,79,239.9,78.8,239.6z"
          className="st0"
        ></path>
        <path
          d="M85.9,242.5c-0.4-0.2-1-0.4-1.9-0.6c-0.6-0.2-1-0.3-1.1-0.3c-0.2-0.1-0.4-0.2-0.5-0.3s-0.2-0.3-0.2-0.5
            c0-0.3,0.1-0.5,0.4-0.7s0.7-0.3,1.3-0.3c0.5,0,0.9,0.1,1.2,0.3c0.3,0.2,0.4,0.5,0.5,0.9l1.3-0.2c-0.1-0.5-0.2-0.9-0.4-1.2
            c-0.2-0.3-0.5-0.5-1-0.7s-1-0.3-1.6-0.3c-0.4,0-0.8,0.1-1.1,0.2s-0.6,0.2-0.8,0.4c-0.3,0.2-0.5,0.4-0.6,0.7s-0.2,0.6-0.2,0.9
            c0,0.4,0.1,0.7,0.3,1c0.2,0.3,0.5,0.5,0.8,0.7c0.4,0.2,1,0.4,2,0.6c0.7,0.2,1.1,0.3,1.3,0.4c0.3,0.2,0.4,0.4,0.4,0.7
            s-0.1,0.6-0.4,0.8s-0.7,0.4-1.3,0.4c-0.6,0-1-0.1-1.4-0.4c-0.3-0.3-0.5-0.7-0.6-1.2L81,244c0.1,0.8,0.5,1.4,1,1.8
            c0.5,0.4,1.3,0.6,2.3,0.6c0.6,0,1.1-0.1,1.6-0.3s0.8-0.5,1.1-0.9c0.3-0.4,0.4-0.8,0.4-1.2s-0.1-0.8-0.3-1.1
            C86.6,242.9,86.3,242.6,85.9,242.5z"
          className="st0"
        ></path>
        <rect height="7.6" width="1.3" className="st0" y="238.9" x="88.6"></rect>
        <rect height="1.5" width="1.3" className="st0" y="236" x="88.6"></rect>
        <path
          d="M97.6,239.8c-0.3-0.3-0.6-0.6-1-0.8s-0.9-0.3-1.3-0.3c-0.8,0-1.5,0.3-2.1,1V236h-1.3v10.5H93v-1
            c0.5,0.7,1.2,1.1,2.1,1.1s1.7-0.4,2.3-1.1c0.6-0.7,1-1.7,1-3c0-0.5-0.1-1.1-0.2-1.5S97.8,240.1,97.6,239.8z M96.5,244.9
            c-0.4,0.5-0.9,0.7-1.5,0.7c-0.7,0-1.2-0.3-1.6-1c-0.2-0.4-0.4-1.1-0.4-2s0.2-1.6,0.6-2.1s0.9-0.7,1.5-0.7s1,0.2,1.4,0.7
            s0.6,1.2,0.6,2.2C97.1,243.7,96.9,244.4,96.5,244.9z"
          className="st0"
        ></path>
        <rect height="10.5" width="1.3" className="st0" y="236" x="100"></rect>
        <path
          d="M106.4,238.7c-1,0-1.9,0.4-2.6,1.1c-0.7,0.7-1,1.7-1,3c0,1.2,0.3,2.2,1,2.9s1.5,1,2.6,1c0.9,0,1.6-0.2,2.2-0.6
            s1-1,1.2-1.8l-1.3-0.2c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.4-1.2,0.4c-0.6,0-1.2-0.2-1.6-0.7s-0.7-1.1-0.7-1.9h5.7c0-0.2,0-0.3,0-0.3
            c0-1.3-0.3-2.2-1-2.9C108.2,239.1,107.4,238.7,106.4,238.7z M104.2,242c0-0.7,0.3-1.2,0.7-1.6s0.9-0.6,1.5-0.6
            c0.7,0,1.2,0.2,1.6,0.7c0.3,0.3,0.4,0.8,0.5,1.4h-4.3V242z"
          className="st0"
        ></path>
        <path
          d="M117.3,237.3c0.2-0.2,0.4-0.3,0.8-0.3c0.2,0,0.5,0,0.8,0.1l0.2-1.1c-0.4-0.1-0.8-0.1-1.2-0.1
            c-0.6,0-1,0.1-1.3,0.3s-0.5,0.5-0.7,0.8c-0.1,0.2-0.1,0.6-0.1,1.1v0.8h-1.1v1h1.1v6.6h1.3v-6.6h1.5v-1h-1.5v-0.7
            C117.1,237.7,117.1,237.4,117.3,237.3z"
          className="st0"
        ></path>
        <path
          d="M122.6,238.7c-0.9,0-1.7,0.3-2.4,0.8c-0.8,0.7-1.2,1.7-1.2,3.1c0,1.3,0.3,2.3,1,2.9c0.7,0.7,1.5,1,2.6,1
            c0.7,0,1.3-0.2,1.8-0.5c0.6-0.3,1-0.7,1.3-1.3s0.4-1.3,0.4-2.3c0-1.2-0.3-2.2-1-2.8S123.7,238.7,122.6,238.7z M124.2,244.9
            c-0.4,0.5-1,0.7-1.6,0.7s-1.2-0.2-1.6-0.7s-0.6-1.2-0.6-2.2s0.2-1.7,0.6-2.2s1-0.7,1.6-0.7s1.2,0.2,1.6,0.7s0.6,1.2,0.6,2.1
            C124.9,243.6,124.6,244.4,124.2,244.9z"
          className="st0"
        ></path>
        <path
          d="M129.7,239c-0.3,0.2-0.5,0.5-0.8,1.1v-1.2h-1.2v7.6h1.3v-4c0-0.5,0.1-1,0.2-1.5c0.1-0.3,0.3-0.5,0.5-0.7
            s0.5-0.3,0.8-0.3s0.6,0.1,0.9,0.3l0.4-1.2c-0.4-0.3-0.9-0.4-1.3-0.4C130.2,238.7,129.9,238.8,129.7,239z"
          className="st0"
        ></path>
        <path
          d="M135.7,238.7c-1,0-1.9,0.4-2.6,1.1c-0.7,0.7-1,1.7-1,3c0,1.2,0.3,2.2,1,2.9s1.5,1,2.6,1c0.9,0,1.6-0.2,2.2-0.6
            s1-1,1.2-1.8l-1.3-0.2c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.4-1.2,0.4c-0.6,0-1.2-0.2-1.6-0.7s-0.7-1.1-0.7-1.9h5.7c0-0.2,0-0.3,0-0.3
            c0-1.3-0.3-2.2-1-2.9S136.7,238.7,135.7,238.7z M133.5,242c0-0.7,0.3-1.2,0.7-1.6s0.9-0.6,1.5-0.6c0.7,0,1.2,0.2,1.6,0.7
            c0.3,0.3,0.4,0.8,0.5,1.4h-4.3V242z"
          className="st0"
        ></path>
        <path
          d="M145.4,242.5c-0.4-0.2-1-0.4-1.9-0.6c-0.6-0.2-1-0.3-1.1-0.3c-0.2-0.1-0.4-0.2-0.5-0.3s-0.2-0.3-0.2-0.5
            c0-0.3,0.1-0.5,0.4-0.7c0.3-0.2,0.7-0.3,1.3-0.3c0.5,0,0.9,0.1,1.2,0.3s0.4,0.5,0.5,0.9l1.3-0.2c-0.1-0.5-0.2-0.9-0.4-1.2
            s-0.5-0.5-1-0.7s-1-0.3-1.6-0.3c-0.4,0-0.8,0.1-1.1,0.2s-0.6,0.2-0.8,0.4c-0.3,0.2-0.5,0.4-0.6,0.7s-0.2,0.6-0.2,0.9
            c0,0.4,0.1,0.7,0.3,1s0.5,0.5,0.8,0.7s1,0.4,2,0.6c0.7,0.2,1.1,0.3,1.3,0.4c0.3,0.2,0.4,0.4,0.4,0.7s-0.1,0.6-0.4,0.8
            c-0.3,0.2-0.7,0.4-1.3,0.4s-1-0.1-1.4-0.4c-0.3-0.3-0.5-0.7-0.6-1.2l-1.3,0.2c0.1,0.8,0.5,1.4,1,1.8c0.5,0.4,1.3,0.6,2.3,0.6
            c0.6,0,1.1-0.1,1.6-0.3s0.8-0.5,1.1-0.9s0.4-0.8,0.4-1.2s-0.1-0.8-0.3-1.1C146,242.9,145.7,242.6,145.4,242.5z"
          className="st0"
        ></path>
        <path
          d="M150.3,245.4c-0.2,0-0.3,0-0.4-0.1c-0.1-0.1-0.2-0.1-0.2-0.3c0-0.1-0.1-0.3-0.1-0.7v-4.4h1.3v-1h-1.3v-2.7
            l-1.3,0.8v1.9h-0.9v1h0.9v4.4c0,0.8,0.1,1.3,0.2,1.5c0.1,0.2,0.3,0.4,0.5,0.6c0.3,0.1,0.6,0.2,1.1,0.2c0.3,0,0.6,0,1-0.1l-0.2-1.1
            C150.6,245.4,150.4,245.4,150.3,245.4z"
          className="st0"
        ></path>
        <path
          d="M154.1,239c-0.3,0.2-0.5,0.5-0.8,1.1v-1.2h-1.2v7.6h1.3v-4c0-0.5,0.1-1,0.2-1.5c0.1-0.3,0.3-0.5,0.5-0.7
            s0.5-0.3,0.8-0.3s0.6,0.1,0.9,0.3l0.4-1.2c-0.4-0.3-0.9-0.4-1.3-0.4C154.6,238.7,154.3,238.8,154.1,239z"
          className="st0"
        ></path>
        <path
          d="M160.3,243.3c-0.2,0.6-0.4,1.1-0.5,1.7c-0.2-0.6-0.3-1.2-0.6-1.8l-1.6-4.4h-1.4l2.9,7.6
            c-0.1,0.1-0.1,0.2-0.1,0.3c-0.2,0.5-0.3,0.8-0.4,1c-0.1,0.2-0.3,0.3-0.4,0.4c-0.2,0.1-0.4,0.1-0.7,0.1c-0.2,0-0.5,0-0.7-0.1
            l0.1,1.2c0.3,0.1,0.6,0.2,0.8,0.2c0.4,0,0.8-0.1,1.1-0.3s0.6-0.5,0.8-0.9c0.2-0.3,0.4-0.9,0.7-1.7l2.9-7.7h-1.3L160.3,243.3z"
          className="st0"
        ></path>
      </g>
    </svg>
  );
});

export default FSCLogo;
