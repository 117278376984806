import { css, styled } from "@sourceful/shared-components";
import toRem from "@styles/postprocess/conversion/to-rem";

export const primaryNavCss = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
  padding: "$inline_m1",
  position: "relative",

  "@lg": {
    justifyContent: "center",
  },
});

export const primaryNavListCss = css({
  margin: "0",
  padding: "0",

  "@lg": {
    display: "flex",
    marginInlineStart: "clamp(20px, 30px, 50px)",
  },
});

export const innerCss = css({
  display: "grid",
  gridTemplateColumns: "1fr min-content",
  gap: "10px",
  width: "100%",
  maxWidth: "1200px",
  position: "relative",

  "@lg": {
    paddingBlockEnd: "0",
  },
});

export const burgerLogoNavCss = css({
  display: "flex",
  alignItems: "center",
});

export const ActionsContainer = styled("div", {
  marginLeft: "auto",
  display: "flex",
  justifyContent: "end",
  alignItems: "center",

  "& > div": {
    marginLeft: toRem(10),

    "&:first-child": {
      marginLeft: "0",
    },
  },
});

export const closeButtonCss = css({
  border: "none",
  position: "absolute",
  right: "clamp(30px, 6vw, 60px)",
  top: "clamp(30px, 6vw, 60px)",
  cursor: "pointer",
  borderRadius: "100%",

  "@lg": {
    display: "none",
  },

  "&:focus-visible": {
    outline: "none",
  },

  "&:focus-within": {
    boxShadow: "rgb(204 222 249) 0px 0px 0px 2px",
    outline: "none",
  },
});

export const headingContainerCss = css({
  display: "flex",
  alignItems: "center",
  marginBlockEnd: "$block_s1",
});

export const menuCss = css({
  backgroundColor: "#FFF",
  display: "none",

  variants: {
    primaryNav: {
      yes: {
        "@lg": {
          display: "block",
        },
      },
      no: {
        "@lg": {
          minWidth: toRem(350),
          width: "auto",
          height: "auto",
          position: "absolute",
          left: "auto",
          top: "calc(100% + 10px)",
          transform: "translateX(calc(-50% + 60px))",
          bshad: "$subtle",
          rad: "$medium",
          borderRadius: "16px",
          padding: "30px",
          overflowX: "visible",
          zIndex: "3",

          "&:before": {
            content: "",
            display: "block",
            width: "100%",
            height: "30px",
            position: "absolute",
            top: "-30px",
            left: "0",
          },
        },
      },
    },
    open: {
      yes: {
        display: "block",

        "@<lg": {
          width: "100%",
          height: "100%",
          overflowX: "auto",
          position: "fixed",
          top: "0",
          left: "0",
          zIndex: 9999,
          padding: "clamp(30px, 6vw, 60px)",
        },
      },
      no: {},
    },
  },
});

export const menuInfoCss = css({
  "@lg": {
    display: "none",
  },
});

export const menuHeadingCss = css({
  fontSize: toRem(24),
  fontWeight: "bold",
  color: "#000",
  margin: "0",
  marginInlineEnd: toRem(50),
});

export const menuLinkCss = css({
  width: "100%",
  border: "none",
  cursor: "pointer",
  textAlign: "left",
  display: "flex",
  alignItems: "center",
  padding: 0,
  paddingBlock: toRem(10),
  fontSize: toRem(16),
  fontWeight: 600,
  color: "#404044",
  transition: "all 0.2s",

  "&:hover": {
    opacity: 0.7,
  },

  div: {
    flexGrow: "1",
  },

  "@lg": {
    height: "50px",
    padding: "9px 14px",
  },
});

export const menuButtonCss = css({
  transition: "all 0.2s",

  "@<lg": {
    width: "100%",
    border: "none",
    cursor: "pointer",
    textAlign: "left",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 0,
    paddingBlock: toRem(10),
    fontSize: toRem(16),
    fontWeight: 600,
    color: "#404044",
    outlineOffset: "1px",

    div: {
      flexGrow: "1",
    },
  },

  "@lg": {
    padding: "9px 14px",
    lineHeight: "26px",
    letterSpacing: "-0.025em",
    alignItems: "center",
    display: "flex",
    height: "50px",
    border: "none",
    backgroundColor: "transparent",
    whiteSpace: "nowrap",
    cursor: "pointer",
    fontWeight: 600,
    position: "relative",
    zIndex: "2",
  },

  variants: {
    active: {
      yes: {},
      no: {},
    },
  },

  "&:hover": {
    opacity: 0.7,
  },
});

export const navigationItemCss = css({
  "&::before": {
    display: "none",
  },

  "@<lg": {
    borderBottom: "1px solid #DCDCDD",

    "&:last-child": {
      border: "none",
    },
  },

  "@lg": {
    "&:not(:first-child)": {
      marginInlineStart: "$inline_s1",
    },

    ".no-js &": {
      "&:hover, &:focus, &:focus-within": {
        [`& .${menuCss}`]: {
          display: "block",
        },
      },
    },
  },

  // Removing outline for hover elements
  "&:hover div a": {
    outline: "none !important",
  },

  variants: {
    burgerOnly: {
      yes: {
        "@lg": {
          display: "none",
        },
      },
      no: {},
    },
  },
});

export const backButtonCss = css({
  marginInlineEnd: "20px",
  border: "none",
  cursor: "pointer",
  borderRadius: "100%",
  boxShadow: "$lift",
  color: "#404044",

  "@lg": {
    // If we re-introduce multiple levels at desktop we need to revisit this
    display: "none",
  },
});

export const nestedMenuItemCss = css({
  borderBottom: "1px solid #DCDCDD",

  "&::before": {
    display: "none",
  },

  "&:last-child": {
    border: "none",
  },
});

export const nestedMenuItemLinkCss = css({
  display: "flex",
  alignItems: "center",
  padding: 0,
  paddingBlock: toRem(10),
  fontSize: toRem(16),
  fontWeight: 600,
  color: "#404044",
  outlineOffset: "2px",
  transition: "all 0.2s",

  "&:hover span": {
    opacity: 0.7,
  },

  div: {
    flexGrow: "1",
  },

  variants: {
    icon: {
      yes: { svg: { marginInlineEnd: toRem(20) } },
      no: {},
    },
    image: {
      yes: { img: { marginInlineEnd: toRem(20) } },
      no: {},
    },
  },
});
