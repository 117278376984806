import { useErrorContext, ErrorStatus, ErrorWithKey } from "@providers/ErrorProvider";
import { styled } from "@sourceful/shared-components";
import toRem from "@styles/postprocess/conversion/to-rem";
import { FC, useEffect, useState } from "react";
import { Icon } from "@sourceful/shared-components";

const Toast = styled("div", {
  top: "$spacing-fluid-block-xs",
  left: "$spacing-fluid-block-xs",
  right: "$spacing-fluid-block-xs",
  position: "absolute",
  zIndex: "20000",
  minHeight: "75px",
  background: "#fb7c7c",
  color: "#730202",
  borderRadius: "$radius-static-m",
  padding: "$spacing-fluid-block-s",
  alignItems: "center",
  display: "flex",

  ".chip-icon": {
    marginRight: toRem(10),
  },

  "& span": {
    flexGrow: 1,
  },
});

const StyledButton = styled("button", {
  all: "unset",
  cursor: "pointer",
  "&:focus": {
    $$focusBorder: "$colors$palette1_colour1_tint60",
    boxShadow: "0 0 0 2px $$focusBorder",
    outline: "none",
  },
  marginLeft: toRem(5),
});

export type ErrorHandlerProps = { children?: React.ReactNode };

const ErrorHandler: FC<ErrorHandlerProps> = ({ children }) => {
  const { getErrorsByType, errors, clearError } = useErrorContext();
  const [fatalErrors, setFatalErrors] = useState<ErrorWithKey[]>();

  useEffect(() => {
    if (Object.keys(errors).length) {
      const errs = getErrorsByType(ErrorStatus.fatal);
      setFatalErrors(errs);
    }
  }, [errors]);

  const handleDismiss = () => {
    fatalErrors?.map(error => clearError(error.key, error.message));
  };

  return (
    <>
      {fatalErrors && fatalErrors.length > 0 && (
        <Toast className="toast">
          <span>ERROR: {fatalErrors[0].message}</span>
          <StyledButton aria-label="close" onClick={handleDismiss}>
            <Icon name="alert-cross-default" height={22} width={22} />
          </StyledButton>
        </Toast>
      )}
      {children}
    </>
  );
};

export default ErrorHandler;
