import { HeaderCountsQuery, HeaderCountsQueryVariables } from "@generated/graphql";
import { useAuthentication } from "@providers/AuthenticationProvider";
import { useGraphQLQuery } from "@lib/hooks/useGraphQLRequest";
import { HEADER_COUNTS } from "@gql/queries/header/getHeaderCounts";
import { GQLResponse } from "@sourceful/shared-types";

export const useFetchHeaderCounts = (): { draftsCount?: number; quotesCount?: number } => {
  const { user, isLoading: userLoading } = useAuthentication();

  const { query: headerQuery } = useGraphQLQuery<
    HeaderCountsQueryVariables,
    GQLResponse<HeaderCountsQuery>
  >(HEADER_COUNTS, {
    variables: {
      user_id: user?.sub!,
    },
    enabled: !userLoading,
  });

  const draftsCount = headerQuery?.data?.product_cloud_configuration_item?.length;
  const quotesCount = headerQuery?.data?.product_cloud_quote?.length;

  return { draftsCount, quotesCount };
};
