import { Icon, css, Text } from "@sourceful/shared-components";
import Link from "next/link";
import { IconName } from "@sourceful/shared-types";
import toRem from "@styles/postprocess/conversion/to-rem";
import { MouseEventHandler, useEffect, useRef, useState } from "react";
import { IMenu } from "./PrimaryNavigation";

const itemCss = css({
  variants: {
    button: {
      true: {
        width: "100%",
        border: "none",
        cursor: "pointer",
        textAlign: "left",
      },
    },
    default: {
      yes: {
        display: "flex",
        alignItems: "center",
        //justifyContent: "space-between",
        padding: 0,
        paddingBlock: toRem(10),
        fontSize: toRem(16),
        fontWeight: 600,
        color: "#404044",
        outline: "none",

        "&:hover span, &:focus span": {
          textDecoration: "underline",
        },

        div: {
          flexGrow: "1",
        },
      },
      no: {},
    },
    active: {
      yes: {},
      no: {},
    },
    icon: {
      yes: {},
      no: {},
    },
    image: {
      yes: {},
      no: {},
    },
    primaryNavigation: {
      yes: {
        padding: "9px 14px",
        lineHeight: "26px",
        letterSpacing: "-0.025em",
        alignItems: "center",
        display: "flex",
        //paddingInline: '25px',
        height: "50px",
        borderRadius: "30px",
        border: "none",
        backgroundColor: "transparent",
        whiteSpace: "nowrap",
        cursor: "pointer",
        fontWeight: 600,
        position: "relative",
        zIndex: "2",

        "&:focus, &:hover": {
          backgroundColor: "#f6f6f6",
        },

        "&:focus-within": {
          boxShadow: "rgb(204 222 249) 0px 0px 0px 4px",
          backgroundColor: "#f6f6f6",
          outline: "none",
        },

        "&:focus-visible": {
          outline: "none",
        },

        "& .icon": {
          marginLeft: toRem(8),
        },
      },
      no: {},
    },
  },
  compoundVariants: [
    {
      primaryNavigation: "yes",
      active: "yes",
      css: {
        backgroundColor: "#f6f6f6",
      },
    },
    {
      primaryNavigation: "no",
      icon: "yes",
      css: {
        div: {
          paddingInlineStart: toRem(20),
        },
      },
    },
    {
      primaryNavigation: "no",
      image: "yes",
      css: {
        div: {
          paddingInlineStart: toRem(20),
        },
      },
    },
    {
      primaryNavigation: "no",
      button: "true",
      icon: "yes",
      css: {
        div: {
          paddingInline: toRem(20),
        },
      },
    },
  ],
});

export enum DisplayType {
  Default,
  PrimaryNavigation,
}

function getIconButtonName(displayType: DisplayType, isActive: boolean = false) {
  if (displayType === DisplayType.PrimaryNavigation) {
    return isActive ? "arrow-direction-up" : "arrow-direction-down";
  } else {
    return "arrow-direction-right";
  }
}

export interface IMenuItemProps {
  title: string;
  description?: string;
  href?: string;
  menu?: IMenu;
  displayType: DisplayType;
  onButtonClick: MouseEventHandler<HTMLButtonElement>;
  onLinkClick?: MouseEventHandler<HTMLAnchorElement>;
  onMouseEnter?: MouseEventHandler<HTMLButtonElement>;
  isActive: boolean;
  isTabbable?: boolean;
  shouldFocus?: boolean;
  icon?: string;
  index?: number;
  id?: string;
  panelId?: string;
  image?: string;
}

export const MenuItem = ({
  title,
  description,
  href,
  menu,
  displayType = DisplayType.Default,
  onButtonClick,
  onLinkClick,
  onMouseEnter,
  isActive,
  isTabbable,
  shouldFocus,
  icon,
  index,
  id,
  panelId,
  image,
}: IMenuItemProps) => {
  const [iconName, setIconName] = useState<string>("");

  useEffect(() => {
    setIconName(getIconButtonName(displayType, isActive));
  }, [displayType, isActive]);

  // Any shared type I can use here... :/
  const buttonRef = useRef<HTMLButtonElement>(null);
  const anchorRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (shouldFocus) {
      buttonRef.current?.focus();
      anchorRef.current?.focus();
    }
  }, [shouldFocus, isActive]);

  let item;

  if (menu) {
    item = (
      <button
        ref={buttonRef}
        role="menuitem"
        onClick={onButtonClick}
        onMouseEnter={onMouseEnter}
        className={itemCss({
          button: true,
          default: displayType === DisplayType.Default ? "yes" : "no",
          primaryNavigation: displayType === DisplayType.PrimaryNavigation ? "yes" : "no",
          active: isActive ? "yes" : "no",
          icon: icon ? "yes" : "no",
          image: image ? "yes" : "no",
        })}
        aria-controls={panelId}
        aria-haspopup={menu ? "true" : "false"}
        aria-expanded={isActive}
        tabIndex={isTabbable === false ? -1 : 0}
        data-primary-navigation={displayType === DisplayType.PrimaryNavigation ? "yes" : "no"}
        data-id={id}
        data-index={index}
      >
        {icon && <Icon name={icon as IconName} />}
        {image && <img className="image-icon" src={image} width="24" height="24" alt="" />}
        <div>
          <span>{title}</span>
          {description && <Text level={4}>{description}</Text>}
        </div>
        <Icon name={iconName as IconName} />
      </button>
    );
  } else {
    item = (
      <Link
        href={href || ""}
        passHref
        ref={anchorRef}
        role="menuitem"
        className={itemCss({
          default: displayType === DisplayType.Default ? "yes" : "no",
          primaryNavigation: displayType === DisplayType.PrimaryNavigation ? "yes" : "no",
          icon: icon ? "yes" : "no",
          image: image ? "yes" : "no",
        })}
        tabIndex={isTabbable === false ? -1 : 0}
        data-primary-navigation={displayType === DisplayType.PrimaryNavigation ? "yes" : "no"}
        data-id={id}
        data-index={index}
        onClick={onLinkClick}
      >
        {icon && <Icon name={icon as IconName} />}
        {image && <img src={image} width="24" height="24" alt="" />}
        <div>
          <span>{title}</span>
          {description && <Text level={4}>{description}</Text>}
        </div>
      </Link>
    );
  }

  return item;
};

export default MenuItem;
