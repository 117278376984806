import React, { useRef, MouseEventHandler, RefObject } from "react";
import { css, breakpoints } from "@sourceful/shared-components";
import { DisplayType, MenuItem } from "./MenuItem";
import { IMenu } from "./PrimaryNavigation";

const liCss = css({
  "&::before": {
    content: "",
  },

  variants: {
    burgerOnly: {
      yes: {
        "@lg": {
          display: "none",
        },
      },
      no: {},
    },
    primaryNavigation: {
      yes: {
        borderRadius: "30px",

        "&:not(:first-child)": {
          marginLeft: "12px",
        },

        "&:focus-within": {
          backgroundColor: "#f6f6f6",
        },

        ".no-js &": {
          "&:focus-within [data-panel], &:hover [data-panel]": {
            visibility: "visible",
            opacity: "1",
          },
        },
      },
      no: {
        borderBottom: "1px solid #DCDCDD",

        "&:last-child": {
          border: "none",
        },
      },
    },
  },
});

export interface PrimaryNavigationItemProps {
  index: number;
  id: string;
  panelId?: string;
  title: string;
  href?: string;
  isActive: boolean;
  isTabbable: boolean;
  shouldFocus?: boolean;
  isBurgerOnly?: boolean;
  onMenuItemButtonClick: MouseEventHandler<HTMLButtonElement>;
  onMenuItemLinkClick: MouseEventHandler<HTMLAnchorElement>;
  onKeyDownEvent: (e: React.KeyboardEvent) => void;
  children?: React.ReactNode;
  menu?: IMenu;
  closeRef?: RefObject<HTMLButtonElement>;
  displayType: DisplayType;
}

export const PrimaryNavigationItem = ({
  index,
  id,
  panelId,
  title,
  href,
  isActive,
  isTabbable,
  shouldFocus,
  isBurgerOnly,
  children,
  menu,
  closeRef,
  onMenuItemButtonClick,
  onMenuItemLinkClick,
  displayType,
}: PrimaryNavigationItemProps) => {
  const refLi = useRef<HTMLLIElement>(null);

  const isTouchDevice = !!global.window?.document?.documentElement?.ontouchstart;

  const isLgOrBigger =
    typeof window !== "undefined" && window.matchMedia(`(min-width: ${breakpoints.lg}px)`).matches;

  const onMouseEnter = (e: any) => {
    if (!isActive && !isTouchDevice && isLgOrBigger) {
      onMenuItemButtonClick(e);
    }
  };

  const onMouseLeaveAndBlur = (e: any) => {
    if (isLgOrBigger) {
      if (e.relatedTarget === null || closeRef === null) return;

      // Basically if they click off we close the active menu using the closeRef click.
      // If its another menu item clicked though we allow the state updates to take care of UI.
      if (closeRef) {
        const relatedTarget = e.relatedTarget as HTMLElement,
          isExpectedType = "closest" in relatedTarget;

        // If not an expected type, close whatever we have open
        // This can be caused by having a menu open and then tabbing to another window.
        // relatedTarget became the Window which doesn't have "closest" support
        if (!isExpectedType) {
          closeRef.current?.click();
        } else {
          const focusWithin = refLi.current?.contains(relatedTarget),
            isMenuItem = relatedTarget?.closest("[data-primary-navigation");

          if (!focusWithin && !isMenuItem) {
            closeRef.current?.click();
          }
        }
      }
    }
  };

  return (
    <li
      ref={refLi}
      className={liCss({
        primaryNavigation: displayType === DisplayType.PrimaryNavigation ? "yes" : "no",
        burgerOnly: isBurgerOnly ? "yes" : "no",
      })}
      onBlur={onMouseLeaveAndBlur}
      onMouseLeave={onMouseLeaveAndBlur}
    >
      <MenuItem
        onButtonClick={onMenuItemButtonClick}
        onLinkClick={onMenuItemLinkClick}
        onMouseEnter={onMouseEnter}
        title={title}
        href={href}
        menu={menu}
        displayType={displayType}
        isActive={isActive}
        isTabbable={isTabbable}
        shouldFocus={shouldFocus}
        index={index}
        id={id}
        panelId={panelId}
      />

      {children}
    </li>
  );
};

export default PrimaryNavigationItem;
