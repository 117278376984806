import React from "react";
import { css, IconButton } from "@sourceful/shared-components";
import { Dropdown } from "@sourceful/shared-components";
import GlobalHeaderAccountContent from "./GlobalHeaderAccountContent";
import { useOrdersCount } from "@lib/hooks/useOrdersCount";
import { useFetchHeaderCounts } from "./useFetchHeaderCounts";

export interface GHAccount {
  user: User | undefined;
}

interface User {
  id: number;
}

const buttonStyles = css({
  button: {
    transition: "all 0.2s",
    outline: "revert !important",
    boxShadow: "none !important",
    borderRadius: "$radii-small",

    "&:hover": {
      opacity: 0.7,
      background: "none !important",
    },

    "&:focus": {
      background: "none !important",
    },
  },
});

export const GlobalHeaderAccount = () => {
  const { orderCount } = useOrdersCount();

  const { draftsCount, quotesCount } = useFetchHeaderCounts();
  const hasNotification = !!draftsCount || !!quotesCount || !!orderCount;

  return (
    <Dropdown
      triggerId="account-dropdown-trigger"
      menuId="account-content"
      contentStyles={{
        "@dropdown-dialog-minimum-height": {
          left: "-250px",
        },
      }}
      shouldOpenOnHover
      trigger={({ ref, onClick, ...props }) => {
        return (
          <IconButton
            {...props}
            buttonRef={ref}
            handleClick={onClick}
            iconName={"navigation-people"}
            buttonDescription="Account"
            data-cy="global-header-account-trigger"
            isNotification={hasNotification}
            testDataAttribute="account-menu-button"
            className={buttonStyles()}
          />
        );
      }}
    >
      {props => {
        return (
          <Dropdown.Menu {...props} title="Account">
            <GlobalHeaderAccountContent
              handleClose={props.handleClose}
              draftsCount={draftsCount}
              quotesCount={quotesCount}
              orderCount={orderCount}
            />
          </Dropdown.Menu>
        );
      }}
    </Dropdown>
  );
};

export default GlobalHeaderAccount;
