import getAxiosClient from "@lib/getAxiosClient";
import { ErrorStatus } from "@providers/ErrorProvider";
import { apiConfig } from "apiConfig";
import { useAuthentication } from "../../providers/AuthenticationProvider";
import { useQuery } from "@tanstack/react-query";
import { useHandleQueryError } from "./useHandleQueryError";

const ERROR_SCOPE = "Orders";

export const useOrdersCount = (): {
  orderCount: number | undefined;
  ordersLoading: boolean;
  error: any;
} => {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuthentication();

  const {
    data: orderCount,
    error,
    isLoading: ordersLoading,
  } = useQuery({
    queryKey: ["order_count", isLoading, isAuthenticated],
    queryFn: async () => {
      try {
        const token = await getAccessTokenSilently();
        const res = await fetchOrderCountForUser(token);
        return res;
      } catch (error) {
        throw error;
      }
    },
    gcTime: 0,
    enabled: !isLoading && isAuthenticated,
  });

  useHandleQueryError({
    error,
    message: "Unable to fetch order count",
    scope: ERROR_SCOPE,
    status: ErrorStatus.warning,
  });

  return { orderCount: orderCount, ordersLoading, error };
};

const fetchOrderCountForUser = async (token: string): Promise<number> => {
  const client = getAxiosClient(
    token,
    {
      baseURL: apiConfig.orderServiceApiOrigin,
    },
    {}
  );

  const {
    data: { data },
  } = await client.get("/client-order-access/order_count");

  return data?.order_service_order_aggregate?.aggregate?.count || 0;
};
