import React, { MouseEventHandler, useRef } from "react";
import { IMenuItem } from "./PrimaryNavigation";
import { Icon } from "@sourceful/shared-components";
import { useIsSsr } from "@lib/hooks/useIsSsr";
import Link from "next/link";
import FocusTrapCatcher from "@components/focusTrapCatcher";
import { NestedMenuItem } from "./NestedMenuItem";
import {
  backButtonCss,
  closeButtonCss,
  headingContainerCss,
  menuButtonCss,
  menuCss,
  menuHeadingCss,
  menuLinkCss,
  navigationItemCss,
} from "./styles";

interface NavigationItemProps {
  item: IMenuItem;
  menuButtonClickHandler: MouseEventHandler<HTMLButtonElement>;
  menuButtonMouseEnterHandler: MouseEventHandler<HTMLButtonElement>;
  menuLiMouseLeaveAndBlurHandler: any;
  menuCloseClickHandler: MouseEventHandler<HTMLButtonElement>;
  menuBackClickHandler: MouseEventHandler<HTMLButtonElement>;
}

export function MenuItem({
  item,
  menuButtonClickHandler,
  menuButtonMouseEnterHandler,
  menuLiMouseLeaveAndBlurHandler,
  menuCloseClickHandler,
  menuBackClickHandler,
}: NavigationItemProps) {
  let retVal;

  const buttonRef = useRef<HTMLButtonElement>(null);

  const isSsr = useIsSsr();

  if (item.menu) {
    retVal = (
      <button
        ref={buttonRef}
        className={menuButtonCss({
          active: item.isActive ? "yes" : "no",
        })}
        onClick={menuButtonClickHandler}
        onMouseEnter={menuButtonMouseEnterHandler}
        data-primary-navigation-item=""
        data-burger={item.isBurgerOnly}
        aria-haspopup="true"
        aria-expanded={item.isActive}
        aria-controls={`panel-${item._key}`}
        id={`button-${item._key}`}
      >
        <span>{item.title}</span>
      </button>
    );
  } else {
    retVal = (
      <Link
        href={item.href || ""}
        passHref
        className={menuLinkCss()}
        data-primary-navigation-item=""
        data-burger={item.isBurgerOnly}
      >
        <div>
          <span>{item.title}</span>
        </div>
      </Link>
    );
  }

  const nextItem = () => {
    const currentActiveEl = document.activeElement as HTMLElement;

    if (!currentActiveEl) return;

    if (currentActiveEl.hasAttribute("data-nested-menu-item")) {
      const items = Array.from(document.querySelectorAll("[data-nested-menu-item]")),
        currentIndex = items.indexOf(currentActiveEl),
        nextIndex = getNextIndex(currentIndex, items);

      (items[nextIndex] as HTMLElement)?.focus();
    }
  };

  const prevItem = () => {
    const currentActiveEl = document.activeElement as HTMLElement;

    if (!currentActiveEl) return;

    if (currentActiveEl.hasAttribute("data-nested-menu-item")) {
      const items = Array.from(document.querySelectorAll("[data-nested-menu-item]")),
        currentIndex = items.indexOf(currentActiveEl),
        prevIndex = getPrevIndex(currentIndex, items);

      (items[prevIndex] as HTMLElement)?.focus();
    }
  };

  const firstItem = () => {
    const currentActiveEl = document.activeElement as HTMLElement;

    if (!currentActiveEl) return;

    if (currentActiveEl.hasAttribute("data-nested-menu-item")) {
      const items = Array.from(document.querySelectorAll("[data-nested-menu-item]")),
        nextIndex = getNextIndex(items.length - 1, items);

      (items[nextIndex] as HTMLElement)?.focus();
    }
  };

  const lastItem = () => {
    const currentActiveEl = document.activeElement as HTMLElement;

    if (!currentActiveEl) return;

    if (currentActiveEl.hasAttribute("data-nested-menu-item")) {
      const items = Array.from(document.querySelectorAll("[data-nested-menu-item]")),
        prevIndex = getPrevIndex(0, items);

      (items[prevIndex] as HTMLElement)?.focus();
    }
  };

  const getNextIndex = (currentIndex: number, items: Element[]): number => {
    return currentIndex >= items.length - 1 ? 0 : currentIndex + 1;
  };

  const getPrevIndex = (currentIndex: number, items: Element[]): number => {
    return currentIndex === 0 ? items.length - 1 : currentIndex - 1;
  };

  const onKeyDownEvent = (event: React.KeyboardEvent) => {
    if (event.code === "Escape") {
      // Let this bubble so our primary nav escape handles it
      event.preventDefault();
      return;
    }

    // Stop everything from here bubbling up

    event.stopPropagation();

    if (event.code === "ArrowRight" || event.code === "ArrowDown") {
      nextItem();
      event.preventDefault();
    }

    if (event.code === "ArrowLeft" || event.code === "ArrowUp") {
      prevItem();
      event.preventDefault();
    }

    if (event.code === "Home") {
      firstItem();
      event.preventDefault();
    }

    if (event.code === "End") {
      lastItem();
      event.preventDefault();
    }
  };

  return (
    <li
      data-key={item._key}
      className={navigationItemCss({
        burgerOnly: item.isBurgerOnly ? "yes" : "no",
      })}
      onMouseLeave={menuLiMouseLeaveAndBlurHandler}
      data-id={item.id}
      data-active={item.isActive}
    >
      {retVal}

      {/* So this is what gets rendered client side, FocusTrap is a right pain with with not finding tabbable elements */}
      {item.menu && item.isActive && (
        <FocusTrapCatcher
          focusTrapOptions={{
            delayInitialFocus: true,
            preventScroll: true,
            clickOutsideDeactivates: true,
          }}
        >
          <div
            className={menuCss({ primaryNav: "no", open: item.isActive ? "yes" : "no" })}
            onKeyDown={onKeyDownEvent}
            id={`panel-${item._key}`}
            aria-labelledby={`button-${item._key}`}
          >
            <button className={closeButtonCss()} onClick={menuCloseClickHandler} tabIndex={0}>
              <Icon name="alert-cross-default" />
              <span hidden>Close menu</span>
            </button>
            <div className={headingContainerCss()}>
              <button className={backButtonCss()} onClick={menuBackClickHandler} data-id={item.id}>
                <Icon name="arrow-direction-left" />
                <span hidden>Back to parent</span>
              </button>
              <p className={menuHeadingCss()}>{item.menu.heading}</p>
            </div>

            {item.menu.sections.map(section => {
              return (
                <div key={section._key}>
                  {section.items && (
                    <ul>
                      {section.items.map(sectionItem => {
                        return <NestedMenuItem key={sectionItem._key} item={sectionItem} />;
                      })}
                    </ul>
                  )}
                </div>
              );
            })}
          </div>
        </FocusTrapCatcher>
      )}

      {/* So this is what gets rendered server side here, gives us a little bit of functionality IF javascript was disabled */}
      {item.menu && isSsr && !item.isActive && (
        <div
          className={menuCss({ primaryNav: "no", open: item.isActive ? "yes" : "no" })}
          id={`panel-${item._key}`}
          aria-labelledby={`button-${item._key}`}
        >
          <button className={closeButtonCss()} onClick={menuCloseClickHandler} tabIndex={0}>
            <Icon name="alert-cross-default" />
            <span hidden>Close menu</span>
          </button>
          <div className={headingContainerCss()}>
            <button className={backButtonCss()} onClick={menuBackClickHandler} data-id={item.id}>
              <Icon name="arrow-direction-left" />
              <span hidden>Back to parent</span>
            </button>
            <p className={menuHeadingCss()}>{item.menu.heading}</p>
          </div>

          {item.menu.sections.map(section => {
            return (
              <div key={section._key}>
                {section.items && (
                  <ul>
                    {section.items.map(sectionItem => {
                      return <NestedMenuItem key={sectionItem._key} item={sectionItem} />;
                    })}
                  </ul>
                )}
              </div>
            );
          })}
        </div>
      )}
    </li>
  );
}
