import { createContext, FunctionComponent, useContext, useState } from "react";
import { ToastProps, CSS, IconName } from "@sourceful/design-system";
import uniqueId from "lodash/uniqueId";
interface PushToastProps {
  id?: string;
  title: string;
  description: string;
  iconName: IconName;
  duration?: number;
  showCloseIcon?: boolean;
  blockCloseInteraction?: boolean;
  css?: CSS;
  iconCss?: CSS;
}
export interface ToastPropsInjected {
  pushToast: (props: PushToastProps) => void;
  removeToast: (id: string) => void;
  toasts: ToastProps[];
}

const ToastContext = createContext({} as ToastPropsInjected);

const useToastContext = () => useContext(ToastContext);

interface ToastContextProps {
  children?: React.ReactNode;
}

const ToastContextProvider: FunctionComponent<ToastContextProps> = ({ children }) => {
  const [activeToasts, setActiveToasts] = useState<ToastProps[]>([]);

  const pushToast = (props: PushToastProps) => {
    const { id, ...rest } = props;

    const toastId = id ?? uniqueId();

    const toastIndex = activeToasts.find(toast => toast.id === toastId);

    if (toastIndex) {
      return;
    }

    setActiveToasts([
      ...activeToasts,
      {
        id: toastId,
        open: true,
        ...rest,
      },
    ]);
  };
  const removeToast = (id: string) => {
    const toasts = activeToasts.filter(toast => toast.id !== id);
    setActiveToasts(toasts);
  };

  return (
    <ToastContext.Provider value={{ toasts: activeToasts, pushToast, removeToast }}>
      {children}
    </ToastContext.Provider>
  );
};
export default ToastContextProvider;
export { ToastContextProvider, useToastContext, ToastContext };
