import env from "@lib/utils/env";
import { Router } from "next/router";
import { useMemo } from "react";

const BASE_URL = env("BASE_URL");

export const disallowedQueryParams = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content",

  // used by configurator creator but doesn't affect content
  "return",
  "qty",
  "preset",
];

export const getCanonicalUrl = (basePath: string, path: string) => {
  const url = new URL(path, basePath);

  //remove query params that are not important for content
  disallowedQueryParams.forEach(param => {
    url.searchParams.delete(param);
  });

  //remove hash as it's not important for content
  url.hash = "";

  return url.toString();
};
export const useCanonicalUrl = (
  router: Router,
  baseUrl = BASE_URL || "https://shop.sourceful.com" // this is a placeholder needed for build but gets overwritten at runtime
) => {
  const canonicalUrl = useMemo(() => {
    return getCanonicalUrl(baseUrl, router.asPath);
  }, [router.asPath, baseUrl]);

  return canonicalUrl;
};
