import { css, Heading, Icon, IconText, PrimaryButton, Text } from "@sourceful/shared-components";
import {
  sectionCss,
  sectionHeadCss,
  sectionLiCss,
  sectionUlCss,
  iconButtonCss,
} from "./PrimaryNavigationSearchStyles";
import toRem from "@styles/postprocess/conversion/to-rem";
import Link from "next/link";
import { MouseEventHandler } from "react";

const containerCss = css({
  textAlign: "center",
  pbe: "$inline_m1",
  my: "$block_m1",
  borderBottom: "1px solid #DCDCDD",

  "&:last-child": {
    borderBottom: "none",
  },

  "@lg": {
    pbe: "$inline_l1",
    my: "$block_l1",
  },
});

const iconContainerCss = css({
  mbe: "$block_s2",
  color: "$palette3_colour1_tint60",

  "@lg": {
    marginInline: "auto",
    width: "160px",
    height: "120px",

    svg: {
      width: "100%",
      height: "100%",
    },
  },
});

const retryButtonCss = css({
  marginInline: "auto",
  width: "100%",
  maxWidth: toRem(220),
});

interface IPrimaryNavigationSearchErrorProps {
  retryClick: MouseEventHandler<HTMLButtonElement>;
}

export const PrimaryNavigationSearchError = ({
  retryClick,
}: IPrimaryNavigationSearchErrorProps) => {
  return (
    <div>
      <div className={containerCss()}>
        <div className={iconContainerCss()}>
          <Icon name="save-error" width={51} height={38} />
        </div>
        <Heading level={3} prominence="primary" fontSize={6} css={{ mbe: "$block_s2" }}>
          Search unavailable
        </Heading>
        <Text level={2} prominence="primary" css={{ mbe: "$block_m2" }}>
          It looks like there is a problem with our search at the moment. Our team are working hard
          to fix it.
        </Text>
        <PrimaryButton className={retryButtonCss()} onClick={retryClick}>
          Retry
        </PrimaryButton>
      </div>

      <div className={sectionCss()}>
        <div className={sectionHeadCss()}>
          <Heading level={3} fontSize={6} prominence="primary">
            Have you tried?
          </Heading>
        </div>
        <ul className={sectionUlCss()}>
          <li className={sectionLiCss()}>
            <Link href="/">
              <a>
                <IconText
                  secondaryIcon="arrow-direction-right"
                  text="Shop"
                  className={iconButtonCss()}
                />
              </a>
            </Link>
          </li>
          <li className={sectionLiCss()}>
            <Link href="/#">
              <IconText
                secondaryIcon="arrow-direction-right"
                text="Platform"
                className={iconButtonCss()}
              />
            </Link>
          </li>
          <li className={sectionLiCss()}>
            <Link href="/knowledge-hub">
              <IconText
                secondaryIcon="arrow-direction-right"
                text="Knowledge Hub"
                className={iconButtonCss()}
              />
            </Link>
          </li>
          <li className={sectionLiCss()}>
            <Link href="/#">
              <IconText
                secondaryIcon="arrow-direction-right"
                text="About"
                className={iconButtonCss()}
              />
            </Link>
          </li>
          <li className={sectionLiCss()}>
            <Link href="/#">
              <IconText
                secondaryIcon="arrow-direction-right"
                text="Contact us"
                className={iconButtonCss()}
              />
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
