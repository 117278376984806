import { ErrorStatus, useErrorContext } from "../../providers/ErrorProvider";
import { SanitySiteFooterConfig, SanitySiteFooterConfigFetcher } from "@groq/siteFooterConfig";
import { useRouter } from "next/router";
import { i18n } from "../../next-i18next.config";
import useSWR from "swr";
import { getClient } from "@lib/sanity";

export const useSiteFooterConfig = (existingConfig: SanitySiteFooterConfig | undefined) => {
  const { reportError } = useErrorContext();
  const { isPreview, locale } = useRouter();
  const currentLocale = locale || i18n.defaultLocale;

  const { data: config, error } = useSWR(
    ["site-footer", isPreview, currentLocale],
    async ([_key, _isPreview, _locale]) => {
      if (_isPreview) {
        const client = getClient(_isPreview, true);

        const sanitySiteFooterConfigFetcher = new SanitySiteFooterConfigFetcher({
          client,
          locale: _locale,
          isPreview: _isPreview,
        });

        return sanitySiteFooterConfigFetcher.fetchConfig();
      } else {
        return existingConfig;
      }
    },
    {
      onError: e => {
        reportError({
          status: ErrorStatus.local,
          message: "Error fetching Sanity Site Footer Config",
          stack: e,
        });
      },
    }
  );

  const isLoading = !config && !error;

  return { config: config || undefined, isLoading, error };
};
