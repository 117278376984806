import { styled } from "@sourceful/shared-components";
import { PrimaryNav, Inner, burgerLogoNavWrapper } from "./PrimaryNavigation";
import { useSiteHeaderConfig } from "@lib/hooks/useSiteHeaderConfig";
import SiteLogo from "./SiteLogo";
import { SanitySiteHeaderConfig } from "@groq/siteHeaderConfig";
import { useRouter } from "next/router";
import { i18n } from "next-i18next.config";
import staticData from "@lib/static-data/site-header/data.json";
import React from "react";
import { PrimaryNavigation } from "./primaryNavigation/PrimaryNavigation";
import { getDataBySanityLocale } from "@lib/utils/getDataBySanityLocale";
import { SecondaryNavigation } from "./SecondaryNavigation";
import { apiConfig } from "apiConfig";

const Header = styled("header", {
  display: "flex",
  flexDirection: "column",
});

// Should be global styling
const SrOnly = styled("div", {
  position: "absolute",
  width: "1px",
  height: "1px",
  padding: "0",
  margin: "-1px",
  overflow: "hidden",
  clip: "rect(0,0,0,0)",
  whiteSpace: "nowrap",
  borderWidth: 0,
});

interface SiteHeaderProps {
  appearance?: "simplified" | "default";
}

export const SiteHeader = ({ appearance }: SiteHeaderProps) => {
  let { locale } = useRouter();

  const _locale = locale || i18n.defaultLocale;

  let fallbackSiteHeader = getDataBySanityLocale(
    _locale,
    staticData as unknown as Record<string, SanitySiteHeaderConfig>
  );

  const { config, isLoading } = useSiteHeaderConfig(fallbackSiteHeader);

  const isSimplified = appearance === "simplified";

  if (isLoading && !fallbackSiteHeader) {
    return <></>;
  }

  // Fallback should be available outside of the hook for static page generation - otherwise header is missing in initial document
  const siteHeaderConfig = config || fallbackSiteHeader;

  if (isSimplified) {
    return (
      <Header data-cy="site-header">
        <PrimaryNav>
          <Inner>
            <div className={burgerLogoNavWrapper()}>
              <SiteLogo ariaLabel="Return to home" href={`${apiConfig.gtmSite}/`} />
            </div>
          </Inner>
        </PrimaryNav>
      </Header>
    );
  }

  return (
    <Header data-cy="site-header">
      {/* Not REALLY needed these days based on navigation features but maybe still useful for some? */}
      <SrOnly>
        <a href="#primary-navigation">Skip to navigation</a>
        <a href="#main">Skip to content</a>
      </SrOnly>

      {!!siteHeaderConfig?.secondaryNavigation && (
        <SecondaryNavigation data={siteHeaderConfig?.secondaryNavigation} />
      )}

      {!!siteHeaderConfig?.primaryNavigation && (
        <PrimaryNavigation data={siteHeaderConfig?.primaryNavigation} />
      )}
    </Header>
  );
};

export default SiteHeader;
