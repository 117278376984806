import { getSanityImgUrl } from "@lib/sanity";
import { BlockContent } from "@lib/sanity-components";
import { PortableTextTypeComponentProps } from "@portabletext/react";
import { Image, PortableText } from "@sourceful/shared-types";
import BlockContentFigure from "./BlockContentFigure";

const BlockContentBodyImage = (
  props: PortableTextTypeComponentProps<{ caption: PortableText[]; asset: Image["asset"] }>
) => {
  if (!props?.value?.asset?._ref) {
    console.error("Embedded body image is missing details");
    return null;
  }
  return (
    <BlockContentFigure>
      <img alt={props?.value?.asset?.altText} src={getSanityImgUrl(props?.value?.asset?._ref)} />
      <div>
        {props.value?.caption.map((caption, index) => {
          return <BlockContent value={caption} key={`caption-${index}`} />;
        })}
      </div>
    </BlockContentFigure>
  );
};

export default BlockContentBodyImage;
