import React, { FC } from "react";
import Link from "next/link";
import { css as stitchesCss, CSS } from "@sourceful/shared-components";

const linkCss = stitchesCss({
  display: "inline-flex",
});

export interface LinkNewProps {
  link: string;
  internal?: boolean;
  newWindow?: boolean;
  children?: React.ReactNode;
  css?: CSS;
  className?: string;
}

export const LinkNew: FC<LinkNewProps> = ({
  link,
  internal = false,
  newWindow = false,
  children,
  css,
  className,
  ...props
}) => {
  if (internal) {
    return (
      <Link
        href={link}
        className={
          linkCss({
            css: {
              ...css,
            },
          }) + (className ? ` ${className}` : ``)
        }
        {...props}
      >
        {children}
      </Link>
    );
  }
  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      href={link}
      target={newWindow ? "_blank" : ""}
      rel={newWindow ? "noreferrer" : ""}
      className={
        linkCss({
          css: {
            ...css,
          },
        }) + (className ? ` ${className}` : ``)
      }
      {...props}
    >
      {children}
    </a>
  );
};

export default LinkNew;
