import { createContext, FunctionComponent, useContext } from "react";
import { useAreFeatureFlagsLoaded } from "./useAreFeatureFlagsLoaded";

export interface FlagsPropsInjected {
  isInitialised: boolean;
  isIdentified: boolean;
  hasError: boolean;
}

export interface FlagsLoadedProps {
  children?: React.ReactNode;
}

const FlagsLoadedContext = createContext({} as FlagsPropsInjected);

const useFlagsLoadedContext = () => useContext(FlagsLoadedContext);

const FlagsLoadedProvider: FunctionComponent<FlagsLoadedProps> = ({ children }) => {
  const { isInitialised, isIdentified, hasError } = useAreFeatureFlagsLoaded();
  return (
    <FlagsLoadedContext.Provider
      value={{
        isInitialised,
        isIdentified,
        hasError,
      }}
    >
      {children}
    </FlagsLoadedContext.Provider>
  );
};
export default FlagsLoadedProvider;
export { FlagsLoadedProvider, useFlagsLoadedContext };
