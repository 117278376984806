import { getUserName } from "@providers/CheckOnboardingWrapper/useCheckOnboardingStatus";
import { styled } from "@sourceful/shared-components";
import { DropdownLink } from "@sourceful/shared-types";
import { useAuthentication } from "../../../../providers/AuthenticationProvider";
import GlobalHeaderLink from "../GlobalHeaderLink";

export interface DropdownLinkWithCount extends DropdownLink {
  count?: number;
}
export interface AuthenticatedProps {
  menuItems: DropdownLinkWithCount[];
}

const OptionListWrapper = styled("ul", {
  display: "flex",
  flexDirection: "column",
  listStyleType: "none",
  margin: "0px",
  padding: "0px",
});

export const AuthenticatedGlobalHeaderAccount = ({ menuItems: options }: AuthenticatedProps) => {
  const { user, logout } = useAuthentication();
  const name = getUserName(user);

  return (
    <OptionListWrapper>
      {options.length > 0 &&
        options.map(option => {
          return <GlobalHeaderLink key={option._key} count={option.count} item={option} />;
        })}

      <GlobalHeaderLink
        isButton
        id="header-link-sign-out"
        item={{
          _key: "logout",
          title: name ? `Sign Out ${name}` : "Sign Out",
          iconName: "arrow-fast-fast-down",
          url: "",
        }}
        onClick={() => {
          logout({
            logoutParams: {
              returnTo: window.location.origin,
            },
          });
        }}
      />
    </OptionListWrapper>
  );
};

export default AuthenticatedGlobalHeaderAccount;
