import { ErrorStatus, useErrorContext } from "../../providers/ErrorProvider";
import { SanitySiteHeaderConfig, SanitySiteHeaderConfigFetcher } from "@groq/siteHeaderConfig";
import { useRouter } from "next/router";
import { i18n } from "next-i18next.config";
import useSWR from "swr";
import { getClient } from "@lib/sanity";

export const useSiteHeaderConfig = (existingConfig: SanitySiteHeaderConfig | undefined) => {
  const { reportError } = useErrorContext();
  const { isPreview, locale } = useRouter();
  const currentLocale = locale || i18n.defaultLocale;

  const { data: config, error } = useSWR(
    ["site-header", isPreview, currentLocale],
    async ([_key, _isPreview, _locale]) => {
      if (_isPreview) {
        const client = getClient(_isPreview, true);
        const sanitySiteHeaderConfigFetcher = new SanitySiteHeaderConfigFetcher({
          client,
          locale: _locale,
          isPreview: _isPreview,
        });

        return sanitySiteHeaderConfigFetcher.fetchConfig();
      } else {
        return existingConfig;
      }
    },
    {
      onError: e => {
        reportError({
          status: ErrorStatus.local,
          message: "Error fetching Sanity Site Header Config",
          stack: e,
        });
      },
    }
  );

  const isLoading = !config && !error;

  return { config, isLoading, error };
};
