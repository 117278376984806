import {
  createContext,
  FunctionComponent,
  ReactElement,
  useCallback,
  useContext,
  useState,
} from "react";
import { Chip } from "@sourceful/shared-components";

export enum ErrorStatus {
  "fatal",
  "warning",
  "local",
}

export interface ErrorProviderInjectedProps {
  errors: IErrors;
  pushError: (id: string, error: Error, log: boolean) => void;
  reportError: (error: Error) => void;
  clearError: (id: string, message: string) => void;
  getErrors: (id: string) => Error[];
  getErrorsByType: (type: ErrorStatus) => ErrorWithKey[];
  renderWarningChip: (errors: Error[], key: string, callback?: () => void) => ReactElement | null;
  clearErrorsById: (id: string) => void;
}

export interface Error {
  status: ErrorStatus;
  message: string;
  stack: any;
}

export interface ErrorWithKey extends Error {
  key: string;
}

export interface IErrors {
  [key: string]: Error[];
}

export interface ErrorProviderProps {
  errors?: IErrors;
  children?: React.ReactNode;
}

const ErrorContext = createContext({} as ErrorProviderInjectedProps);

const useErrorContext = () => useContext(ErrorContext);

const ErrorProvider: FunctionComponent<ErrorProviderProps> = ({
  children,
  errors: injectedErrors = {},
}) => {
  const [errors, setErrors] = useState<IErrors>(injectedErrors);

  const renderWarningChip = (errors: Error[], key: string, callback?: () => void) => {
    if (errors && errors.length && errors[0].status === ErrorStatus.warning) {
      return (
        <div>
          <Chip
            text={errors[0].message}
            iconName={"alert-exclamation-outline"}
            handleDismiss={() => {
              clearError(key, errors[0].message);
              callback && callback();
            }}
            size={"medium"}
            isFullWidth={true}
            borderRadius="square"
          />
        </div>
      );
    }
    return null;
  };

  const pushError = useCallback((id: string, error: Error, log: boolean = true) => {
    setErrors((currentErrors: IErrors) => {
      if (currentErrors[id]) {
        return {
          ...currentErrors,
          [id]: [...currentErrors[id], error],
        };
      }

      return {
        ...currentErrors,
        [id]: [error],
      };
    });

    if (log) {
      console.error(`error in ${id} section`, error.message, error.stack);
    }
  }, []);

  const reportError = useCallback((error: Error) => {
    console.error(error.message, error.stack);
  }, []);

  const clearError = (id: string, message: string) => {
    const newErrors = Object.keys(errors).reduce((obj: IErrors, key: string): IErrors => {
      obj[key] = errors[key].reduce((acc: Error[], item: Error) => {
        if (key !== id) {
          acc.push(item);
          return acc;
        }

        if (item.message !== message) acc.push(item);

        return acc;
      }, []);

      return obj;
    }, {});

    setErrors(newErrors);
  };

  const clearErrorsById = useCallback((id: string) => {
    setErrors((currentErrors: IErrors) => {
      const newErrors = Object.keys(currentErrors).reduce((obj: IErrors, key: string): IErrors => {
        obj[key] = currentErrors[key].reduce((acc: Error[], item: Error) => {
          if (key !== id) {
            acc.push(item);
            return acc;
          }

          return acc;
        }, []);

        return obj;
      }, {});
      return newErrors;
    });
  }, []);

  const getErrorsByType = (type: ErrorStatus) => {
    return Object.keys(errors).reduce((acc: ErrorWithKey[], key: string): ErrorWithKey[] => {
      errors[key].forEach((item: Error) => {
        if (item.status === type) acc.push({ ...item, key });
      });
      return acc;
    }, []);
  };

  const getErrors = (id: string) => {
    return errors[id] || [];
  };

  return (
    <ErrorContext.Provider
      value={{
        errors,
        pushError,
        reportError,
        clearError,
        getErrors,
        getErrorsByType,
        renderWarningChip,
        clearErrorsById,
      }}
    >
      {children}
    </ErrorContext.Provider>
  );
};

export { ErrorProvider, useErrorContext, ErrorContext };
