import { LogoutOptions, RedirectLoginOptions, useAuth0, User } from "@auth0/auth0-react";
import { GetTokenSilentlyOptions } from "@auth0/auth0-spa-js";
import { getGuestUserId } from "@lib/flags/flags.client";
import { useIdentifySentryUser } from "@lib/hooks/useIdentifySentryUser";
import { isTrackingDisabled } from "@lib/hooks/useTracking";
import env from "@lib/utils/env";
import { ErrorStatus, useErrorContext } from "@providers/ErrorProvider";
import { useIdentifyFeatureFlagUser } from "@sourceful/shared-utils/flag-utils";
import {
  getDefaultRole,
  getProductCloudRoles,
  ProductCloudRole,
} from "@sourceful/shared-utils/rbac";
import Router from "next/router";
import {
  createContext,
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { useAuthSync } from "./useAuthSync";
import { CLAIMS_DOMAIN, UserClaims } from "./types";

enum ACTIONS {
  "UPDATE" = "UPDATE",
}

export { CLAIMS_DOMAIN };
const ALLOWED_ROLES = "x-hasura-allowed-roles";

export interface Organisation {
  id: string;
  name: string;
  display_name: string;
  uuid?: string;
}

interface AuthProps {
  user: User | undefined;
  loginCount: number | undefined;
  metadata: UserClaims["user"]["user_metadata"] | undefined;
  isSocialLogin: boolean | undefined;
  organisation: Organisation | undefined;
  hasuraRoles: ProductCloudRole[] | undefined;
  hasuraRole: ProductCloudRole | undefined;
  isLoading: boolean;
  isAuthenticated: boolean;
  logout: (options?: LogoutOptions | undefined) => Promise<void>;
}
interface AuthAction {
  type: string;
  payload: Partial<AuthProps>;
}

const initialState: AuthProps = {
  user: undefined,
  loginCount: undefined,
  metadata: undefined,
  isSocialLogin: undefined,
  organisation: undefined,
  hasuraRoles: undefined,
  hasuraRole: undefined,
  isAuthenticated: false,
  isLoading: true,
  logout: () => Promise.resolve(),
};

export type AuthenticationProviderInjectedProps = AuthProps & {
  getAccessTokenSilently: (options?: GetTokenSilentlyOptions) => Promise<string>;
  loginWithRedirect: (options?: RedirectLoginOptions | undefined) => Promise<void>;
  waitForUser: Promise<unknown>;
};

const AuthContext = createContext({} as AuthenticationProviderInjectedProps);

const useAuthenticationContext = () => useContext(AuthContext);

const authReducer = (state: AuthProps, action: AuthAction): AuthProps => {
  const { type, payload } = action;
  switch (type) {
    case ACTIONS.UPDATE: {
      return {
        ...state,
        ...payload,
      };
    }
    default: {
      console.error(`Unhandled action type ${type}`);
      return state;
    }
  }
};

export type AuthProviderProps = { children?: React.ReactNode };

const AuthenticationProvider: FunctionComponent<AuthProviderProps> = ({ children }) => {
  const { pushError } = useErrorContext();
  const waitForUser = new Promise(() => {});
  const [userState, dispatch] = useReducer(authReducer, initialState);
  const { getAccessTokenSilently, logout, loginWithRedirect, user, isAuthenticated, isLoading } =
    useAuth0();

  const [authChecked, setAuthChecked] = useState(false);

  // on a fresh load of the app if the user is authenticated on the auth0 server but has no session
  // state in local storage the app will not realise it is logged in until getAccessTokenSilently is called
  // this most often happens when clicking 'Customise' - the user will launch configurator with a guest item and then
  // the app realises the user is logged in and then boots the user out of the configurator.
  // This useEffect syncs the server and frontend state

  useEffect(() => {
    if (authChecked || isLoading) return;
    if (isAuthenticated) {
      // don't check if auth0 is already revalidating auth state
      setAuthChecked(true);
      return;
    }

    getAccessTokenSilently({
      cacheMode: "off",
    })
      .catch(_ => {})
      .finally(() => {
        setAuthChecked(true);
      });
  }, [authChecked, getAccessTokenSilently, isAuthenticated, isLoading]);

  const handleLogout = useCallback(() => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  }, [logout]);

  const handleSignin = useCallback(() => {
    getAccessTokenSilently({ cacheMode: "off" });
    Router.push("/");
  }, [getAccessTokenSilently]);

  // sync auth state between tabs
  useAuthSync({
    handleLogout,
    handleSignin,
    isAuthenticatedInApp: userState.isAuthenticated,
    isAuthLoading: userState.isLoading,
  });

  // identify user with feature flag platform
  useIdentifyFeatureFlagUser({
    user: userState.user || null,
    guestUserId: getGuestUserId(),
    isTrackingDisabled: isTrackingDisabled(),
  });
  useIdentifySentryUser({ user: userState.user || null });

  useEffect(() => {
    if (isLoading || !authChecked) {
      return dispatch({
        type: ACTIONS.UPDATE,
        payload: {
          isLoading: true,
          isAuthenticated: false,
          user: undefined,
          organisation: undefined,
          hasuraRole: undefined,
          hasuraRoles: undefined,
        },
      });
    } else if (!isLoading && isAuthenticated && user) {
      const userClaims: UserClaims = user[CLAIMS_DOMAIN];

      if (!userClaims) {
        console.error(`Missing claims for user ${user.sub}`, user);
        pushError(
          "AuthenticationProvider",
          {
            message: "Missing claims for user",
            status: ErrorStatus.fatal,
            stack: new Error("Missing claims for user").stack,
          },
          false
        );

        logout({ logoutParams: { returnTo: window.location.origin } });
        return;
      }

      const organisation = userClaims.user.org;
      const allRoles = userClaims[ALLOWED_ROLES] || [];
      const isSocialLogin = !(user.sub?.startsWith("auth0|") || user.sub?.startsWith("email|"));

      const hasuraRoles = getProductCloudRoles({
        roles: allRoles,
        userOrgId: organisation?.id || "",
        sourcefulOrgId: env("SOURCEFUL_ORG_ID"),
      });
      const hasuraDefaultRole = getDefaultRole(hasuraRoles);

      dispatch({
        type: ACTIONS.UPDATE,
        payload: {
          user,
          loginCount: userClaims?.user?.logins_count,
          metadata: userClaims?.user?.user_metadata,
          isSocialLogin,
          hasuraRoles,
          hasuraRole: hasuraDefaultRole,
          organisation,
          isAuthenticated: true,
          isLoading: false,
        },
      });

      Promise.resolve(waitForUser);
    } else {
      dispatch({ type: ACTIONS.UPDATE, payload: { ...initialState, isLoading, isAuthenticated } });
      Promise.resolve(waitForUser);
    }
  }, [user, isLoading, isAuthenticated, authChecked, authChecked]);

  return (
    <AuthContext.Provider
      value={{
        ...userState,
        loginWithRedirect,
        getAccessTokenSilently,
        waitForUser,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthenticationProvider;
export { AuthContext, AuthenticationProvider, useAuthenticationContext as useAuthentication };
