import { useEffect } from "react";
import { useRouter } from "next/router";
import { datadogRum } from "@datadog/browser-rum";
import { isDatadogInitialized } from "./useDatadog";

/*
  This hook is used to manually track page views by pathname in Datadog which will help in building out various funnel reports that 
  need to group sessions by pathname e.g. /buy/[productMain] and not by the specific url (router.asPath) /buy/mailer-box etc. 
  Datadatog's wildcard matching isn't sufficient so we need to help it out a bit
*/

export const useDatadogPageViews = () => {
  const router = useRouter();

  const isInitialised = isDatadogInitialized();

  useEffect(() => {
    if (!isInitialised) return;
    if (router.pathname === router.asPath) return; // avoid tracking same page name twice if it provides no extra benefit for filtering
    datadogRum.startView({ name: router.pathname });
  }, [router.pathname, router.asPath, isInitialised]);
};
