import StandardLink from "@components/configuratorV2/components/CustomBlockContentV2/StandardLinkV2";
import { SanitySiteFooterConfig } from "@groq/siteFooterConfig";
import {
  styled,
  CSS,
  Label,
  LabelSize,
  LabelWeight,
  LabelLineHeight,
} from "@sourceful/design-system";

const LastLineWrapper = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  "@bp-sm": {
    flexDirection: "row",
  },
});

const LastLineUnorderedList = styled("ul", {
  padding: "0",
  margin: "0",
  display: "flex",

  "@bp-sm": {
    marginLeft: "$spacing-fluid-inline-medium",
    marginTop: "0px",
  },
});

const LastLineListItem = styled("li", {
  padding: "0",
  paddingRight: "$spacing-fluid-inline-medium",
  listStyle: "none",

  "@bp-sm": {
    paddingRight: "$spacing-fluid-inline-medium",
  },

  "&:last-of-type": {
    paddingRight: "0",
  },
});

interface FooterLastLineProps {
  siteFooterConfig: SanitySiteFooterConfig;
  containerCss?: CSS;
}

const FooterLastLine = ({ siteFooterConfig, containerCss }: FooterLastLineProps) => {
  return (
    <LastLineWrapper css={containerCss}>
      <Label
        size={LabelSize.Small}
        weight={LabelWeight["Regular"]}
        lineHeight={LabelLineHeight["Tight"]}
        css={{
          color: "$colour-lightest",
          "@bp-sm": {
            textAlign: "center",
          },
        }}
      >
        &copy; {siteFooterConfig.copyrightText}
      </Label>

      <LastLineUnorderedList>
        {siteFooterConfig?.policyLinks?.map((policyLink, index) => (
          <LastLineListItem key={`${policyLink?._key}-${index}`}>
            <StandardLink
              internalLink={policyLink.internalLink}
              externalLink={policyLink.externalLink}
              css={{
                textDecoration: "none",
              }}
            >
              <Label
                size={LabelSize.Small}
                weight={LabelWeight["Regular"]}
                css={{
                  color: "$colour-brand-gtm-grey",
                  "&:hover": {
                    color: "$colour-brand-gtm-green-hue-base",
                  },
                }}
              >
                {policyLink.name}
              </Label>
            </StandardLink>
          </LastLineListItem>
        ))}
      </LastLineUnorderedList>
    </LastLineWrapper>
  );
};

export default FooterLastLine;
