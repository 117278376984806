import React, { createRef, MouseEvent, useState, useEffect, useCallback } from "react";
import { i18n } from "next-i18next.config";
import {
  IconButton,
  Heading,
  LoadingSpinner,
  Text,
  IconText,
  Icon,
} from "@sourceful/shared-components";
import FocusTrap from "focus-trap-react";
import { useLockBodyScroll } from "@sourceful/shared-components";
import axios from "axios";
import { useDebounce, useLocalStorage } from "react-use";
import Link from "next/link";
import { getSanityImgUrl } from "@lib/sanity";
import { PrimaryNavigationSearchSuggested } from "./PrimaryNavigationSearchSuggested";
import {
  Container,
  containerCss,
  headCloseIconCss,
  headCss,
  iconButtonCss,
  inputCss,
  labelCss,
  linkButtonCss,
  modalCss,
  productsCss,
  responseIoCss,
  screenReaderCss,
  SearchButton,
  searchCloseIconCss,
  searchLoadingCss,
  searchResultContentCss,
  searchResultImageContainerCss,
  searchResultImgCss,
  searchResultLiCss,
  searchResultLinkCss,
  searchResultsContainerCss,
  searchResultsListCss,
  searchResultsSummaryCss,
  sectionCss,
  sectionHeadCss,
  sectionLiCss,
  sectionUlCss,
} from "./PrimaryNavigationSearchStyles";
import { PrimaryNavigationSearchError } from "./PrimaryNavigationSearchError";
import { useRouter } from "next/router";
import { ArticleSearchResult, ProductSearchResult } from "@groq/searchQuery";
import { useEnterKeyCallback } from "@app/hooks/useEnterKeyCallback";

interface ISearchResults {
  empty: boolean;
  noResults: boolean;
  products: ProductSearchResult[];
  articles: ArticleSearchResult[];
}

const defaultSearchState = {
  empty: true,
  noResults: false,
  products: [],
  articles: [],
};

export const PrimaryNavigationSearch = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryDebounced, setSearchQueryDebounced] = useState("");
  const [searchResults, setSearchResults] = useState<ISearchResults>(defaultSearchState);

  const router = useRouter(),
    locale = router.locale || i18n.defaultLocale;

  const [,] = useDebounce(
    () => {
      // console.log(
      //   "PrimaryNavigationSearch.useDebounce: Typing stopped",
      //   searchQuery,
      //   searchQueryDebounced
      // );

      // Handle scenario if they end up typing the same word during loading.
      // Just show the current result set.
      if (searchQuery === searchQueryDebounced) {
        setIsLoading(false);
      } else {
        setIsLoading(true);
        setSearchQueryDebounced(searchQuery);
      }
    },
    1000,
    [searchQuery]
  );

  const [recentSearches, setRecentSearches, removeRecentSearches] = useLocalStorage<Array<string>>(
    "recent-searches",
    []
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const modalRef = createRef<HTMLDivElement>();
  const buttonRef = createRef<HTMLButtonElement>();
  const closeButtonRef = createRef<HTMLButtonElement>();
  const searchInputRef = createRef<HTMLInputElement>();

  useLockBodyScroll(isModalOpen, "search-open");

  useEffect(() => {
    // console.log("PrimaryNavigationSearch.useEffect: router.asPath");

    setIsModalOpen(false);
  }, [router.asPath]);

  const fetchData = async () => {
    // console.log("PrimaryNavigationSearch.fetchData", searchQueryDebounced);

    if (searchQuery === "") {
      setIsError(false);
      setIsLoading(false);
      setSearchResults(defaultSearchState);

      return;
    }

    setIsError(false);
    setIsLoading(true);

    await axios
      .get(`/api/search?q=${encodeURIComponent(searchQueryDebounced)}&locale=${locale}`, {
        timeout: 5000,
      })
      .then(response => {
        if ("products" in response.data || "articles" in response.data) {
          let data: ISearchResults = {
            ...(response.data as ISearchResults),
          };

          data.noResults =
            searchQuery !== "" && data.articles.length === 0 && data.products.length === 0;
          data.empty =
            searchQuery === "" && data.articles.length === 0 && data.products.length === 0;

          setSearchResults(data);
        } else {
          setSearchResults({ empty: true, noResults: false, products: [], articles: [] });
        }

        setIsLoading(false);
      })
      .catch(() => {
        setIsError(true);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    // console.log(
    //   "PrimaryNavigationSearch.useEffect: searchQueryDebounced updated",
    //   searchQueryDebounced
    // );

    fetchData();
  }, [searchQueryDebounced]);

  useEffect(() => {
    // console.log("PrimaryNavigationSearch.useEffect: isModalOpen", isModalOpen, searchQuery);

    if (!isModalOpen && searchQuery !== "") {
      addRecentSearch(searchQuery);

      setSearchQuery("");
    }

    if (isModalOpen) {
      const onScroll = () => {
        if (document.activeElement && document.activeElement.id === "search-input") {
          (document.activeElement as HTMLInputElement).blur();
        }
      };

      modalRef.current?.removeEventListener("scroll", onScroll);
      modalRef.current?.addEventListener("scroll", onScroll, { passive: true });
    }
  }, [isModalOpen]);

  const addRecentSearch = (query: string) => {
    if (recentSearches?.some(x => x.toLocaleLowerCase() === query.toLocaleLowerCase())) {
      return;
    }

    const searches = recentSearches ? [...recentSearches] : [];

    if (searches.length === 5) {
      searches.pop();
    }

    searches.unshift(query);

    setRecentSearches(searches);
  };

  const presetSearchClick = (event: MouseEvent<HTMLButtonElement>) => {
    const el = event.target as HTMLButtonElement,
      buttonText = el.textContent;

    if (isLoading || buttonText === searchQuery) {
      return;
    }

    // console.log("PrimaryNavigationSearch.presetSearchClick", event, el, buttonText);

    setIsLoading(true);

    setSearchQuery(buttonText || "");
  };

  const clearAllRecentSearchesClick = () => {
    removeRecentSearches();
  };

  const clearRecentSearchClick = (event: MouseEvent<HTMLButtonElement>) => {
    // console.log("PrimaryNavigationSearch.clearRecentSearchClick", event);

    if (!recentSearches) {
      return;
    }

    const el = event.target as HTMLElement,
      button = el.closest("button") as HTMLButtonElement,
      searchQuery = button.getAttribute("data-search-query");

    // console.log("PrimaryNavigationSearch.clearRecentSearchClick", el, searchQuery);

    const searches = [...recentSearches].filter(x => x !== searchQuery);

    setRecentSearches(searches);
  };

  const modalOnDeactive = () => {
    // console.log("PrimaryNavigationSearch.modalOnDeactive");

    setIsModalOpen(false);
  };

  const modalOnPostActivate = () => {
    //console.log("PrimaryNavigationSearch.modalOnPostActivate");

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1);
  };

  responseIoCss();

  const handleClick = useCallback(() => {
    addRecentSearch(searchQuery);
  }, [addRecentSearch, searchQuery]);

  const handleEnter = useEnterKeyCallback(handleClick);

  return (
    <Container>
      <SearchButton
        ref={buttonRef}
        type="button"
        onClick={() => setIsModalOpen(true)}
        aria-label="Search"
      >
        <Icon name="navigation-search" />
      </SearchButton>

      {isModalOpen && (
        <FocusTrap
          active={isModalOpen}
          focusTrapOptions={{
            initialFocus: "#search-input",
            delayInitialFocus: false,
            allowOutsideClick: false,
            preventScroll: true,
            onDeactivate: modalOnDeactive,
            onPostActivate: modalOnPostActivate,
          }}
        >
          <div
            ref={modalRef}
            role="dialog"
            id="search-modal-new"
            aria-labelledby="search-modal-heading"
            aria-modal="true"
            className={modalCss()}
          >
            <div className={containerCss()}>
              <div className={headCss()}>
                <Heading level={2} fontSize={4} prominence="primary">
                  <span id="search-modal-heading">Search</span>
                </Heading>
                <div className={headCloseIconCss()}>
                  <IconButton
                    buttonDescription="close-dialog"
                    handleClick={() => setIsModalOpen(false)}
                    buttonRef={closeButtonRef}
                    iconName="alert-cross-default"
                    size="small"
                  />
                </div>
              </div>

              <form
                role="search"
                method="post"
                onSubmit={e => {
                  e.preventDefault();
                }}
              >
                <label className={labelCss()}>
                  <span className={screenReaderCss()}>Search</span>
                  {/* <Icon name="navigation-search" className={searchIconCss()} /> */}
                  <input
                    ref={searchInputRef}
                    type="text"
                    id="search-input"
                    name="q"
                    placeholder="Keywords e.g. Mailer Box"
                    className={inputCss()}
                    value={searchQuery}
                    autoComplete="off"
                    onChange={e => {
                      setSearchQuery(e.target.value);
                    }}
                    onKeyDown={e => {
                      e.stopPropagation();
                    }}
                  />
                  {searchQuery.length > 0 && (
                    <div className={searchCloseIconCss()}>
                      <button
                        type="button"
                        className={linkButtonCss()}
                        onClick={() => {
                          setIsLoading(false);
                          setSearchResults(defaultSearchState);
                          setSearchQuery("");
                        }}
                      >
                        Clear
                      </button>
                    </div>
                  )}
                </label>
              </form>

              {searchResults.empty && !isError && !isLoading && (
                <div>
                  {recentSearches && recentSearches.length > 0 && (
                    <div className={sectionCss()}>
                      <div className={sectionHeadCss()}>
                        <Heading level={3} fontSize={6} prominence="primary">
                          Recent searches
                        </Heading>
                        <button
                          type="button"
                          onClick={clearAllRecentSearchesClick}
                          className={linkButtonCss()}
                        >
                          Clear all<span className={screenReaderCss()}> recent searches</span>
                        </button>
                      </div>
                      <ul className={sectionUlCss()}>
                        {recentSearches.map((x, i) => {
                          return (
                            <li key={`rs-${i}`} className={sectionLiCss()}>
                              <button
                                onClick={presetSearchClick}
                                className={iconButtonCss({
                                  css: { "& .leftIcon": { position: "relative", top: "2px" } },
                                })}
                                aria-label={`Search for ${x}`}
                              >
                                <IconText primaryIcon="navigation-revision" text={x} css={{}} />
                              </button>

                              <button
                                type="button"
                                onClick={clearRecentSearchClick}
                                data-search-query={x}
                                className={linkButtonCss()}
                                aria-label={`Delete ${x} from recent searches`}
                              >
                                Clear
                              </button>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}

                  <PrimaryNavigationSearchSuggested buttonClick={presetSearchClick} />
                </div>
              )}

              {isError && (
                <PrimaryNavigationSearchError
                  retryClick={() => {
                    setIsLoading(true);
                    fetchData();
                  }}
                />
              )}

              {isLoading && (
                <div className={searchLoadingCss()}>
                  <LoadingSpinner iconWidth={32} iconHeight={32} />
                </div>
              )}

              {!isLoading && !searchResults.empty && (
                <div className={searchResultsContainerCss()}>
                  <Text level={2} prominence="primary" className={searchResultsSummaryCss()}>
                    {searchResults.products.length + searchResults.articles.length} result(s) for{" "}
                    <strong>{searchQuery}</strong>
                  </Text>

                  {searchResults.noResults && (
                    <PrimaryNavigationSearchSuggested buttonClick={presetSearchClick} />
                  )}

                  {searchResults.products.length > 0 && (
                    <div className={productsCss()}>
                      <Heading
                        level={3}
                        fontSize={6}
                        prominence="primary"
                        css={{ mbe: "$block_m1" }}
                      >
                        Shop
                      </Heading>
                      <ul className={searchResultsListCss()}>
                        {searchResults.products.map((p, i) => {
                          const hasShortDesc = p.shortDescription && p.shortDescription.length > 0;

                          const hasDesc = p.description && p.description.length > 0;

                          let fallbackImage = null;

                          if (!p.image) {
                            fallbackImage = searchResults.products.find(
                              product => product._id === p.productMainId
                            )?.image;
                          }

                          return (
                            <li key={`srp-${i}`} className={searchResultLiCss()}>
                              <Link href={`/buy/${p.slug}`} legacyBehavior>
                                <a
                                  className={searchResultLinkCss()}
                                  onClick={handleClick}
                                  onKeyDown={handleEnter}
                                >
                                  <div className={searchResultImageContainerCss()}>
                                    {p.image && (
                                      <img
                                        className={searchResultImgCss()}
                                        src={getSanityImgUrl(p?.image.asset._ref, base =>
                                          base.width(82).height(82)
                                        )}
                                        alt=""
                                      />
                                    )}
                                    {fallbackImage && (
                                      <img
                                        className={searchResultImgCss()}
                                        src={getSanityImgUrl(fallbackImage.asset._ref, base =>
                                          base.width(82).height(82)
                                        )}
                                        alt=""
                                      />
                                    )}
                                  </div>
                                  <div className={searchResultContentCss()}>
                                    <Text level={2} prominence="secondary" className="heading">
                                      {p.name}
                                    </Text>

                                    {hasShortDesc && (
                                      <Text
                                        level={3}
                                        prominence="primary"
                                        css={{ mbs: "$block_xs1" }}
                                        data-short-desc=""
                                      >
                                        {p.shortDescription}
                                      </Text>
                                    )}

                                    {!hasShortDesc && hasDesc && (
                                      <Text
                                        level={3}
                                        prominence="primary"
                                        css={{ mbs: "$block_xs1" }}
                                        data-desc=""
                                      >
                                        {p.description}
                                      </Text>
                                    )}

                                    {hasShortDesc && (
                                      <Text
                                        level={3}
                                        prominence="primary"
                                        css={{ mbs: "$block_xs1" }}
                                        data-short-desc=""
                                      >
                                        /buy/{p.slug}
                                      </Text>
                                    )}
                                    <Text
                                      level={3}
                                      prominence="primary"
                                      css={{
                                        color: "$palette3_colour1_tint20",
                                        mbs: "$block_xs1",
                                      }}
                                    >
                                      /buy/{p.slug}
                                    </Text>
                                  </div>
                                </a>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}

                  {searchResults.articles.length > 0 && (
                    <div>
                      <Heading
                        level={3}
                        fontSize={6}
                        prominence="primary"
                        css={{ mbe: "$block_m1" }}
                      >
                        Articles
                      </Heading>
                      <ul className={searchResultsListCss()}>
                        {searchResults.articles.map((a, i) => {
                          return (
                            <li key={`sra-${i}`} className={searchResultLiCss()}>
                              <Link
                                href={`/knowledge-hub/${a.slug}`}
                                className={searchResultLinkCss()}
                                onClick={() => {
                                  addRecentSearch(searchQuery);
                                }}
                              >
                                {/* <div className={searchResultImageContainerCss()}></div> */}
                                <div className={searchResultContentCss()}>
                                  <Text level={2} prominence="secondary" className="heading">
                                    {a.name}
                                  </Text>
                                  <Text
                                    level={3}
                                    prominence="primary"
                                    css={{
                                      color: "$palette3_colour1_tint20",
                                      mbs: "$block_xs1",
                                    }}
                                    className="slug"
                                  >
                                    {a.heading}
                                  </Text>
                                </div>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </FocusTrap>
      )}
    </Container>
  );
};

export default PrimaryNavigationSearch;
