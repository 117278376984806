import { PortableTextTypeComponentProps } from "@portabletext/react";
import YouTube from "react-youtube";
import getYouTubeId from "get-youtube-id";

const BlockContentYoutube = (
  props: PortableTextTypeComponentProps<{
    _key: string;
    _type: "youtube";
    link?: any;
    url?: string;
  }>
) => {
  const { url } = props.value;
  if (!url) return null;
  const id = getYouTubeId(url) as string;
  return <YouTube videoId={id} />;
};

export default BlockContentYoutube;
