import { User } from "@auth0/auth0-react";
import { datadogRum } from "@datadog/browser-rum";
import { getGuestUserId } from "@lib/flags/flags.client";
import { useEffect } from "react";
import { isDatadogInitialized } from "../useDatadog";

export interface UseIdentifyDatadogUserProps {
  user: User | null;
  isAuthLoading: boolean;
  isDatadogDisabled: boolean;
}

export const useIdentifyDatadogUser = ({
  user,
  isAuthLoading,
  isDatadogDisabled,
}: UseIdentifyDatadogUserProps) => {
  const user_uid = user?.sub || "";
  const user_name = user?.name || "";
  const user_email = user?.email || "";
  const isReady = isDatadogInitialized();

  useEffect(() => {
    if (isDatadogDisabled || !isReady) return;
    // wait until we know the auth state
    if (isAuthLoading) return;

    // guest users
    if (!user_uid) {
      datadogRum.setUser({
        id: getGuestUserId(),
      });
      return;
    }

    const user = {
      id: user_uid,
      name: user_name,
      email: user_email,
    };

    console.log("identifying datadog user", user);
    // logged-in users
    datadogRum.setUser(user);
  }, [isReady, isAuthLoading, user_uid, user_email, user_name, isDatadogDisabled]);
};
