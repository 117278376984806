import { isFeatureFlagProviderDisabled } from "@lib/flags/flags.client";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useEffect, useMemo, useState } from "react";

export const useAreFeatureFlagsLoaded = () => {
  const ldClient = useLDClient();
  const [hasError, setHasError] = useState(false);
  const [isInitialised, setIsInitialised] = useState(isFeatureFlagProviderDisabled);

  const user = useMemo(() => ldClient?.getContext()?.key, [ldClient]);

  useEffect(() => {
    if (!ldClient) return;

    ldClient
      .waitForInitialization()
      .then(() => setIsInitialised(true))
      .catch(error => {
        setHasError(true);
        console.error("Failed to initialise feature flags", error);
      });
  }, [ldClient]);

  return { isInitialised, isIdentified: isFeatureFlagProviderDisabled() ? true : !!user, hasError };
};
