import { styled } from "@sourceful/shared-components";

const LinkButton = styled("button", {
  all: "unset",
  display: "inline-block",
  width: "fit-content",
  textDecoration: "underline",
  lineHeight: 1.25,
  letterSpacing: "-0.025em",
  cursor: "pointer",
  borderRadius: "$x-small",
  "&:focus": {
    color: "$palette1_colour1",
    $$focusBorder: "$colors$palette1_colour1",
    boxShadow: "0 0 0 1px $$focusBorder",
  },
  variants: {
    level: {
      1: {
        fontSize: "$p1",
      },
      2: {
        fontSize: "$p2",
      },
      3: {
        fontSize: "$p3",
      },
      4: {
        fontSize: "$p4",
      },
    },
    prominence: {
      primary: {
        fontWeight: "$latin_1_variable_light",
      },
      secondary: {
        fontWeight: "$latin_1_variable_semibold",
      },
    },
  },
  defaultVariants: {
    prominence: "primary",
    level: "1",
  },
});

export default LinkButton;
