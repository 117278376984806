import { AppState } from "@auth0/auth0-react";
import { NextRouter } from "next/router";
import { useCallback } from "react";
import Router from "next/router";

const REDIRECT_STATE = "redirectState";

function stripQueryStringAndHashFromPath(url: string) {
  return url.split("?")[0].split("#")[0];
}

export const setRedirectState = (appState: AppState) => {
  console.log("setting redirect state", appState);
  window.localStorage.setItem(REDIRECT_STATE, JSON.stringify(appState));
};

export const getRedirectState = (): AppState | null => {
  const redirectState = window.localStorage.getItem(REDIRECT_STATE);
  return redirectState ? JSON.parse(redirectState) : null;
};

export const onRedirectCallback = (appState?: AppState | undefined) => {
  console.log("onRedirectCallback", { appState, currentUrl: Router.pathname });
  appState && setRedirectState(appState);
  Router.replace("/handleRedirect");
};

export const getReturnUrl = (redirectState: AppState | null, path: string) => {
  console.log("getting return url", {
    path,
    redirectState,
  });

  if (!redirectState?.returnTo) {
    return "/";
  }

  if (
    stripQueryStringAndHashFromPath(redirectState.returnTo).trim() ===
    stripQueryStringAndHashFromPath(path).trim()
  ) {
    console.warn("Invalid returnTo - falling back to homepage");
    return "/";
  }

  return redirectState.returnTo;
};

export interface UseRouteToPreviousContextProps {
  router: NextRouter;
}

export const useRouteToPreviousContext = ({ router }: UseRouteToPreviousContextProps) => {
  const routeToPreviousContext = useCallback(
    (forceReload?: boolean) => {
      const redirectState = getRedirectState();
      const returnTo = getReturnUrl(redirectState, router.pathname);

      return router.replace(returnTo, undefined, { shallow: false }).then(() => {
        if (forceReload) {
          router.reload();
        }
      });
    },
    [router]
  );

  return { routeToPreviousContext };
};
