import LinkButton from "@components/rfq/RFQModal/LinkButton";
import { styled, Text } from "@sourceful/shared-components";

const BannerWrapper = styled("div", {
  height: "30px",
  width: "100%",
  padding: "0 20px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "green",
  color: "white",
  position: "sticky",
  top: 0,
  zIndex: 20,
});

const PreviewBanner = () => {
  return (
    <BannerWrapper>
      <div />
      <Text level={2}>Preview Mode</Text>
      <LinkButton level={2} as="a" href="/api/exit-preview">
        Exit
      </LinkButton>
    </BannerWrapper>
  );
};

export default PreviewBanner;
