import "@sourceful/reset.css";
import "@sourceful/reset.css/forms.css";
import "@sourceful/reset.css/typography.css";
import "@ungap/global-this";
import "intersection-observer";
import "../styles/nprogress.css";

import { Auth0Provider } from "@auth0/auth0-react";
import ErrorHandler from "@components/ErrorHandler";
import PreviewBanner from "@components/previewBanner/PreviewBanner";
import { getGuestUserId, isFeatureFlagProviderDisabled } from "@lib/flags/flags.client";
import { useCanonicalUrl } from "@lib/hooks/useCanonicalUrl";
import { useDatadog } from "@lib/hooks/useDatadog";
import { useDatadogPageViews } from "@lib/hooks/useDatadogPageViews";
import { isTrackingDisabled, useIntercomInit, useTracking } from "@lib/hooks/useTracking";
import { onRedirectCallback } from "@lib/redirectStateUtils";
import env from "@lib/utils/env";
import { AuthenticationProvider } from "@providers/AuthenticationProvider";
import { ErrorProvider } from "@providers/ErrorProvider";
import { FinalMileProvider } from "@providers/FinalMileProvider";
import FlagsLoadedProvider from "@providers/FlagsLoadedProvider/FlagsLoadedProvider";
import { ToastContextProvider } from "@providers/ToastContextProvider";
import { IconSprite as IconSpriteLegacy } from "@sourceful/shared-components";
import { formatAuthCookieDomain } from "@sourceful/shared-utils/auth-utils";
import { withFlagProvider } from "@sourceful/shared-utils/flag-utils";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Cookies from "js-cookie";
import DefaultLayout from "layouts/DefaultLayout";
import type { NextPage } from "next";
import { AppProps } from "next/app";
import dynamic from "next/dynamic";
import Head from "next/head";
import Router from "next/router";
import NProgress from "nprogress";
import type { ReactElement, ReactNode } from "react";
import { useEffect } from "react";
import EngineVersionWarning from "views/admin/engine-settings/EngineVersionWarning";
import { useLeadGenExitDialog } from "../app/components/lead-gen/useLeadGenExitDialog";

const LeadGenExitDialog = dynamic(
  () => import("../components/LeadGenExitDialog/LeadGenExitDialog"),
  {
    ssr: false,
  }
);

const SOURCEFUL_ROOT_DOMAIN = env("ROOT_HOSTNAME");

export const GUEST_ID_COOKIE = "SFE_GUEST";

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 5,
      refetchOnMount: false,
      refetchOnReconnect: false,
      gcTime: 0,
    },
    mutations: {
      retry: false,
    },
  },
});

function MyApp({ Component, pageProps, router }: AppPropsWithLayout) {
  useTracking();
  // init Intercom
  const { initialiseIntercom } = useIntercomInit();

  useDatadog();
  useDatadogPageViews();

  const canonicalUrl = useCanonicalUrl(router);

  // set cookie to preserve locale after auth0 callback redirects to '/'
  useEffect(() => {
    Cookies.set("NEXT_LOCALE", router.locale || "en");
  }, [router.locale]);

  useEffect(() => {
    Router.events.on("routeChangeStart", NProgress.start);
    Router.events.on("routeChangeComplete", NProgress.done);
    Router.events.on("routeChangeError", NProgress.done);

    return () => {
      Router.events.off("routeChangeStart", NProgress.start);
      Router.events.off("routeChangeComplete", NProgress.done);
      Router.events.off("routeChangeError", NProgress.done);
    };
  }, []);

  const {
    value: showExitDialog,
    set: setShowExitDialog,
    isLeadGenPage,
  } = useLeadGenExitDialog(router.asPath);

  const getLayout =
    Component.getLayout ||
    (page => {
      return <DefaultLayout initialiseIntercom={initialiseIntercom}>{page}</DefaultLayout>;
    });

  return (
    <>
      <Head>
        <link rel="canonical" href={canonicalUrl} />
      </Head>
      <ErrorProvider>
        <Auth0Provider
          domain={env("AUTH0_DOMAIN")}
          clientId={env("AUTH0_CLIENT_ID")}
          cacheLocation="localstorage"
          useRefreshTokens={true}
          useRefreshTokensFallback
          authorizationParams={{
            redirect_uri: env("BASE_URL"),
            audience: env("AUTH0_AUDIENCE"),
          }}
          cookieDomain={formatAuthCookieDomain(SOURCEFUL_ROOT_DOMAIN)}
          onRedirectCallback={onRedirectCallback}
        >
          <AuthenticationProvider>
            <QueryClientProvider client={queryClient}>
              {/* design system icon sprite */}
              <img src={`/images/sprite.svg`} alt="" loading="lazy" style={{ display: "none" }} />
              {/* legacy shared-components icon sprite */}
              <IconSpriteLegacy />
              <ToastContextProvider>
                <FinalMileProvider>
                  <FlagsLoadedProvider>
                    <ErrorHandler>
                      {router.isPreview && <PreviewBanner />}
                      <EngineVersionWarning />
                      {
                        // Return the custom layout, or default to the site header and global footer
                        getLayout(<Component {...pageProps} />)
                      }
                      {showExitDialog && showExitDialog.show && !isLeadGenPage && (
                        <LeadGenExitDialog
                          onClose={() => {
                            setShowExitDialog({ show: false, seen: true });
                          }}
                          onContinue={() => {
                            setShowExitDialog({ show: false, seen: true });
                          }}
                        />
                      )}
                    </ErrorHandler>
                  </FlagsLoadedProvider>
                </FinalMileProvider>
              </ToastContextProvider>
            </QueryClientProvider>
          </AuthenticationProvider>
        </Auth0Provider>
      </ErrorProvider>
    </>
  );
}

const AppWithFlags = isFeatureFlagProviderDisabled()
  ? MyApp
  : withFlagProvider({
      app: MyApp,
      launchDarklyId: env("LAUNCHDARKLY_CLIENTSIDE_SDK_KEY"),
      guestUserId: getGuestUserId(),
      isTrackingDisabled: isTrackingDisabled(),
    });

export default AppWithFlags;
