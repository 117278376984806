import env from "@lib/utils/env";
import Cookies from "js-cookie";
import { useCallback, useEffect, useMemo } from "react";
import TagManager from "react-gtm-module";
import { COOKIE_DISABLE_TRACKING_KEY } from "./useTracking.constants";

const GTM_CODE = env("GTM_CODE");

const INTERCOM_APP_ID = env("INTERCOM_APP_ID");

const hasRun = {
  gtm: false,
  intercom: false,
};

export const isTrackingInitialised = (library: "gtm") => {
  return hasRun[library];
};

export const isTrackingDisabled = () => !!Cookies.get(COOKIE_DISABLE_TRACKING_KEY);

export const useGTMInit = (isTrackingDisabled: boolean) => {
  useEffect(() => {
    if (hasRun.gtm) return;

    if (GTM_CODE && !isTrackingDisabled) {
      TagManager.initialize({ gtmId: GTM_CODE });

      // NOTE: I certain this can be via GTM but for now I'll leave in place incase anything is calling gtag() directly
      const scriptGtag = document.createElement("script");
      scriptGtag.id = "gtag-init";
      scriptGtag.async = true;
      scriptGtag.src = "https://www.googletagmanager.com/gtag/js?id=AW-10793964155";
      document.head.appendChild(scriptGtag);

      const scriptGtagInit = document.createElement("script");
      scriptGtagInit.id = "gtag-init";
      scriptGtagInit.defer = true;
      scriptGtagInit.text = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-10793964155');`;
      document.head.appendChild(scriptGtagInit);
    }

    hasRun.gtm = true;
  }, [isTrackingDisabled]);

  return { isInitialised: hasRun.gtm };
};

export const useIntercomInit = () => {
  const initialiseIntercom = useCallback(() => {
    if (hasRun.intercom || !INTERCOM_APP_ID) return;

    const scriptIntercomInit = document.createElement("script");
    scriptIntercomInit.id = "intercom-init";
    scriptIntercomInit.defer = false;
    scriptIntercomInit.text = ` window.intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: "${INTERCOM_APP_ID}",
        custom_launcher_selector: ".webchat_widget"
      };

      (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${INTERCOM_APP_ID}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;

    document.head.appendChild(scriptIntercomInit);
    hasRun.intercom = true;
  }, []);

  return { isInitialised: hasRun.intercom, initialiseIntercom };
};

export const useTracking = () => {
  const disabled = useMemo(() => isTrackingDisabled(), []);

  // init GTM
  useGTMInit(disabled);

  return {
    enabled: !disabled,
    disabled,
  };
};
